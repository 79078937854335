import React from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import Button from 'components/common/form/Button'
import { DatePickerWithLabel, AttachFile } from 'components/common/form' // AttachFile
import { showWarning } from 'store/ducks/application'
import { downloadECAttachment, fetchEthicCommitteeDocuments, saveECAttachments, setShowECDocUpload } from 'store/ducks/siteManagement/ecDirectory/ecManagement'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const StyledDiv = styled.div`
  width: '100%';
`

const DateAndBannerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div:first-of-type {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }`

const attachFileStyles = {
  justifyContent: 'flex-start',
}

const UploadECDoc = ({ encrypttedEthicID }) => {
  const dispatch = useDispatch()

  const validationSchema = () =>
    validationMapper([
      {
        id: 'attachments',
        isResponseRequired: true,
        answerType: formElements.attachment
      },
      {
        id: 'ApprovalDate',
        isResponseRequired: false,
        answerType: formElements.date
      }
    ])

  return (
    <Formik
      initialValues={{ attachments: [], ApprovalDate: '' }}
      validationSchema={validationSchema()}
      onSubmit={async values => {
        await dispatch(saveECAttachments(values, encrypttedEthicID))
        await dispatch(fetchEthicCommitteeDocuments(encrypttedEthicID))
      }}
    >
      {({ values, setFieldValue }) => (
        <StyledDiv>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AttachFile
                  attachFileProperties={attachFileStyles}
                  label="Attach File:"
                  name="attachments"
                  downloadAttachment={file => dispatch(downloadECAttachment(file))}
                  onDelete={deletedFile => {
                    setFieldValue('attachmentsToBeDeleted', [
                      ...values.attachmentsToBeDeleted,
                      deletedFile.id
                    ])
                  }}
                  onFileUploadFailure={msg => dispatch(showWarning(msg))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <DateAndBannerWrapper>
                  <DatePickerWithLabel
                    id="ApprovalDate"
                    label="Approval Date"
                    name="ApprovalDate"
                    size="small"
                  />
                </DateAndBannerWrapper>
              </Grid>
              <Grid item xs={0} sm={0} md={6} lg={6}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                  &ensp;&ensp;
                  <Button size="large" color="inherit" fullWidth={false} onClick={() => dispatch(setShowECDocUpload(false))}>
                    Cancel
                  </Button>
                </ActionWrapper>
              </Grid>
            </Grid>
          </Form>
        </StyledDiv>
      )}
    </Formik>
  )
}

UploadECDoc.propTypes = {
  encrypttedEthicID: PropTypes.string,
  siteProtocolId: PropTypes.string
}

export default UploadECDoc
