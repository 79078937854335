import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Typography, Grid } from '@mui/material'
import { PageHeader, Button, AccordionGroup, PartitionCard, Bar } from 'components/common'
import {
  fetchSiteDetails,
  fetchSites,
  resetSiteDetails,
  setAddEditSiteFlow
} from 'store/ducks/siteManagement/siteManagement'
import { displayDate } from 'components/helper/utility'

import QuickView from 'components/common/QuickView'
import PropertyValue from 'components/common/dataDisplay/PropertyValue'
import AddEditSiteDetails from './AddEditSiteDetails'
import SiteAddress from './siteAddresses/SiteAddress'
import UserActivity from './userActivity/UserActivity'
import SiteNotes from './siteNotes/SiteNotes'
import DataPoints from './dataPoints/DataPoints'
import SiteImage from 'assets/images/site-management/SiteImage.png'
import { useRestriction } from 'permissions'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import ECDetails from './ecDirectory/ECDetails'

const EmptyFooter = styled.div`
  padding: 25px;
`

const SiteCardLogoWrapper = styled.div(() => ({
  '&&': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      width: '60%'
    }
  }
}))

const GrayGrid = styled(Grid)(
  ({ theme }) => `
  color: ${theme.palette.other.grayLabel};
`
)

const PrevNextSiteButtons = styled.div(({ theme }) => ({
  '&&': {
    button: {
      marginLeft: theme.spacing(2)
    }
  }
}))

const SiteDetails = () => {
  const { siteId, instanceID } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { siteDetails, addEditSiteFlow, sites } = useSelector(store => store.siteManagement)
  // const [showEditForm, setShowEditForm] = useState(false)

  const selectedSiteIndex =
    sites.results.length && sites.results.findIndex(site => site.siteId === siteDetails.siteId)

  useEffect(() => {
    dispatch(fetchSiteDetails(siteId))

    if (sites.results.length === 0) {
      // site list is required to make the next and previous button work
      dispatch(fetchSites())
    }

    // Cleanup
    return () => {
      dispatch(resetSiteDetails())
    }
  }, [siteId])

  const goBackHandler = e => {
    e.preventDefault()
    navigate(`/instance/${instanceID}/site-management`)
  }

  const siteDetailsAccordionItems = [
    {
      label: 'Site Addresses',
      component: <SiteAddress />,
      id: 'siteAddresses'
    },
    {
      label: 'User Activity',
      component: <UserActivity />,
      id: 'userActivity'
    },
    {
      label: 'Site Notes',
      component: <SiteNotes />,
      id: 'siteNotes'
    },
    {
      label: 'Data Points',
      component: <DataPoints />,
      id: 'dataPoints'
    },
    {
      label: 'Ethics Committee Documents',
      component: <ECDetails />,
      id: 'ecDocumentsTab'
    }
  ]

  const updateAccordionList = siteDetailsAccordionItems.filter(
    accordion => useRestriction('read', accordion.id) && accordion
  )

  return (
    <>
      <PageHeader
        title={
          <Typography variant="h3">
            <b>{`Site ${siteDetails.siteId} |`}</b>{' '}
            {`${siteDetails.piLastName} | ${siteDetails.country}`}
          </Typography>
        }
        actionContent={
          <PrevNextSiteButtons>
            <Button
              disabled={selectedSiteIndex === 0}
              startIcon={<ArrowBackIos />}
              onClick={() => {
                navigate(
                  `/instance/${instanceID}/site-details/${
                    sites.results[selectedSiteIndex - 1].encrypttedSiteId
                  }`
                )
              }}
            >
              Previous
            </Button>
            <Button
              disabled={selectedSiteIndex === sites.results.length - 1}
              startIcon={<ArrowForwardIos />}
              onClick={() => {
                navigate(
                  `/instance/${instanceID}/site-details/${
                    sites.results[selectedSiteIndex + 1].encrypttedSiteId
                  }`
                )
              }}
            >
              Next
            </Button>
            <Button color="inherit" size="medium" onClick={goBackHandler}>
              Back
            </Button>
          </PrevNextSiteButtons>
        }
        content={<div></div>}
        expandContent={false}
      />
      <PartitionCard
        cardProps={{ cardContentStyles: { padding: '0px', margin: '0px' } }}
        firstContent={
          <SiteCardLogoWrapper>
            <img alt="Site" src={SiteImage} />
          </SiteCardLogoWrapper>
        }
        firstContentWidth="20%"
        secondContent={
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography gutterBottom variant="h2">
                <b>{siteDetails?.siteName ? siteDetails.siteName : ''}</b>
              </Typography>
              <Bar fullWidth color="midnight" />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={3} xl={3} pb={2} pt={2}>
              <Grid container pr={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={1}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="PI Last Name:"
                    value={siteDetails?.piLastName ? siteDetails.piLastName : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Site Status:"
                    value={siteDetails ? siteDetails.siteStatus?.displayText : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Site Type:"
                    value={siteDetails ? siteDetails.siteType?.displayText : ''}
                    align="row"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={2}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Ethics Committee Name:"
                    value={siteDetails?.irbecName ? siteDetails.irbecName : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Ethics Committee Type:"
                    value={siteDetails?.irbecType ? siteDetails.irbecType : ''}
                    align="row"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} pt={3}>
                  <Button
                    size="large"
                    onClick={() => dispatch(setAddEditSiteFlow(true))}
                    color="inherit"
                  >
                    Edit Site Information
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Bar vertical color="gray" />
            <Grid item xs={12} sm={12} md={12} lg={8.5} xl={8.5} pb={2} pt={2}>
              <Grid container pl={{ xs: 0, sm: 0, md: 0, lg: 5, xl: 5 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} pt={1}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Screening Target:"
                    value={siteDetails?.screeningTarget ? siteDetails.screeningTarget : ''}
                    align="row"
                  />

                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Randomization Target:"
                    value={siteDetails?.randomizationTarget ? siteDetails.randomizationTarget : ''}
                    align="row"
                  />
                </Grid>
                <GrayGrid item xs={12} sm={12} md={6} lg={6} pt={1}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Dropped Date:"
                    value={siteDetails ? displayDate(siteDetails.droppedDate) : ''}
                    align="row"
                  />

                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Closure Date:"
                    value={siteDetails ? displayDate(siteDetails.closureDate) : ''}
                    align="row"
                  />
                </GrayGrid>
                <Grid item xs={12} sm={12} md={6} lg={6} pt={2}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Planned Activation Date:"
                    value={siteDetails ? displayDate(siteDetails.plannedSiteActivationDate) : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Initial Activation Date:"
                    value={siteDetails ? displayDate(siteDetails.activationDate) : ''}
                    align="row"
                  />
                </Grid>
                <GrayGrid item xs={12} sm={12} md={6} lg={6} pt={2}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="IM Attendance Date:"
                    value={siteDetails ? displayDate(siteDetails.imAttendanceDate) : ''}
                    align="row"
                  />
                </GrayGrid>
                <GrayGrid item xs={12} sm={12} md={6} lg={6} pt={2}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Enrollment Paused Date:"
                    value={siteDetails ? displayDate(siteDetails.enrollmentPausedDate) : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Reactivation Date:"
                    value={siteDetails ? displayDate(siteDetails.reactivationDate) : ''}
                    align="row"
                  />
                </GrayGrid>
              </Grid>
            </Grid>
          </Grid>
        }
        firstContentBackground="gradientBlue"
        secondContentWidth="80%"
        isFirstContentBackgroundGradient={true}
      />

      <AccordionGroup
        accordions={updateAccordionList}
        wrapperStyles={{ marginTop: '28px' }}
        gradient={false}
        color="primary"
        transitionProps={{ unmountOnExit: true }}
        defaultOpenPanel={-1}
      />
      <QuickView
        title="Edit Site Information"
        onClose={() => dispatch(setAddEditSiteFlow(false))}
        dialogContent={<AddEditSiteDetails />}
        isDialogOpen={addEditSiteFlow}
        maxWidth="md"
        dialogActions={<EmptyFooter />}
        closeConfirmation={true}
      />
    </>
  )
}
export default SiteDetails
