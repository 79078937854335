import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  downloadAttachmentByID,
  getAddressTypes,
  getCategories,
  getCohortsLookup,
  getCountries,
  getCountriesForTransferFunds,
  getEnglishSkillLevels,
  getGender,
  getLanguages,
  getMeansOfCommunication,
  getMessagesSendCategory,
  getProductsLookup,
  getPrograms,
  getSiteDataPointTypes,
  getSiteNoteTypesLookup,
  getSitesLookup,
  getSiteStatusesLookup,
  getSiteTypesLookup,
  getSupportPersonRelationships,
  getSupportPersonTypes,
  getSystemIDs,
  getSystemIDsByLoggedInSite,
  getVendorsLookup,
  getHelperAttachmentModules,
  downloadHelperAttachmentById,
  getCategoriesForWithPatients,
  getTacticsLookup,
  getMediaPlanSitesLookup,
  getMediaPlanStatusesLookup,
  getEthicstypes,
} from 'services/common'
import { copyObject, removeDuplicateItemsFromArray } from 'components/helper/utility'
import { showError } from './application'
import { setLoading } from './userInteractions'
import { communicationTabIDs } from 'components/helper/constants/mailbox'

export const setCategoriesLookup = createAction('common/setCategoriesLookup')
export const setProgramsLookup = createAction('common/setProgramsLookup')
export const resetCommon = createAction('common/resetCommon')
export const setSitesLookup = createAction('common/setSitesLookup')
export const setSystemIDs = createAction('common/setSystemIDs')
export const setCountriesLookup = createAction('common/setCountriesLookup')
export const setMessagesSendCategory = createAction('common/setMessagesSendCategory')
export const setSystemIDsByLoggedInSite = createAction('common/setSystemIDsByLoggedInSite')
export const setProductsLookup = createAction('common/setProductsLookup')
export const setCohortsLookup = createAction('common/setCohortsLookup')
export const setVendorsLookup = createAction('common/setVendorsLookup')
export const setSiteTypesLookup = createAction('common/setSiteTypesLookup')
export const setSiteStatusesLookup = createAction('common/setSiteStatusesLookup')
export const setSiteNoteTypesLookup = createAction('common/setSiteNoteTypesLookup')
export const setSiteDataPointTypes = createAction('common/setSiteDataPointTypes')
export const setSupportPersonRelationships = createAction('common/setSupportPersonRelationships')
export const setSupportPersonTypes = createAction('common/setSupportPersonTypes')
export const setGenderLookup = createAction('coommon/setGenderLookup')
export const setLanguagesLookup = createAction('common/setLanguagesLookup')
const setMeansOfCommunicationsLookup = createAction('common/setMeansOfCommunicationsLookup')
export const setAllAddressTypes = createAction('common/setAllAddressTypes')
const setEnglishSkillsLookup = createAction('common/setEnglishSkillsLookup')
export const setCountriesForTransferFunds = createAction('common/setCountriesForTransferFunds')
export const setHelperAttachmentModules = createAction('common/setHelperAttachmentModules')
export const setTacticsLookup = createAction('common/setTacticsLookup')
export const setMediaPlanSitesLookup = createAction('common/setMediaPlanSitesLookup')
export const setMediaPlanStatusesLookup = createAction('common/setMediaPlanStatusesLookup')
export const setEthicsTypesLookup = createAction('common/setEthicsTypesLookup')

export const fetchCategoriesLookup = selectedCommunicationTabId => async dispatch => {
  dispatch(setCategoriesLookup({ isLoading: true }))
  try {
    const { data } = [
      communicationTabIDs.conciergeMsgsWithPatients,
      communicationTabIDs.conciergeMsgsForPatients
    ].includes(selectedCommunicationTabId)
      ? await getCategoriesForWithPatients()
      : await getCategories()
    dispatch(setCategoriesLookup({ results: data }))
  } catch (e) {
    dispatch(setCategoriesLookup({ results: [] }))
    dispatch(
      showError(
        'There was issue fetching categories options. Please try refreshing your page or come back after sometime.',
        e
      )
    )
  }
  dispatch(setCategoriesLookup({ isLoading: false }))
}

export const fetchProgramsLookup = () => async (dispatch, getState) => {
  dispatch(setProgramsLookup({ isLoading: true }))
  const { programsLookup } = getState().common
  if (programsLookup.results.length === 0) {
    await getPrograms()
      .then(response => {
        dispatch(setProgramsLookup({ results: response.data }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching program options. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setProgramsLookup({ isLoading: false }))
}

export const fetchSitesLookup = () => async dispatch => {
  dispatch(setSitesLookup({ isLoading: true }))
  // const { sitesLookup } = getState().common
  // if (sitesLookup.results.length === 0) {
  await getSitesLookup()
    .then(response => {
      dispatch(setSitesLookup({ results: response.data }))
    })
    .catch(() => {
      dispatch(
        showError(
          'There was issue fetching sites options. Please try refreshing your page or come back after sometime.'
        )
      )
    })
  // }
  dispatch(setSitesLookup({ isLoading: false }))
}

export const fetchSystemIDs = () => async dispatch => {
  dispatch(setSystemIDs({ isLoading: true }))
  await getSystemIDs()
    .then(res => {
      dispatch(setSystemIDs({ results: res.data }))
    })
    .catch(() => {
      dispatch(
        showError(
          'There was issue fetching system IDs. Please try refreshing your page or come back after sometime.'
        )
      )
    })
  dispatch(setSystemIDs({ isLoading: false }))
}

export const fetchSystemIDsByLoggedInSite = () => async dispatch => {
  dispatch(setSystemIDsByLoggedInSite({ isLoading: true }))
  await getSystemIDsByLoggedInSite()
    .then(response => {
      dispatch(setSystemIDsByLoggedInSite({ isLoading: false, results: response.data }))
    })
    .catch(() => {
      dispatch(setSystemIDsByLoggedInSite({ isLoading: false }))
      dispatch(
        showError(
          'There was issue fetching system IDs. Please try refreshing your page or come back after sometime.'
        )
      )
    })
}

export const fetchCountriesLookup = () => async (dispatch, getState) => {
  try {
    dispatch(setCountriesLookup({ isLoading: true }))
    const { countriesLookup } = getState().common
    if (countriesLookup.results.length === 0) {
      const res = await getCountries()

      const finalRes = removeDuplicateItemsFromArray(res, 'displayText')
      dispatch(setCountriesLookup({ results: finalRes }))
    }
  } catch (e) {
    dispatch(() => {
      dispatch(
        showError(
          'There was some issue while trying to fetch countries dropdown options, Please try refreshing your page.', e
        )
      )
    })
  }

  dispatch(setCountriesLookup({ isLoading: false }))
}

export const fetchCountriesLookupForTransferFunds = () => async (dispatch, getState) => {
  dispatch(setCountriesForTransferFunds({ isLoading: true }))
  const { countriesLookupForTransferFunds } = getState().common
  if (countriesLookupForTransferFunds.results.length === 0) {
    await getCountriesForTransferFunds()
      .then(res => {
        const finalRes = removeDuplicateItemsFromArray(res, 'displayText')
        dispatch(setCountriesForTransferFunds({ results: finalRes }))
      })
      .catch(() => {
        dispatch(() => {
          dispatch(
            showError(
              'There was some issue while trying to fetch countries dropdown options, Please try refreshing your page.'
            )
          )
        })
      })
  }

  dispatch(setCountriesForTransferFunds({ isLoading: false }))
}

export const fetchProductsLookup = () => async (dispatch, getState) => {
  dispatch(setProductsLookup({ isLoading: true }))
  const { productsLookup } = getState().common
  if (productsLookup.results.length === 0) {
    getProductsLookup()
      .then(response => {
        dispatch(setProductsLookup({ results: response }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching products options. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setProductsLookup({ isLoading: false }))
}

export const fetchMessagesSendCategory = entityTypeID => async dispatch => {
  dispatch(setMessagesSendCategory({ isLoading: true }))
  await getMessagesSendCategory(entityTypeID)
    .then(res => {
      dispatch(setMessagesSendCategory({ results: res.data }))
    })
    .catch(() => {
      dispatch(
        showError(
          'There was some error while trying to fetch Send To Options. Try refreshing your page.'
        )
      )
    })
  dispatch(setMessagesSendCategory({ isLoading: false }))
}

export const fetchCohortsLookup = () => async (dispatch, getState) => {
  const { cohortsLookup } = getState().common
  dispatch(setCohortsLookup({ isLoading: true }))
  if (cohortsLookup.results.length === 0) {
    await getCohortsLookup()
      .then(response => {
        dispatch(setCohortsLookup({ results: response.data }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching cohorts options. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setCohortsLookup({ isLoading: false }))
}

export const fetchVendorsLookup = () => async (dispatch, getState) => {
  dispatch(setVendorsLookup({ isLoading: true }))
  const { vendorsLookup } = getState().common
  if (vendorsLookup.results.length === 0) {
    await getVendorsLookup()
      .then(response => {
        dispatch(setVendorsLookup({ results: response.data }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching vendors options. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setVendorsLookup({ isLoading: false }))
}

export const fetchSiteTypesLookup = () => async (dispatch, getState) => {
  dispatch(setSiteTypesLookup({ isLoading: true }))
  const { vendorsLookup } = getState().common
  if (vendorsLookup.results.length === 0) {
    await getSiteTypesLookup()
      .then(results => {
        dispatch(setSiteTypesLookup({ results }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching Site Types options. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setSiteTypesLookup({ isLoading: false }))
}

export const fetchSiteStatusesLookup = () => async (dispatch, getState) => {
  dispatch(setSiteStatusesLookup({ isLoading: true }))
  const { vendorsLookup } = getState().common
  if (vendorsLookup.results.length === 0) {
    await getSiteStatusesLookup()
      .then(results => {
        dispatch(setSiteStatusesLookup({ results }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching Site Statuses options. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setSiteStatusesLookup({ isLoading: false }))
}

export const fetchSiteNoteTypesLookup = () => async (dispatch, getState) => {
  dispatch(setSiteNoteTypesLookup({ isLoading: true }))
  const { siteNoteTypesLookup } = getState().common
  if (siteNoteTypesLookup.results.length === 0) {
    await getSiteNoteTypesLookup()
      .then(results => {
        dispatch(setSiteNoteTypesLookup({ results }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching Site Statuses options. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setSiteNoteTypesLookup({ isLoading: false }))
}

export const fetchSiteDataPointsType = () => async (dispatch, getState) => {
  dispatch(setSiteDataPointTypes({ isLoading: true }))
  const { siteDataPointTypes } = getState().common
  if (siteDataPointTypes.results.length === 0) {
    await getSiteDataPointTypes()
      .then(results => {
        dispatch(setSiteDataPointTypes({ results }))
      })
      .catch(() => {
        dispatch(
          showError(
            'There was issue fetching Site DataPoint Types. Please try refreshing your page or come back after sometime.'
          )
        )
      })
  }
  dispatch(setSiteDataPointTypes({ isLoading: false }))
}

export const downloadAttachment = attachment => async dispatch => {
  try {
    dispatch(setLoading(true))

    await downloadAttachmentByID(attachment)
  } catch (e) {
    dispatch(showError('There was some issue with downloading the attachment.', e))
  }

  dispatch(setLoading(false))
}

export const fetchSupportPersonRelationships = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { supportPersonRelationships } = getState().common
    if (supportPersonRelationships.length === 0) {
      const { data } = await getSupportPersonRelationships()
      dispatch(setSupportPersonRelationships(data))
    }
  } catch {
    dispatch(
      showError(
        'There was issue fetching "support person relationship" options. Please try refreshing your page or come back after sometime.'
      )
    )
  }

  dispatch(setLoading(false))
}

export const fetchSupportPersonTypes = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { supportPersonTypes } = getState().common
    if (supportPersonTypes.length === 0) {
      const { data } = await getSupportPersonTypes()
      dispatch(setSupportPersonTypes(data))
    }
  } catch {
    dispatch(
      showError(
        'There was issue fetching "support person relationship" options. Please try refreshing your page or come back after sometime.'
      )
    )
  }

  dispatch(setLoading(false))
}

export const fetchGenderLookup = () => async (dispatch, getState) => {
  try {
    const { genderLookup } = getState().common
    dispatch(setGenderLookup({ isLoading: true }))
    if (genderLookup.results.length === 0) {
      const { data } = await getGender()
      dispatch(setGenderLookup({ results: data }))
    }
  } catch {
    dispatch(
      showError(
        'There was an issue fetching gender options. Please try refreshing your page or come back after sometime.'
      )
    )
  }

  dispatch(setGenderLookup({ isLoading: false }))
}
export const fetchLanguagesLookup = () => async (dispatch, getState) => {
  try {
    const { languagesLookup } = getState().common
    dispatch(setLanguagesLookup({ isLoading: true }))
    if (languagesLookup.results.length === 0) {
      const { data } = await getLanguages()
      const finalRes = removeDuplicateItemsFromArray(data, 'displayText')
      dispatch(setLanguagesLookup({ results: finalRes }))
    }
  } catch {
    dispatch(
      showError(
        'There was issue fetching languages options. Please try refreshing your page or come back after sometime.'
      )
    )
  }

  dispatch(setLanguagesLookup({ isLoading: false }))
}
export const fetchMeansOfCommunications = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { meansOfCommunicationsLookup } = getState().common
    if (meansOfCommunicationsLookup.length === 0) {
      const { data } = await getMeansOfCommunication()
      dispatch(setMeansOfCommunicationsLookup(data))
    }
  } catch {
    dispatch(
      showError(
        'There was issue fetching "Means of communications" options. Please try refreshing your page or come back after sometime.'
      )
    )
  }
  dispatch(setLoading(false))
}

export const fetchAllAddressTypes = () => async (dispatch, getState) => {
  try {
    dispatch(setAllAddressTypes({ isLoading: true }))
    const { allAddressTypes } = getState().common
    if (allAddressTypes.results.length === 0) {
      const allAddressTypesResult = await getAddressTypes()
      dispatch(setAllAddressTypes({ results: allAddressTypesResult }))
    }
  } catch {
    dispatch(
      showError(
        'There was issue fetching "Address Type" options. Please try refreshing your page or come back after sometime.'
      )
    )
  } finally {
    dispatch(setAllAddressTypes({ isLoading: false }))
  }
}

export const fetchEnglishSkillsLookup = () => async (dispatch, getState) => {
  try {
    const { englishSkillsLookup } = getState().common
    dispatch(setEnglishSkillsLookup({ isLoading: true }))
    if (englishSkillsLookup.results.length === 0) {
      const { data } = await getEnglishSkillLevels()
      dispatch(setEnglishSkillsLookup({ results: data }))
    }
  } catch {
    dispatch(
      showError(
        'There was issue fetching English skill levels options. Please try refreshing your page or come back after sometime.'
      )
    )
  }

  dispatch(setEnglishSkillsLookup({ isLoading: false }))
}

export const fetchHelperAttachmentModules = () => async dispatch => {
  try {
    dispatch(setHelperAttachmentModules({ isLoading: true }))

    const { data } = await getHelperAttachmentModules()
    dispatch(setHelperAttachmentModules({ results: data }))
  } catch (e) {
    dispatch(showError('There was issue fetching Helper Attachements Modules.'))
  }

  dispatch(setHelperAttachmentModules({ isLoading: false }))
}

export const downloadHelperAttachment = attachment => async dispatch => {
  dispatch(setLoading(true))
  await downloadHelperAttachmentById(attachment)
    .then(() => { })
    .catch(() => {
      dispatch(showError('There was some issue with downloading the attachment.'))
    })
  dispatch(setLoading(false))
}

export const fetchTacticsLookup = () => async dispatch => {
  try {
    dispatch(setTacticsLookup({ isLoading: true }))
    const { data } = await getTacticsLookup()
    dispatch(setTacticsLookup({ results: data, isLoading: false }))
  } catch (e) {
    dispatch(setTacticsLookup({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch Tactics', e))
  }
}

export const fetchMediaPlanSitesLookup = () => async dispatch => {
  try {
    dispatch(setMediaPlanSitesLookup({ isLoading: true }))
    const { data } = await getMediaPlanSitesLookup()
    dispatch(setMediaPlanSitesLookup({ results: data, isLoading: false }))
  } catch (e) {
    dispatch(setMediaPlanSitesLookup({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch media sites', e))
  }
}

export const fetchMediaPlanStatusesLookup = () => async (dispatch, getState) => {
  try {
    const { mediaPlanStatusesLookup } = getState().common
    if (mediaPlanStatusesLookup.results.length === 0) {
      dispatch(setMediaPlanStatusesLookup({ isLoading: true }))
      const { data } = await getMediaPlanStatusesLookup()
      dispatch(setMediaPlanStatusesLookup({ results: data, isLoading: false }))
    }
  } catch (e) {
    dispatch(setMediaPlanStatusesLookup({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch media plan statuses', e))
  }
}

export const fetchEthicsType = () => async (dispatch, getState) => {
  try {
    dispatch(setEthicsTypesLookup({ isLoading: true }))
    const { ethicsTypesLookup } = getState().common
    if (ethicsTypesLookup.results.length === 0) {
      const res = await getEthicstypes()

      const finalRes = res?.data?.map(data => ({
        id: data.ethicTypeId,
        displayText: data.ethicsCommiteeType
      }))
      dispatch(setEthicsTypesLookup({ results: finalRes }))
    }
  } catch (e) {
    dispatch(() => {
      dispatch(
        showError(
          'There was some issue while trying to fetch Ethics Types dropdown options, Please try refreshing your page.', e
        )
      )
    })
  }

  dispatch(setEthicsTypesLookup({ isLoading: false }))
}

const initialState = {
  categoriesLookup: { isLoading: false, results: [] },
  programsLookup: { isLoading: false, results: [] },
  sitesLookup: { isLoading: false, results: [] },
  systemIDs: { isLoading: false, results: [] },
  countriesLookup: { isLoading: false, results: [] },
  messagesSendCategory: { isLoading: false, results: [] },
  systemIDsByLoggedInSite: { isLoading: false, results: [] },
  productsLookup: { results: [], isLoading: false },
  cohortsLookup: { results: [], isLoading: false },
  vendorsLookup: { results: [], isLoading: false },
  siteTypesLookup: { results: [], isLoading: false },
  siteStatusesLookup: { results: [], isLoading: false },
  siteNoteTypesLookup: { results: [], isLoading: false },
  siteDataPointTypes: { results: [], isLoading: false },
  supportPersonRelationships: [],
  supportPersonTypes: [],
  genderLookup: { isLoading: false, results: [] },
  languagesLookup: { isLoading: false, results: [] },
  meansOfCommunicationsLookup: [],
  englishSkillsLookup: { isLoading: false, results: [] },
  allAddressTypes: { isLoading: false, results: [] },
  countriesLookupForTransferFunds: { isLoading: false, results: [] },
  helperAttachmentModules: { isLoading: false, results: [] },
  tacticsLookup: { isLoading: false, results: [] },
  mediaPlanSitesLookup: { isLoading: false, results: [] },
  mediaPlanStatusesLookup: { isLoading: false, results: [] },
  ethicsTypesLookup: { isLoading: false, results: [] },
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setCategoriesLookup, (state, action) => {
      state.categoriesLookup = { ...state.categoriesLookup, ...action.payload }
    })
    .addCase(setProgramsLookup, (state, action) => {
      state.programsLookup = { ...state.programsLookup, ...action.payload }
    })
    .addCase(resetCommon, state => {
      copyObject(state, initialState)
    })
    .addCase(setSitesLookup, (state, action) => {
      state.sitesLookup = { ...state.sitesLookup, ...action.payload }
    })
    .addCase(setSystemIDs, (state, action) => {
      state.systemIDs = { ...state.systemIDs, ...action.payload }
    })
    .addCase(setCountriesLookup, (state, action) => {
      state.countriesLookup = { ...state.countriesLookup, ...action.payload }
    })
    .addCase(setMessagesSendCategory, (state, action) => {
      state.messagesSendCategory = { ...state.messagesSendCategory, ...action.payload }
    })
    .addCase(setSystemIDsByLoggedInSite, (state, action) => {
      state.systemIDsByLoggedInSite = { ...state.systemIDsByLoggedInSite, ...action.payload }
    })
    .addCase(setProductsLookup, (state, { payload }) => {
      state.productsLookup = { ...state.productsLookup, ...payload }
    })
    .addCase(setCohortsLookup, (state, { payload }) => {
      state.cohortsLookup = { ...state.cohortsLookup, ...payload }
    })
    .addCase(setVendorsLookup, (state, { payload }) => {
      state.vendorsLookup = { ...state.vendorsLookup, ...payload }
    })
    .addCase(setSiteTypesLookup, (state, { payload }) => {
      state.siteTypesLookup = { ...state.siteTypesLookup, ...payload }
    })
    .addCase(setSiteStatusesLookup, (state, { payload }) => {
      state.siteStatusesLookup = { ...state.siteStatusesLookup, ...payload }
    })
    .addCase(setSiteNoteTypesLookup, (state, { payload }) => {
      state.siteNoteTypesLookup = { ...state.siteNoteTypesLookup, ...payload }
    })
    .addCase(setSiteDataPointTypes, (state, { payload }) => {
      state.siteDataPointTypes = { ...state.siteDataPointTypes, ...payload }
    })
    .addCase(setSupportPersonRelationships, (state, action) => {
      state.supportPersonRelationships = action.payload
    })
    .addCase(setSupportPersonTypes, (state, action) => {
      state.supportPersonTypes = action.payload
    })
    .addCase(setGenderLookup, (state, action) => {
      state.genderLookup = { ...state.genderLookup, ...action.payload }
    })
    .addCase(setLanguagesLookup, (state, action) => {
      state.languagesLookup = { ...state.languagesLookup, ...action.payload }
    })
    .addCase(setMeansOfCommunicationsLookup, (state, action) => {
      state.meansOfCommunicationsLookup = action.payload
    })
    .addCase(setAllAddressTypes, (state, action) => {
      state.allAddressTypes = { ...state.allAddressTypes, ...action.payload }
    })
    .addCase(setCountriesForTransferFunds, (state, action) => {
      state.countriesLookupForTransferFunds = {
        ...state.countriesLookupForTransferFunds,
        ...action.payload
      }
    })
    .addCase(setEnglishSkillsLookup, (state, action) => {
      state.englishSkillsLookup = { ...state.englishSkillsLookup, ...action.payload }
    })
    .addCase(setHelperAttachmentModules, (state, action) => {
      state.helperAttachmentModules = { ...state.helperAttachmentModules, ...action.payload }
    })
    .addCase(setTacticsLookup, (state, action) => {
      state.tacticsLookup = { ...state.tacticsLookup, ...action.payload }
    })
    .addCase(setMediaPlanSitesLookup, (state, action) => {
      state.mediaPlanSitesLookup = { ...state.mediaPlanSitesLookup, ...action.payload }
    })
    .addCase(setMediaPlanStatusesLookup, (state, action) => {
      state.mediaPlanStatusesLookup = { ...state.mediaPlanStatusesLookup, ...action.payload }
    })
    .addCase(setEthicsTypesLookup, (state, action) => {
      state.ethicsTypesLookup = { ...state.ethicsTypesLookup, ...action.payload }
    })
})
