import React, { useEffect } from 'react'
import { Grid, InputAdornment } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSites, searchInSites } from 'store/ducks/siteManagement/siteManagement'
import { AutoComplete, Button, TextBox } from 'components/common'
import { Search } from '@mui/icons-material'
import { fetchSitesLookup, fetchCountriesLookup, fetchSiteStatusesLookup } from 'store/ducks/common'

// import * as Yup from 'yup'

const Wrapper = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing(2, 2, 2, 2)};
`,
)

const ActionWrapper = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-self: center;
    max-width: 200px;
  }
`
const SearchWrapper = styled(Grid)`
  align-self: center;
`

const SiteGridFilters = () => {
  const dispatch = useDispatch()

  const { sitesLookup, siteStatusesLookup, countriesLookup } = useSelector(store => store.common)
  const { filters } = useSelector(store => store.siteManagement)

  useEffect(() => {
    lookupsForFilters()
  }, [])

  const lookupsForFilters = async () => {
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchSitesLookup())
    await dispatch(fetchSiteStatusesLookup())
  }

  return (
    <Wrapper>
      <Formik
        initialValues={filters}
        enableReinitialize={true}
        // validationSchema={validations}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(fetchSites(values))
          setSubmitting(false)
        }}
      >
        {({ values, resetForm, setFieldValue }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <AutoComplete
                  size="medium"
                  id="siteIDs"
                  label="Site ID"
                  name="siteIDs"
                  options={sitesLookup.results}
                  isLoading={sitesLookup.isLoading}
                  multiple={true}
                  hasSelectAllOption={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <AutoComplete
                  size="medium"
                  id="countries"
                  label="Country of Site"
                  name="countries"
                  options={countriesLookup.results}
                  isLoading={countriesLookup.isLoading}
                  multiple={true}
                  hasSelectAllOption={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <AutoComplete
                  size="medium"
                  id="siteStatuses"
                  label="Site Status"
                  name="siteStatuses"
                  options={siteStatusesLookup.results}
                  multiple={true}
                />
              </Grid>
              <SearchWrapper item xs={12} sm={6} md={3} lg={2}>
                <TextBox
                  variant="outlined"
                  id="site-list-search"
                  name="search"
                  label="Search grid data"
                  size="small"
                  value={values.search}
                  onChange={e => {
                    setFieldValue('search', e.target.value)
                    dispatch(searchInSites({ ...values, search: e.target.value }))
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  rounded="true"
                />
              </SearchWrapper>
              <ActionWrapper item xs={12} sm={12} md={3} lg={2.5}>
                <Button type="submit" fullWidth={false} color="primary">
                  Submit
                </Button>
                <Button fullWidth={false} color="inherit" onClick={() => {
                  resetForm()
                  setFieldValue('search', '')
                  dispatch(fetchSites())
                }}>
                  Clear Filter
                </Button>
              </ActionWrapper>
            </Grid>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default SiteGridFilters
