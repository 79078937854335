import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { saveSupplementalInformation } from 'services/patientRecord/patientProfile'
import { showSuccess, showError } from 'store/ducks/application'

const EditSupplementalInfo = ({ userId, userSupportPersonId }) => {
  const dispatch = useDispatch()
  const { isLoading, supplementalInfo } = useSelector(store => store.patientProfile.supplementalInfo)

  const validationSchema = Yup.object({
    passportNumber: Yup.string().when('hasNoPassport', {
      is: false,
      then: Yup.string().trim().required('Required'),
    }),
    passportExpirationDate: Yup.string()
      .nullable()
      .when('hasNoPassport', {
        is: false,
        then: Yup.string().required('Required'),
      }),
    passportIssuedCountry: Yup.object()
      .nullable()
      .when('hasNoPassport', {
        is: false,
        then: Yup.object().required('Required'),
      }),
    hasNoPassport: Yup.bool(),
  })

  const submitHandler = async data => {
    const requestData = {
      userId,
      userSupportPersonId: userSupportPersonId || null,
      passportNumber: data.passportNumber.trim(),
      passportExpirationDate: data.passportExpirationDate || '',
      passportIssuedCountryId: data.passportIssuedCountry?.id || null,
      hasNoPassport: data.hasNoPassport,
      supplementalInformationId: data.supplementalInformationId,
    }
    try {
      await saveSupplementalInformation(requestData)
      dispatch(showSuccess('Information saved successfully!'))
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    }
  }

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        {[...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
            <Box>
              <Skeleton height={64} data-testid="skeleton" />
            </Box>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Formik
      initialValues={supplementalInfo?.results || {}}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {() => (
        <div style={{ width: '100%' }}>
          <Typography variant="h4">
            <b>TCN Arrive</b>
          </Typography>
          <br />
        </div>
      )}
    </Formik>
  )
}

EditSupplementalInfo.propTypes = {
  userId: PropTypes.string.isRequired,
  userSupportPersonId: PropTypes.string,
}

EditSupplementalInfo.defaultProps = {
  userSupportPersonId: null,
}

export default EditSupplementalInfo
