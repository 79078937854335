import React, { useEffect } from 'react'
import { Grid, InputAdornment } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFiltersLookup } from 'store/ducks/application'
import { fetchListOfPatients, setFilters, initialState, setAlignment } from 'store/ducks/patientExperienceManager'
import { AutoComplete, Button, TextBox } from 'components/common'
import { Search } from '@mui/icons-material'
import { fetchSitesLookup, fetchCountriesLookup } from 'store/ducks/common'

// import * as Yup from 'yup'

const Wrapper = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing(2, 2, 2, 2)};
`,
)

const ActionWrapper = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-self: center;
    max-width: 200px;
  }
`
const SearchWrapper = styled(Grid)`
  align-self: center;
`

const PatientGridFilters = () => {
  const dispatch = useDispatch()
  const { participationProgressLookup, sourceLookup } = useSelector(store => store.application)
  const { sitesLookup, countriesLookup } = useSelector(store => store.common)
  const { filters } = useSelector(store => store.patientExperienceManager)

  useEffect(() => {
    lookupsForFilter()
  }, [])

  const lookupsForFilter = async () => {
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchSitesLookup())
    await dispatch(fetchFiltersLookup())
  }

  // todo: add some kind of validation here, so that user should get a message for filling out form before clicking submit
  // const validations = Yup.object().shape({
  //   sites: Yup.string(),
  //   countries: Yup.string(),
  //   sources: Yup.string(),
  //   participationProgresses: Yup.string(),
  //   AorB: Yup.bool().when(['sites', 'countries', 'sources', 'participationProgresses'], {
  //     is: (sites, countries, sources, participationProgresses) =>
  //       (!sites && !countries && !sources && !participationProgresses) ||
  //       (!!sites && !!countries && !!sources && !!participationProgresses),
  //     then: Yup.bool().required('some error msg'),
  //     otherwise: Yup.bool(),
  //   }),
  // })

  return (
    <Wrapper>
      <Formik
        initialValues={filters}
        enableReinitialize={true}
        // validationSchema={validations}
        onSubmit={async (values, { setSubmitting }) => {
          await dispatch(setFilters(values))
          await dispatch(fetchListOfPatients())
          setSubmitting(false)
        }}
      >
        {({ values, resetForm, setFieldValue }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <AutoComplete
                  size="medium"
                  id="source"
                  label="Referral Source"
                  name="sources"
                  options={sourceLookup}
                  multiple={true}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={2}>
                <AutoComplete
                  size="medium"
                  id="participation-progress"
                  label="Participation Progress"
                  name="participationProgresses"
                  options={participationProgressLookup}
                  multiple={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.5}>
                <AutoComplete
                  size="medium"
                  id="site-ID"
                  label="Site ID"
                  name="sites"
                  options={sitesLookup.results}
                  isLoading={sitesLookup.isLoading}
                  multiple={true}
                  hasSelectAllOption={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <AutoComplete
                  size="medium"
                  id="country"
                  label="Country of Site"
                  name="countries"
                  options={countriesLookup.results}
                  isLoading={countriesLookup.isLoading}
                  multiple={true}
                  hasSelectAllOption={true}
                />
              </Grid>

              <SearchWrapper item xs={12} sm={6} md={3} lg={2}>
                <TextBox
                  variant="outlined"
                  id="patient-list-search"
                  name="search"
                  label="Search grid data"
                  size="small"
                  value={values.search}
                  onChange={async e => {
                    setFieldValue('search', e.target.value)
                    await dispatch(fetchListOfPatients())
                    dispatch(setFilters({ ...values, search: e.target.value }))
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  rounded="true"
                />
              </SearchWrapper>
              <ActionWrapper item xs={12} sm={12} md={3} lg={2.5}>
                <Button type="submit" fullWidth={false} color="primary">
                  Submit
                </Button>
                <Button fullWidth={false} color="inherit" onClick={async () => {
                  resetForm()
                  setFieldValue('search', '')
                  await dispatch(setFilters(initialState.filters))
                  await dispatch(setAlignment('all'))
                  await dispatch(fetchListOfPatients())
                }}>
                  Clear Filter
                </Button>
              </ActionWrapper>
            </Grid>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default PatientGridFilters
