/* eslint-disable max-lines */
import {
  getAdditionalPrescreeningReport,
  getMediaPlanOverview,
  getMediaPlannedVsActual,
  getPatientProfileReport,
  getPatientUsageActivityReport,
  getPrescreeningReport,
  getPrescreeningWithNotesReport,
  getReimbursementReportBySite,
  getSecondaryPrescreeningReport,
  getSiteDataPointsReport,
  getSiteManagementSitePerformance,
  getSiteNotesReport,
  getSitePerformance,
  getSitePreferencesReport,
  getSiteStatusAndAddresses,
  getSummaryAndSiteDetailsReport,
  getTCNCardPatientUsageReport,
  getParticipationProgressReport,
  getParticipationProgressNotesReport,
  getParticipantStatusHistoryReport
} from 'services/reports'
import { showError } from './application'
import {
  assignAlphabetKeys,
  displayDate,
  displayTime,
  exportToCSV,
  checkPermissions,
  createFinalSheet,
} from 'components/helper/utility'
import {
  sitePerformanceReportHeaders,
  summaryAndDetailsReportCommonHeaders,
  summaryAndDetailsSheets,
  summaryAndDetailsSheetNames,
  reimbursementReportByVisitHeadersOne,
  reimbursementReportByVisitHeadersTwo,
  tcnCardPatientUsageHeaders,
  siteDataPointsHeaders,
  siteNotesHeaders,
  sitePreferencesHeadersOne,
  sitePreferencesHeadersTwo,
  siteStatusAndAddressesHeaders,
  siteManagementPerformanceHeaders,
  mediaOverviewHeaders,
  mediaPlannedVsActualHeaders,
  patientProfileReportHeaders,
  patientUsageActivityHeaders,
  secondaryPrescreeningHeaders,
  additionalPrescreeningHeaders,
  prescreeningReportHeaderOne,
  prescreeningReportHeaderTwo,
  prescreeningReportNotesHeaders,
  participationProgressReportHeaderOne,
  participationProgressReportHeaderTwo,
  participationProgressReportHeaderThree,
  participationProgressNotesHeader,
  participantStatusHistoryHeader
} from 'components/helper/constants/reports'
import { setLoading } from './userInteractions'

export const fetchSitePerformance = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getSitePerformance()

    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
    const rangeStylesObject = {}
    const columnStylesObject = {}
    const fileName = `${protocolNo}-${campaignName}-TCN Arrive Impact Report`
    const dateColumns = [sitePerformanceReportHeaders.OrientationDate]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    const headers = assignAlphabetKeys(sitePerformanceReportHeaders)
    // Adding styles to each sheet
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(sitePerformanceReportHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(sitePerformanceReportHeaders[columnKey])) { // comparing with column label not column key
          finalTableRow[columnKey] = new Date(tableRow[columnKey])
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName: 'Impact Report'
      }
    ]
    exportToCSV(finalReport, fileName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch site performance report.', e))
  }
}

export const fetchSummaryAndSiteDetailsReport = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getSummaryAndSiteDetailsReport()
    const finalSheets = []

    let fileName
    const dateColumns = [
      summaryAndDetailsReportCommonHeaders.TCNCreateDate,
      summaryAndDetailsReportCommonHeaders.RequestDate,
      summaryAndDetailsSheets.inpRequestDetails.ItineraryDownloadedByPatientOn,
      summaryAndDetailsSheets.requestSummary.VisitDate,
      summaryAndDetailsSheets.requestSummary.ReqNotApprovedDate,
      summaryAndDetailsSheets.requestSummary.ReqApprovedDate,
      summaryAndDetailsSheets.requestSummary.ReqCompletedDate,
      summaryAndDetailsSheets.requestSummary.ReqCancelledDate,
      summaryAndDetailsSheets.inpRequestDetails.VisitStartDate,
      summaryAndDetailsSheets.inpRequestDetails.VisitEndDate,
      summaryAndDetailsSheets.inpRequestDetails.CheckInDate,
      summaryAndDetailsSheets.inpRequestDetails.CheckOutDate,
      summaryAndDetailsSheets.addRequestDetails.VisitStartDate,
      summaryAndDetailsSheets.advRequestDetails.VisitStartDate,
      summaryAndDetailsSheets.remoteRequestDetails.VisitStartDate,
      summaryAndDetailsSheets.remoteRequestDetails.PickupDate,
      summaryAndDetailsSheets.remoteRequestDetails.DropoffDate
    ]

    Object.keys(data).forEach((eachReportKey, index) => {
      const { protocolNo, campaignName, title, reportDate } = data[eachReportKey].reportHeaders
      const rangeStylesObject = {}
      const columnStylesObject = {}
      // Each object will act as an row in Excel
      let finaleTables = [
        { A: 'TCN® Engage' },
        { A: protocolNo },
        { A: campaignName },
        { A: title },
        { A: `${displayDate(reportDate)} ${displayTime(reportDate)}` }
      ]

      if (index === 0) {
        fileName = `${protocolNo}-${campaignName}-TCN Arrive Request Summary and Details`
      }

      finaleTables.push('')
      const combinedHeaders = {
        ...summaryAndDetailsReportCommonHeaders,
        ...summaryAndDetailsSheets[eachReportKey]
      }
      const headers = assignAlphabetKeys(combinedHeaders)

      // Adding styles to each sheet
      rangeStylesObject['A1:A5'] = {
        bold: true,
        horizontalAlignment: 'left',
        fontFamily: 'Arial',
        fontSize: 10
      }

      // loop through the header keys of the main table and find the column that has `date` in the key
      Object.keys(headers).forEach(excelColumnAlphabet => {
        if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
          columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
        }
      })

      rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
        bold: true,
        horizontalAlignment: 'left',
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }

      finaleTables.push(headers)

      const mainTable = data[eachReportKey].reportData.map((tableRow, index) => {
        const finalTableRow = {}

        Object.keys(combinedHeaders).forEach(columnKey => {
          if (tableRow[columnKey] && dateColumns.includes(combinedHeaders[columnKey])) { // comparing with column label not column key
            finalTableRow[columnKey] = new Date(tableRow[columnKey])
          } else if (columnKey === 'MobilityAssistance') {
            let parsedValue
            try {
              parsedValue = JSON.parse(tableRow[columnKey])
            } catch {
              parsedValue = ''
            }
            finalTableRow[columnKey] =
              parsedValue && parsedValue.length
                ? parsedValue.map(value => value.displayText).join(';')
                : ''
          } else {
            finalTableRow[columnKey] = tableRow[columnKey]
          }
        })
        const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

        rangeStylesObject[
          `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
        ] = {
          wrapText: true,
          fontFamily: 'Arial',
          fontSize: 10
        }
        return rowItem
      })
      finaleTables = finaleTables.concat(mainTable)

      finalSheets.push({
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName: `TCN Arrive ${summaryAndDetailsSheetNames[eachReportKey]}`
      })
    })

    exportToCSV(finalSheets, fileName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch Summary & Site Details report.', e))
  }
}

export const fetchReimbursementReportsByVisit = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getReimbursementReportBySite()
    const { reimbursementsByVisit, serviceLookup } = data
    const { protocolNo, campaignName, title, reportDate } = reimbursementsByVisit.reportHeaders
    const sheetName = 'Reimbursements by Visit'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`
    const rangeStylesObject = {}
    const columnStylesObject = {}
    const dateColumns = [
      reimbursementReportByVisitHeadersOne.TCNCreateDate,
      reimbursementReportByVisitHeadersOne.ActualReimbursementDate,
      reimbursementReportByVisitHeadersOne.ExpirationDate,
      reimbursementReportByVisitHeadersOne.VisitDate
    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    // loop through each object in the reportData array, extract the keys containing underscores, and then match those keys with the corresponding displayText from the serviceLookup array based on the IDs.

    // for example outputObject = {4_Amount: "Airfare in Local Currency", 4_CapAmount: "Airfare Guideline in Local Currency", 4_IsExceeded:  "Airfare Exceeded"}

    const categoriesObject = {}

    reimbursementsByVisit.reportData.forEach(dataObject => {
      for (const key in dataObject) {
        if (key.includes('_')) {
          const [id, category] = key.split('_')

          const serviceInfo = serviceLookup.find(item => item.id === parseInt(id))
          if (serviceInfo) {
            const displayText = serviceInfo.displayText
            if (category === 'Amount') {
              // if the id is 15, that means it is Total in Card Currency, so use the displayText as it is given in the response
              if (parseInt(id) === 15) {
                categoriesObject[key] = 'Total in Card Currency' // because business team wants "Total in Card Currency" column: lowercase the i in "in" (consistency purposes)
              } else {
                categoriesObject[key] = `${displayText} in Local Currency`
              }
            } else if (category === 'CapAmount') {
              categoriesObject[key] = `${displayText} Guideline in Local Currency`
            } else if (category === 'IsExceeded') {
              categoriesObject[key] = `${displayText} Exceeded`
            }
          }
        }
      }
    })

    const finalHeaders = {
      ...reimbursementReportByVisitHeadersOne,
      ...categoriesObject,
      ...reimbursementReportByVisitHeadersTwo
    }

    const headers = assignAlphabetKeys(finalHeaders)

    // Adding styles to each sheet
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      numberFormat: 'Text',
      fontFamily: 'Arial',
      fontSize: 10
    }

    finaleTables.push(headers)

    const mainTable = reimbursementsByVisit.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(finalHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
          if (columnKey === 'ExpirationDate') {
            finalTableRow[columnKey] = `${displayDate(tableRow[columnKey], 'MM/yyyy', 'MM/yyyy')}`
          } else {
            finalTableRow[columnKey] = new Date(tableRow[columnKey])
          }
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch Reimbursements By Visit report.', e))
  }
}

export const fetchTCNCardPatientUsageReport = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getTCNCardPatientUsageReport()
    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
    const rangeStylesObject = {}
    const columnStylesObject = {}

    const sheetName = 'TCN Card Usage'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`

    const dateColumns = [
      tcnCardPatientUsageHeaders.TCNCreateDate,
      tcnCardPatientUsageHeaders.CardAssignedDate,
      tcnCardPatientUsageHeaders.ExpirationDate,
      tcnCardPatientUsageHeaders.TermsAcceptedDate,
      tcnCardPatientUsageHeaders.RequestPlasticCardDate,
      tcnCardPatientUsageHeaders.ActivatePlasticCardDate,
      tcnCardPatientUsageHeaders.LastLoginDate
    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    // Note section
    finaleTables = finaleTables.concat([
      { A: 'NOTE: This report displays a row per cardholder.' },
      {
        A: 'Card-specific data is that of the cardholder\'s current card (e.g. "Request Plastic Card" date). All other data is on a cardholder account level (e.g. "Number of PIN Inquiries")'
      },
      { A: 'Report records actions by all policy users' }
    ])

    finaleTables.push('')

    const headers = assignAlphabetKeys(tcnCardPatientUsageHeaders)
    // Adding styles to each sheet
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject['A7:A9'] = {
      bold: true,
      italic: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    rangeStylesObject[`A11:${Object.keys(headers).pop()}11`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(tcnCardPatientUsageHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(tcnCardPatientUsageHeaders[columnKey])) { // comparing with column label not column key
          if (columnKey === 'ExpirationDate') {
            finalTableRow[columnKey] = `${displayDate(tableRow[columnKey], 'MM/yyyy', 'MM/yyyy')}`
          } else {
            finalTableRow[columnKey] = new Date(tableRow[columnKey])
          }
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 12}:${Object.keys(rowItem).pop()}${index + 12}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch Patient Usage report.', e))
  }
}

export const fetchSiteInsights = policyPermissions => async dispatch => {
  dispatch(setLoading(true))
  try {
    const permissionsSiteDataPointsReport = await checkPermissions('reportSiteInsightsDataPoints', policyPermissions)
    const permissionsSiteNotesReport = await checkPermissions('reportSiteInsightsNotes', policyPermissions)
    const permissionsSitePreferencesReport = await checkPermissions('reportSiteInsightsPreferences', policyPermissions)

    const siteDataPointsReport = permissionsSiteDataPointsReport ? await fetchSiteDataPointsReports() : {}
    const siteNotesReport = permissionsSiteNotesReport ? await fetchSiteNotesReports() : {}
    const sitePreferencesReport = permissionsSitePreferencesReport ? await fetchSitePreferencesReports() : {}

    const fileName = `${siteDataPointsReport?.fileName}-Site Insights`
    const reportsArray = [siteDataPointsReport, siteNotesReport, sitePreferencesReport]
    const finalSheets = createFinalSheet(reportsArray)

    exportToCSV(finalSheets, fileName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch Site Insights Report.', e))
  }
}

const fetchSiteDataPointsReports = async () => {
  const { data } = await getSiteDataPointsReport()

  const { siteDataPoints, dynamicHeaderLookup } = data

  const { protocolNo, campaignName, title, reportDate } = siteDataPoints.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}
  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [
    siteDataPointsHeaders.PlannedSiteActivationDate,
    siteDataPointsHeaders.ActivationDate,
    siteDataPointsHeaders.EnrollmentPausedDate,
    siteDataPointsHeaders.ReactivationDate,
    siteDataPointsHeaders.ClosureDate,
    siteDataPointsHeaders.DroppedDate,
    siteDataPointsHeaders.PSCLastLoginDate,
    siteDataPointsHeaders.ASCLastLoginDate
  ]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const categoriesHeaderAboveMainHeader = {}
  const dynamicHeaderObject = {}

  siteDataPoints.reportData.forEach(dataObject => {
    for (const key in dataObject) {
      if (key.includes('_')) {
        const dataPointId = key.split('_')[1]

        const dataPointObject = dynamicHeaderLookup.find(item => item.id === parseInt(dataPointId))
        if (dataPointObject) {
          dynamicHeaderObject[key] = dataPointObject.displayText
        }
      }
    }
  })

  const finalHeaders = { ...siteDataPointsHeaders, ...dynamicHeaderObject }

  for (const key in finalHeaders) {
    if (key.includes('_')) {
      const dataPointId = key.split('_')[1]

      const dataPointObject = dynamicHeaderLookup.find(item => item.id === parseInt(dataPointId))
      if (dataPointObject) {
        categoriesHeaderAboveMainHeader[key] = dataPointObject.alternateIdentifier
      }
    } else {
      categoriesHeaderAboveMainHeader[key] = ''
    }
  }

  const alphabetAssignedCategoriesHeaders = assignAlphabetKeys(categoriesHeaderAboveMainHeader)
  const alphabetAssignedHeaders = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(alphabetAssignedHeaders).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(alphabetAssignedHeaders[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(alphabetAssignedHeaders).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }

  rangeStylesObject[`A8:${Object.keys(alphabetAssignedHeaders).pop()}8`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(alphabetAssignedCategoriesHeaders)
  finaleTables.push(alphabetAssignedHeaders)

  const mainTable = siteDataPoints.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 9}:${Object.keys(rowItem).pop()}${index + 9}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Site Data Points'
  }

  return finalReport
}

const fetchSiteNotesReports = async () => {
  const { data } = await getSiteNotesReport()

  const { protocolNo, campaignName, title, reportDate } = data.reportHeaders

  const rangeStylesObject = {}
  const columnStylesObject = {}

  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [siteNotesHeaders.CreatedDate]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const headers = assignAlphabetKeys(siteNotesHeaders)
  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(headers)

  const mainTable = data.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(siteNotesHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(siteNotesHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
    ] = {
      wrapText: true,
      numberFormat: '@',
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Site Notes'
  }

  return finalReport
}

const fetchSitePreferencesReports = async () => {
  const { data } = await getSitePreferencesReport()

  const { sitePreferences, dynamicHeaderLookup } = data

  const { protocolNo, campaignName, title, reportDate } = sitePreferences.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}

  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [sitePreferencesHeadersTwo.MostRecentQuestionAnsweredDate]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const dynamicHeaderObject = {}

  sitePreferences.reportData.forEach(dataObject => {
    for (const key in dataObject) {
      if (key.includes('_')) {
        const questionId = key.split('_')[1]

        const serviceInfo = dynamicHeaderLookup.find(item => item.id === parseInt(questionId))

        if (serviceInfo) {
          const parser = new DOMParser()
          const parsedHTML = parser.parseFromString(serviceInfo.displayText, 'text/html')
          dynamicHeaderObject[key] = parsedHTML.body.textContent
        }
      }
    }
  })

  const finalHeaders = {
    ...sitePreferencesHeadersOne,
    ...dynamicHeaderObject,
    ...sitePreferencesHeadersTwo
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(headers)

  const mainTable = sitePreferences.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Site Preferences'
  }

  return finalReport
}

export const fetchPrescreeningReports = policyPermissions => async dispatch => {
  dispatch(setLoading(true))
  try {
    const permissionsPrescreeningReport = await checkPermissions('reportPrescreener', policyPermissions)
    const permissionsPrescreeningWithNotesReport = await checkPermissions('reportPrescreenerWithNotes', policyPermissions)
    const permissionsSecondaryPrescreeningReport = await checkPermissions('reportSecondaryPrescreening', policyPermissions)
    const permissionsAdditionalPrescreeningReport = await checkPermissions('reportAdditionalPrescreening', policyPermissions)

    const prescreeningReport = permissionsPrescreeningReport ? await fetchPrescreeningReport() : {}
    const prescreeningWithNotesReport = permissionsPrescreeningWithNotesReport ? await fetchPrescreeningWithNotesReport() : {}
    const secondaryPrescreeningReport = permissionsSecondaryPrescreeningReport ? await fetchSecondaryPrescreeningReport() : {}
    const additionalPrescreeningReport = permissionsAdditionalPrescreeningReport ? await fetchAdditionalPrescreeningReport() : {}

    const fileName = `${prescreeningReport?.fileName}-Prescreening`
    const reportsArray = [prescreeningReport, prescreeningWithNotesReport, secondaryPrescreeningReport, additionalPrescreeningReport]
    const finalSheets = createFinalSheet(reportsArray)

    exportToCSV(finalSheets, fileName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch Prescreening Report.', e))
  }
}

const fetchPrescreeningReport = async () => {
  const { data } = await getPrescreeningReport()

  const { protocolNo, campaignName, title, reportDate } = data.reportHeaders

  const rangeStylesObject = {}
  const columnStylesObject = {}

  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [
    prescreeningReportHeaderOne['Inquiry Start Date'],
    prescreeningReportHeaderTwo['TCN # Created Date'],
    prescreeningReportHeaderTwo['Status Last Updated'],
    prescreeningReportHeaderTwo['First Viewed by Site'],
    prescreeningReportHeaderTwo['Last Viewed by Site']
  ]

  const zipColumns = [
    prescreeningReportHeaderTwo['Site Zip / Postal Code'],
    prescreeningReportHeaderTwo['Inquiry Zip / Postal Code']
  ]
  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    { A: `${displayDate(reportDate)} ${displayTime(reportDate)}` }
  ]

  finaleTables.push('')

  const dynamicHeaderObject = {}
  if (data.reportData.length) {
    Object.keys(data.reportData[0]).forEach(header => {
      if (header.match(/Q\d+-/)) {
        dynamicHeaderObject[header] = header
      }
    })
  }

  const finalHeaders = {
    ...prescreeningReportHeaderOne,
    ...dynamicHeaderObject,
    ...prescreeningReportHeaderTwo
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    } else if (zipColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: '@' } // '@' is used for text format in Excel
    } else {
      columnStylesObject[excelColumnAlphabet] = { numberFormat: '@' }
    }
  })

  // Adding styles to each sheet
  rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }

  finaleTables.push(headers)

  const mainTable = data.reportData.map((tableRow, index) => {
    const finalTableRow = {}
    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = displayDate(tableRow[columnKey], 'MM/dd/yyyy')
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })

    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }

    return rowItem
  })

  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Prescreener Report'
  }

  return finalReport
}

const fetchPrescreeningWithNotesReport = async () => {
  const { data } = await getPrescreeningWithNotesReport()

  const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}

  const fileName = `${protocolNo}-${campaignName}`

  // Define columns that require date formatting
  const dateColumns = [
    prescreeningReportHeaderOne['Inquiry Start Date'],
    prescreeningReportHeaderTwo['TCN # Created Date'],
    prescreeningReportNotesHeaders['Note Added Date'],
    prescreeningReportHeaderTwo['Status Last Updated'],
    prescreeningReportHeaderTwo['First Viewed by Site'],
    prescreeningReportHeaderTwo['Last Viewed by Site']
  ]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    { A: `${displayDate(reportDate)} ${displayTime(reportDate)}` }
  ]

  // Add an empty row for spacing
  finaleTables.push('')

  // Build dynamic headers for additional data columns (e.g., question IDs)
  const dynamicHeaderObject = {}
  if (data.reportData.length) {
    Object.keys(data.reportData[0]).forEach(header => {
      if (header.match(/Q\d+-/)) {
        dynamicHeaderObject[header] = header
      }
    })
  }

  const finalHeaders = {
    ...prescreeningReportHeaderOne,
    ...dynamicHeaderObject,
    ...prescreeningReportHeaderTwo,
    ...prescreeningReportNotesHeaders
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) {
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    } else {
      columnStylesObject[excelColumnAlphabet] = { numberFormat: '@' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }

  finaleTables.push(headers)

  const mainTable = data.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = displayDate(tableRow[columnKey], 'MM/dd/yyyy')
      } else if (columnKey === 'Status' && typeof tableRow[columnKey] === 'string') {
        finalTableRow[columnKey] = tableRow[columnKey].replace(/^status:\s*/i, '')
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })

    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }

    return rowItem
  })

  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Prescreener with Notes'
  }

  return finalReport
}

const fetchSecondaryPrescreeningReport = async () => {
  const { data } = await getSecondaryPrescreeningReport()

  const { secondaryPrescreening, dynamicHeaderLookup } = data

  const { protocolNo, campaignName, title, reportDate } = secondaryPrescreening.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}
  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [
    secondaryPrescreeningHeaders.AssignedToSite,
    secondaryPrescreeningHeaders.TCNCreatedDate
  ]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const categoriesHeaderAboveMainHeader = {}
  const dynamicHeaderObject = {}

  secondaryPrescreening.reportData.forEach(dataObject => {
    for (const key in dataObject) {
      if (key.includes('_')) {
        const prescreeningQuestionId = key.split('_')[1]

        const prescreeningQuestionObject = dynamicHeaderLookup.find(item => item.id === parseInt(prescreeningQuestionId))
        if (prescreeningQuestionObject) {
          dynamicHeaderObject[key] = prescreeningQuestionObject.displayText
        }
      }
    }
  })

  const finalHeaders = { ...secondaryPrescreeningHeaders, ...dynamicHeaderObject }

  for (const key in finalHeaders) {
    if (key.includes('_')) {
      const prescreeningQuestionId = key.split('_')[1]

      const prescreeningQuestionObject = dynamicHeaderLookup.find(item => item.id === parseInt(prescreeningQuestionId))
      if (prescreeningQuestionObject) {
        categoriesHeaderAboveMainHeader[key] = prescreeningQuestionObject.alternateIdentifier
      }
    } else {
      categoriesHeaderAboveMainHeader[key] = ''
    }
  }

  const alphabetAssignedCategoriesHeaders = assignAlphabetKeys(categoriesHeaderAboveMainHeader)
  const alphabetAssignedHeaders = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(alphabetAssignedHeaders).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(alphabetAssignedHeaders[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(alphabetAssignedHeaders).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }

  rangeStylesObject[`A8:${Object.keys(alphabetAssignedHeaders).pop()}8`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(alphabetAssignedCategoriesHeaders)
  finaleTables.push(alphabetAssignedHeaders)

  const mainTable = secondaryPrescreening.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 9}:${Object.keys(rowItem).pop()}${index + 9}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Secondary Prescreening Q&A'
  }

  return finalReport
}

const fetchAdditionalPrescreeningReport = async () => {
  const { data } = await getAdditionalPrescreeningReport()

  const { additionalPrescreening, dynamicHeaderLookup } = data

  const { protocolNo, campaignName, title, reportDate } = additionalPrescreening.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}
  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [
    additionalPrescreeningHeaders.AssignedToSite,
    additionalPrescreeningHeaders.TCNCreatedDate,
  ]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const categoriesHeaderAboveMainHeader = {}
  const dynamicHeaderObject = {}

  additionalPrescreening.reportData.forEach(dataObject => {
    for (const key in dataObject) {
      if (key.includes('_')) {
        const prescreeningQuestionId = key.split('_')[1]

        const prescreeningQuestionObject = dynamicHeaderLookup.find(item => item.id === parseInt(prescreeningQuestionId))
        if (prescreeningQuestionObject) {
          dynamicHeaderObject[key] = prescreeningQuestionObject.displayText
        }
      }
    }
  })

  const finalHeaders = { ...additionalPrescreeningHeaders, ...dynamicHeaderObject }

  for (const key in finalHeaders) {
    if (key.includes('_')) {
      const prescreeningQuestionId = key.split('_')[1]

      const prescreeningQuestionObject = dynamicHeaderLookup.find(item => item.id === parseInt(prescreeningQuestionId))
      if (prescreeningQuestionObject) {
        categoriesHeaderAboveMainHeader[key] = prescreeningQuestionObject.alternateIdentifier
      }
    } else {
      categoriesHeaderAboveMainHeader[key] = ''
    }
  }

  const alphabetAssignedCategoriesHeaders = assignAlphabetKeys(categoriesHeaderAboveMainHeader)
  const alphabetAssignedHeaders = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(alphabetAssignedHeaders).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(alphabetAssignedHeaders[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(alphabetAssignedHeaders).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }

  rangeStylesObject[`A8:${Object.keys(alphabetAssignedHeaders).pop()}8`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(alphabetAssignedCategoriesHeaders)
  finaleTables.push(alphabetAssignedHeaders)

  const mainTable = additionalPrescreening.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 9}:${Object.keys(rowItem).pop()}${index + 9}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Additional Prescreening Q&A'
  }

  return finalReport
}

export const fetchSiteStatusAndAddresses = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const { data } = await getSiteStatusAndAddresses()

    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders

    const rangeStylesObject = {}
    const columnStylesObject = {}

    const sheetName = 'Site Status and Addresses'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`

    const dateColumns = [
      siteStatusAndAddressesHeaders.PlannedSiteActivationDate,
      siteStatusAndAddressesHeaders.ActivationDate,
      siteStatusAndAddressesHeaders.EnrollmentPausedDate,
      siteStatusAndAddressesHeaders.ReactivationDate,
      siteStatusAndAddressesHeaders.ClosureDate,
      siteStatusAndAddressesHeaders.DroppedDate,
      siteStatusAndAddressesHeaders.IMAttendanceDate,
      siteStatusAndAddressesHeaders.VerifiedOn
    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    const headers = assignAlphabetKeys(siteStatusAndAddressesHeaders)
    // Adding styles to each sheet
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(siteStatusAndAddressesHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(siteStatusAndAddressesHeaders[columnKey])) { // comparing with column label not column key
          finalTableRow[columnKey] = new Date(tableRow[columnKey])
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        numberFormat: '@',
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)

    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('Error while trying to download Site Status and Addresses Report', e))
  }
}

export const fetchSiteManagementPerformance = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const { data } = await getSiteManagementSitePerformance()
    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
    const rangeStylesObject = {}
    const columnStylesObject = {}
    const sheetName = 'Site Performance'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`
    const dateColumns = [
      siteManagementPerformanceHeaders.PlannedSiteActivationDate,
      siteManagementPerformanceHeaders.ActivationDate,
      siteManagementPerformanceHeaders.EnrollmentPausedDate,
      siteManagementPerformanceHeaders.ReactivationDate,
      siteManagementPerformanceHeaders.ClosureDate,
      siteManagementPerformanceHeaders.SiteStaffLastLoginDate,
      siteManagementPerformanceHeaders.PrimaryLocationforPatientCareVerifiedOn,
      siteManagementPerformanceHeaders.ShippingAddressforParcelsVerifiedOn
    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    const headers = assignAlphabetKeys(siteManagementPerformanceHeaders)
    // Adding styles to each table
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(siteManagementPerformanceHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(siteManagementPerformanceHeaders[columnKey])) { // comparing with column label not column key
          finalTableRow[columnKey] = new Date(tableRow[columnKey])
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)

    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('Error while trying to download Site management performance Report', e))
  }
}

export const fetchMediaPlanOverview = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const { data } = await getMediaPlanOverview()
    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
    const rangeStylesObject = {}
    const columnStylesObject = {}
    const sheetName = 'Media Overview'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`
    const dateColumns = [
      mediaOverviewHeaders.ActualMediaStartDate,
      mediaOverviewHeaders.ActualMediaEndDate,
    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    const headers = assignAlphabetKeys(mediaOverviewHeaders)
    // Adding styles to each table
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(mediaOverviewHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(mediaOverviewHeaders[columnKey])) { // comparing with column label not column key
          finalTableRow[columnKey] = new Date(tableRow[columnKey])
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)

    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('Error while trying to download Media Overview Report', e))
  }
}

export const fetchMediaPlannedVsActual = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const { data } = await getMediaPlannedVsActual()
    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
    const rangeStylesObject = {}
    const columnStylesObject = {}
    const sheetName = 'Media Planned vs Actual'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`
    const dateColumns = [
      mediaPlannedVsActualHeaders.PlannedStartDate,
      mediaPlannedVsActualHeaders.PlannedEndDate,
      mediaPlannedVsActualHeaders.ActualMediaStartDate,
      mediaPlannedVsActualHeaders.DataLockDateforActuals
    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    const headers = assignAlphabetKeys(mediaPlannedVsActualHeaders)
    // Adding styles to each table
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(mediaPlannedVsActualHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(mediaPlannedVsActualHeaders[columnKey])) { // comparing with column label not column key
          finalTableRow[columnKey] = new Date(tableRow[columnKey])
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)

    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('Error while trying to download Planned vs Actual Report', e))
  }
}

export const fetchPatientProfileReports = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const { data } = await getPatientProfileReport()
    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
    const rangeStylesObject = {}
    const columnStylesObject = {}
    const sheetName = 'Patient Profile'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`
    const dateColumns = [
      patientProfileReportHeaders.TCNCreatedDate,
      patientProfileReportHeaders.AssignedToSite,
      patientProfileReportHeaders.PPChangeDate,
      patientProfileReportHeaders.FirstLoginDate,
      patientProfileReportHeaders.LastLoginDate,
      patientProfileReportHeaders.DateOfBirth,
      patientProfileReportHeaders.SP_DOB,

    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    const headers = assignAlphabetKeys(patientProfileReportHeaders)
    // Adding styles to each table
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(patientProfileReportHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(patientProfileReportHeaders[columnKey])) { // comparing with column label not column key
          finalTableRow[columnKey] = new Date(tableRow[columnKey])
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)

    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('Error while trying to download Patient Profile Report', e))
  }
}

export const fetchPatientUsageActivityReports = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const { data } = await getPatientUsageActivityReport()
    const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
    const rangeStylesObject = {}
    const columnStylesObject = {}
    const sheetName = 'Patient Usage & Activity'
    const fileName = `${protocolNo}-${campaignName}-${sheetName}`
    const dateColumns = [
      patientUsageActivityHeaders.TCNCreatedDate,
      patientUsageActivityHeaders.PPChangeDate,
      patientUsageActivityHeaders.ActivationEmailSentDate,
      patientUsageActivityHeaders.FirstLoginDate,
      patientUsageActivityHeaders.LastLoginDate,
      patientUsageActivityHeaders.TCNCardSignUpDate,
      patientUsageActivityHeaders['Arrive First Request Entered']
    ]

    // Each object will act as an row in Excel
    let finaleTables = [
      { A: 'TCN® Engage' },
      { A: protocolNo },
      { A: campaignName },
      { A: title },
      {
        A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
      }
    ]

    finaleTables.push('')

    const headers = assignAlphabetKeys(patientUsageActivityHeaders)
    // Adding styles to each table
    rangeStylesObject['A1:A5'] = {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }

    // loop through the header keys of the main table and find the column that has `date` in the key
    Object.keys(headers).forEach(excelColumnAlphabet => {
      if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
        columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
      }
    })

    rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
      bold: true,
      horizontalAlignment: 'left',
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    finaleTables.push(headers)

    const mainTable = data.reportData.map((tableRow, index) => {
      const finalTableRow = {}

      Object.keys(patientUsageActivityHeaders).forEach(columnKey => {
        if (tableRow[columnKey] && dateColumns.includes(patientUsageActivityHeaders[columnKey])) { // comparing with column label not column key
          finalTableRow[columnKey] = new Date(tableRow[columnKey])
        } else {
          finalTableRow[columnKey] = tableRow[columnKey]
        }
      })
      const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

      rangeStylesObject[
        `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
      ] = {
        wrapText: true,
        fontFamily: 'Arial',
        fontSize: 10
      }
      return rowItem
    })
    finaleTables = finaleTables.concat(mainTable)

    const finalReport = [
      {
        sheet: finaleTables,
        styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
        sheetName
      }
    ]
    exportToCSV(finalReport, fileName)

    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('Error while trying to download Patient Activity and Usage Report', e))
  }
}

export const fetchParticipationProgressReports = policyPermissions => async dispatch => {
  dispatch(setLoading(true))
  try {
    const permissionsParticipationProgressReport = await checkPermissions('reportParticipationProgress', policyPermissions)
    const permissionsParticipationProgressNotessReport = await checkPermissions('reportParticipationProgressNotes', policyPermissions)
    const permissionsParticipantStatusHistoryReport = await checkPermissions('reportParticipantStatusHistory', policyPermissions)

    const participationProgressReport = permissionsParticipationProgressReport ? await fetchParticipationProgressReport() : {}
    const participationProgressNotessReport = permissionsParticipationProgressNotessReport ? await fetchParticipationProgressNotesReport() : {}
    const participantStatusHistoryReport = permissionsParticipantStatusHistoryReport ? await fetchParticipantStatusHistoryReport() : {}

    const reportsArray = [participationProgressReport, participationProgressNotessReport, participantStatusHistoryReport]
    const finalSheets = createFinalSheet(reportsArray)

    exportToCSV(finalSheets, participationProgressReport?.fileName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch Participation Progress Report.', e))
  }
}

const fetchParticipationProgressReport = async () => {
  const { data } = await getParticipationProgressReport()

  const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}

  const fileName = `${protocolNo}-${campaignName}-${title}`

  const dateColumns = [participationProgressReportHeaderTwo.TCNCreatedDate, participationProgressReportHeaderTwo.AssignedToSite, participationProgressReportHeaderTwo.StatusUpdateDate, participationProgressReportHeaderTwo.FirstViewedDate, participationProgressReportHeaderTwo.LastViewedDate, participationProgressReportHeaderThree.CreatedOn]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: campaignName },
    { A: protocolNo },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const finalHeaders = {
    ...participationProgressReportHeaderOne,
    ...participationProgressReportHeaderTwo,
    ...participationProgressReportHeaderThree
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(headers)

  const mainTable = data.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Participation Progress'
  }

  return finalReport
}

const fetchParticipationProgressNotesReport = async () => {
  const { data } = await getParticipationProgressNotesReport()

  const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}

  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [participationProgressReportHeaderTwo.TCNCreatedDate, participationProgressReportHeaderTwo.AssignedToSite, participationProgressReportHeaderTwo.StatusUpdateDate, participationProgressReportHeaderTwo.FirstViewedDate, participationProgressReportHeaderTwo.LastViewedDate, participationProgressNotesHeader.CreatedOn]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const finalHeaders = {
    ...participationProgressReportHeaderOne,
    ...participationProgressReportHeaderTwo,
    ...participationProgressNotesHeader
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(headers)

  const mainTable = data.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Participation Progress Notes'
  }

  return finalReport
}

const fetchParticipantStatusHistoryReport = async () => {
  const { data } = await getParticipantStatusHistoryReport()

  const { protocolNo, campaignName, title, reportDate } = data.reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}

  const fileName = `${protocolNo}-${campaignName}`

  const dateColumns = [participantStatusHistoryHeader.TCNCreatedDate, participantStatusHistoryHeader.AssignedToSite, participantStatusHistoryHeader['Subject ID Association Date'], participantStatusHistoryHeader.AppointmentDate, participantStatusHistoryHeader.StatusDate]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const finalHeaders = {
    ...participationProgressReportHeaderOne,
    ...participantStatusHistoryHeader
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A5'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A7:${Object.keys(headers).pop()}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(headers)

  const mainTable = data.reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 8}:${Object.keys(rowItem).pop()}${index + 8}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    fileName,
    sheetName: 'Participation Status History'
  }

  return finalReport
}
