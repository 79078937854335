import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { DataGrid } from 'components/common'
import { palette } from 'styles/baseTheme'
import { displayDate } from 'components/helper/utility'
import { fetchEthicCommitteeAssociationDetails } from 'store/ducks/siteManagement/ecDirectory/ecManagement'

const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
`
const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}

// eslint-disable-next-line max-lines-per-function
const SiteIdAssociations = () => {
  const { encrypttedEthicID } = useParams()
  const dispatch = useDispatch()

  const { ecAssociationDetails } = useSelector(store => store.ecManagement)

  let formattedECADetails = []
  if (ecAssociationDetails && ecAssociationDetails.length > 0) {
    formattedECADetails = ecAssociationDetails.map(note => ({
      ...note,
      createdDate: displayDate(note.createdDate)
    }))
  }
  const refreshECAssociations = () => {
    dispatch(fetchEthicCommitteeAssociationDetails(encrypttedEthicID))
  }

  useEffect(() => {
    refreshECAssociations()
  }, [encrypttedEthicID])

  const headers = [
    {
      id: 'siteId',
      label: 'Site ID',
      bodyProperties: {
        width: '25%',
        textAlign: 'center'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'piLastName',
      label: 'PI Last Name',
      bodyProperties: {
        textAlign: 'center',
        width: '25%',
        cellType: 'html'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'provision',
      label: 'State / Province',
      bodyProperties: {
        width: '25%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'country',
      label: 'Country',
      bodyProperties: {
        width: '25%'
      },
      headProperties: { ...headerProperties }
    }
  ]

  return (
    <React.Fragment>
      <TableWrapper>
        <DataGrid
          headers={headers}
          tableData={formattedECADetails}
          setRequestedSort={() => {}}
          order={'DESC'}
          orderBy={'id'}
          alternateRowColored={true}
          noRowsFoundMessage="No results found."
        />
      </TableWrapper>
    </React.Fragment>
  )
}

export default SiteIdAssociations
