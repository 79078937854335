import { createReducer, createAction } from '@reduxjs/toolkit'
import { setLoading } from './userInteractions'
import { showError, showSuccess } from './application'
import { copyObject } from 'components/helper/utility'
import {
  getAssignmentsPerPolicy,
  getBBKActionRoles,
  getModulesForTriggers,
  getPolicies,
  getExistingEmailTriggers,
  saveAssignmentsPerPolicy,
  getModuleAssignmentsPerPolicy,
  saveExistingEmailTriggers
} from 'services/smartAlerts'
import * as constants from 'components/helper/constants/smartAlerts'

const defaultSelectedPolicies = [
  { id: 12, displayText: 'Concierge', alternateIdentifier: null },
  { id: 13, displayText: 'Patient', alternateIdentifier: null },
  { id: 5, displayText: 'Site', alternateIdentifier: null },
]

export const setShowAssignmentForm = createAction('smartAlerts/setShowAssignmentForm')
export const setAssignmentsPerPolicyInformation = createAction('smartAlerts/setAssignmentsPerPolicyInformation')
export const setAssignmentsPerPolicyInitialValues = createAction('smartAlerts/setAssignmentsPerPolicyInitialValues')
export const setModuleAssignmentsPerPolicy = createAction('smartAlerts/setModuleAssignmentsPerPolicy')

export const setModulesForTriggers = createAction('smartAlerts/setModulesForTriggers')
export const setPolicies = createAction('smartAlerts/setPolicies')
export const setBBKActionRoles = createAction('smartAlerts/setBBKActionRoles')
export const setExistingEmailTriggers = createAction('smartAlerts/setExistingEmailTriggers')
export const setGridHeaders = createAction('smartAlerts/setGridHeaders')
export const setGridRows = createAction('smartAlerts/setGridRows')
export const setInitialValues = createAction('smartAlerts/setInitialValues')

export const setSelectedModule = createAction('smartAlerts/setSelectedModule')
export const setSelectedPolicies = createAction('smartAlerts/setSelectedPolicies')
export const setShowActionRolesDropdown = createAction('smartAlerts/setShowActionRolesDropdown')
export const setSelectedBBKActionRoles = createAction('smartAlerts/setSelectedBBKActionRoles')
export const setUpdatedTriggers = createAction('smartAlerts/setUpdatedTriggers')
export const setUpdatedUsers = createAction('smartAlerts/setUpdatedUsers')
export const setInitialValuesUpdateCounter = createAction('smartAlerts/setInitialValuesUpdateCounter')
export const resetSmartAlert = createAction('smartAlerts/resetSmartAlert')

export const generateGrid = () => async (dispatch, getState) => {
  const {
    selectedModule,
    initialValuesUpdateCounter,
    existingEmailTriggers,
    modulesAssignmentsPerPolicy
  } = getState().smartAlerts

  const initialValues = {}
  let rows = []
  let moduleHeader = []
  const policyActionHeaders = []

  if (selectedModule && existingEmailTriggers.length > 0) {
    /** Set InitialValues */
    // Set selected users for selected policy
    existingEmailTriggers[0].triggerAssignments.forEach(assignment => {
      const policyActionId = `${assignment.policyId}-${assignment.luCommunicationActionRoleId || 0}`
      if (assignment.displayText !== 'BBK') {
        initialValues[policyActionId] = modulesAssignmentsPerPolicy[policyActionId]?.exisitingUserList || []
      }
    })

    existingEmailTriggers.forEach(trigger => {
      initialValues[trigger.luCommunicationEmailTriggerId] = {}
      trigger.triggerAssignments.forEach(assignment => {
        const policyActionId = `${assignment.policyId}-${assignment.luCommunicationActionRoleId || '0'}`
        initialValues[trigger.luCommunicationEmailTriggerId][policyActionId] = assignment.isChecked
      })
    })

    /** Set Rows of Grid */
    // First Row for multi-select dropdown
    const userRow = { [selectedModule.id.toString()]: '' }
    existingEmailTriggers[0].triggerAssignments.forEach(assignment => {
      const policyActionId = `${assignment.policyId}-${assignment.luCommunicationActionRoleId || 0}`
      // Only select Policies need User dropdown
      if (constants.policiesNeedUsersDropdownIds.includes(assignment.policyId)) {
        userRow[policyActionId] = `AutoComplete:${policyActionId}`
      }
      // CRA
      if (assignment.policyId === 4) {
        userRow[policyActionId] = 'Active Monitor(s)'
      }
      // Policy Site
      if (assignment.policyId === 5) {
        const policyAssigment = modulesAssignmentsPerPolicy[policyActionId]
        userRow[policyActionId] = policyAssigment ? policyAssigment.exisitingUserList.map(user => user.displayText).join(', ') : 'All Verified / Active Site Users'
      }
      // Policy Patient
      if (assignment.policyId === 13) {
        userRow[policyActionId] = 'Patient'
      }
    })

    // Rows for all triggers fetched for Module
    const triggerRows = existingEmailTriggers.map(trigger => {
      const triggerRow = { [selectedModule.id.toString()]: trigger.displayText }

      trigger.triggerAssignments.forEach(assignment => {
        if (assignment.displayText !== 'BBK') {
          const policyActionId = `${assignment.policyId}-${assignment.luCommunicationActionRoleId || 0}`
          const checkBoxName = `${trigger.luCommunicationEmailTriggerId}.${policyActionId}`
          triggerRow[policyActionId] = `checkbox:${checkBoxName}`
        }
      })

      return triggerRow
    })

    rows = [...[userRow], ...triggerRows]

    /** Set Headers of Grid */
    // Selected Module Name Header. Under this trigger names are displayed
    moduleHeader = [{
      id: selectedModule.id.toString(),
      label: selectedModule.displayText,
      key: selectedModule.id.toString(),
      type: 'module'
    }]

    // Dynamic selected Policy Headers
    existingEmailTriggers[0].triggerAssignments.forEach(assignment => {
      const policyActionId = `${assignment.policyId}-${assignment.luCommunicationActionRoleId || 0}`
      if (assignment.displayText !== 'BBK') {
        policyActionHeaders.push({
          id: policyActionId,
          label: assignment.displayText,
          key: policyActionId,
        })
      }
    })
  }

  dispatch(setInitialValues(initialValues))
  // Counter to use as key to rerender the Table
  dispatch(setInitialValuesUpdateCounter(initialValuesUpdateCounter + 1))
  dispatch(setGridRows(rows))

  const allHeaders = [...moduleHeader, ...policyActionHeaders]
  dispatch(setGridHeaders(allHeaders))
}

export const fetchAssignmentsPerPolicy = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const response = await getAssignmentsPerPolicy()

    const formattedAssignments = {}
    const initialValues = {}

    response.forEach(info => {
      const key = info.defaultActionRoleAssignmentId.toString()
      /**
       * Use alternatidentifier as the Key as id
       * The id sent in the API is encrypted
       * and its value for existing and availableUserList may differ
       * Causing Console warnings on UI
       */
      formattedAssignments[key] = {
        ...info,
        exisitingUserList: info.exisitingUserList.map(user => ({
          id: user.alternateIdentifier,
          displayText: user.displayText
        })),
        availableUsersList: info.availableUsersList.map(user => ({
          id: user.alternateIdentifier,
          displayText: user.displayText
        }))
      }
      initialValues[key] = [...formattedAssignments[key].exisitingUserList]
    })

    dispatch(setAssignmentsPerPolicyInformation(formattedAssignments))
    dispatch(setAssignmentsPerPolicyInitialValues(initialValues))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchModuleAssignmentsPerPolicy = (moduleId, selectedPolicies, selectedActionRoles) => async dispatch => {
  dispatch(setLoading(true))
  try {
    const policies = selectedPolicies.map(policy => policy.id).join(';')
    const actionRoles = selectedActionRoles.map(actionRole => actionRole.id).join(';')
    const modulesAssignmentsPerPolicy = await getModuleAssignmentsPerPolicy(moduleId, policies, actionRoles)

    /** Existing and Available users information is sent in this format
      {
        "id": "YT9zRL-dRNJ_uq97fQVA_uCT9bxV4wIhWNeCc-PFarI=",
        "displayText": "Concierge One",
        "alternateIdentifier": "c7698332-e718-47a4-894e-85e0614cb7b5"
      },
      Need to use the alternateIdentifier as the id, and removed the original id property.
      {
        "id": "c7698332-e718-47a4-894e-85e0614cb7b5",
        "displayText": "Concierge One",
      },
    */
    const formattedModulesAssignmentsPerPolicy = modulesAssignmentsPerPolicy.map(record => {
      if (record.availableUsersList !== null) {
        let newExisitingUserList = []
        if (record.exisitingUserList !== null) {
          newExisitingUserList = record.exisitingUserList.map(user => (
            {
              id: user.alternateIdentifier,
              displayText: user.displayText
            }
          ))
        }
        const newAvailableUsersList = record.availableUsersList.map(user => ({
          id: user.alternateIdentifier,
          displayText: user.displayText
        }))
        return {
          ...record,
          exisitingUserList: newExisitingUserList,
          availableUsersList: newAvailableUsersList
        }
      } else {
        return record
      }
    })

    const modulesAssignmentsPerPolicyTuples = formattedModulesAssignmentsPerPolicy.map(info => {
      const policyActionId = `${info.policyId.toString()}-${info.luCommunicationActionRoleId ? info.luCommunicationActionRoleId.toString() : '0'}`

      return [policyActionId, info]
    })

    const mappedModulesAssignmentsPerPolicy = Object.fromEntries(modulesAssignmentsPerPolicyTuples)

    dispatch(setModuleAssignmentsPerPolicy(mappedModulesAssignmentsPerPolicy))
    dispatch(generateGrid())
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateAssignmentsPerPolicy = (values, updatedIds) => async dispatch => {
  dispatch(setLoading(true))
  try {
    // values are in this format
    // {
    //  2: [{
    //    id:2eaa5268-0564-49d3-83f1-fd1fa7a8fc06,
    //    displayText: Anil Tammana,
    // }]
    // }

    // Convert to
    // [{
    //   "defaultActionRoleAssignmentId": 2,
    //   "userAssignmentEntityIds": [
    //     "5c5a2d00-8f52-44e7-9560-4e1ebfa51e96",
    //     "7237c64c-331a-41d4-96ac-7703c8ff16dc"
    //   ]
    // }]
    // }
    const formattedAssignments = Object.keys(values).map(key => {
      const userIds = values[key].map(user => user.id)
      return {
        defaultActionRoleAssignmentId: key,
        userAssignmentEntityIds: userIds
      }
    })

    await saveAssignmentsPerPolicy({
      assignmentsPerPolicy: formattedAssignments,
      updatedIds
    })
    dispatch(showSuccess('Assignments saved successfully!'))
    dispatch(setShowAssignmentForm(false))
    await dispatch(fetchExistingEmailTriggers())
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateExistingEmailTriggers = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { selectedModule, updatedTriggers, updatedUsers } = getState().smartAlerts
  try {
    /** values are in this format
      {
        "204": {
            "2-19": true
        },
        "205": {
            "2-18": true
        },
        "206": {
            "2-19": true,
            "2-18": true
        },
        "207": {
            "2-18": true,
            "2-19": true
        }
    }
     */

    /** Convert to
    {
    "luCommunicationModuleId": 89,
    "updatedEmailTriggers": [
      {
        "luCommunicationEmailTriggerId": 200,
        "luCommunicationActionRoleId": 18,
        "policyId": 2,
        "isChecked": false
      }],
      "updatedUserAssignments": [
        {
          "luCommunicationActionRoleId": 18,
          "policyId": 2,
          "userAssignmentEntityIds": ["f572511e-34d7-4f6f-85ad-5b2b0e191beb"]
        },
      ]
    }
     */
    const updatedEmailTriggers = Object.keys(updatedTriggers).map(triggerId => Object.keys(updatedTriggers[triggerId]).map(policyActionId => {
      const [policyId, luCommunicationActionRoleId] = policyActionId.split('-')

      return {
        luCommunicationEmailTriggerId: parseInt(triggerId),
        luCommunicationActionRoleId: luCommunicationActionRoleId === 0 ? null : parseInt(luCommunicationActionRoleId),
        policyId: parseInt(policyId),
        isChecked: updatedTriggers[triggerId][policyActionId]
      }
    })).flat()

    const updatedUserAssignments = Object.keys(updatedUsers).map(policyActionId => {
      const [policyId, luCommunicationActionRoleId] = policyActionId.split('-')
      const userAssignmentEntityIds = updatedUsers[policyActionId].map(user => user.id)

      return {
        luCommunicationActionRoleId: luCommunicationActionRoleId === 0 ? null : parseInt(luCommunicationActionRoleId),
        policyId: parseInt(policyId),
        userAssignmentEntityIds
      }
    })

    await saveExistingEmailTriggers({
      luCommunicationModuleId: selectedModule.id,
      updatedEmailTriggers,
      updatedUserAssignments
    })
    dispatch(showSuccess('Email Triggers saved successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchModulesForTriggers = () => async dispatch => {
  dispatch(setModulesForTriggers({ isLoading: true }))
  try {
    const results = await getModulesForTriggers()
    dispatch(setModulesForTriggers({ results, isLoading: false }))
  } catch (e) {
    dispatch(setModulesForTriggers({ isLoading: false }))
    dispatch(showError('Something went wrong!'))
  }
}

export const fetchPolicies = () => async dispatch => {
  dispatch(setPolicies({ isLoading: true }))
  try {
    const results = await getPolicies()
    dispatch(setPolicies({ results, isLoading: false }))
  } catch (e) {
    dispatch(setPolicies({ isLoading: false }))
    dispatch(showError('Something went wrong!'))
  }
}

export const fetchBBKActionRoles = () => async dispatch => {
  dispatch(setBBKActionRoles({ isLoading: true }))
  try {
    const results = await getBBKActionRoles()
    dispatch(setBBKActionRoles({ results, isLoading: false }))
  } catch (e) {
    dispatch(setBBKActionRoles({ isLoading: false }))
    dispatch(showError('Something went wrong!'))
  }
}

export const fetchExistingEmailTriggers = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { selectedModule, selectedPolicies, selectedBBKActionRoles } = getState().smartAlerts
  const moduleId = selectedModule.id
  try {
    const policies = selectedPolicies.map(policy => policy.id).join(';')
    const actionRoles = selectedBBKActionRoles.map(actionRole => actionRole.id).join(';')

    const existingEmailTriggers = await getExistingEmailTriggers(moduleId, policies, actionRoles)
    dispatch(setExistingEmailTriggers(existingEmailTriggers))

    // Stagger calls to avoid duplicate key issue
    dispatch(fetchModuleAssignmentsPerPolicy(moduleId, selectedPolicies, selectedBBKActionRoles))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  showAssignmentForm: false,
  assignmentsPerPolicyInformation: {},
  assignmentsPerPolicyInitialValues: {},
  modulesAssignmentsPerPolicy: {},
  modulesForTriggers: { isLoading: false, results: [] },
  policies: { results: [], isLoading: false },
  bbkActionRoles: [],
  existingEmailTriggers: [],
  gridHeaders: [],
  gridRows: [],
  initialValues: [],
  selectedModule: '',
  selectedPolicies: [...defaultSelectedPolicies],
  selectedBBKActionRoles: [],
  updatedTriggers: {},
  updatedUsers: {},
  initialValuesUpdateCounter: 0,
  showActionRolesDropdown: false,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setSelectedModule, (state, action) => {
      state.selectedModule = action.payload
    })
    .addCase(setSelectedPolicies, (state, action) => {
      state.selectedPolicies = action.payload
    })
    .addCase(setShowActionRolesDropdown, (state, action) => {
      state.showActionRolesDropdown = action.payload
    })
    .addCase(setSelectedBBKActionRoles, (state, action) => {
      state.selectedBBKActionRoles = action.payload
    })
    .addCase(setUpdatedTriggers, (state, action) => {
      state.updatedTriggers = action.payload
    })
    .addCase(setUpdatedUsers, (state, action) => {
      state.updatedUsers = action.payload
    })
    .addCase(setInitialValuesUpdateCounter, (state, action) => {
      state.initialValuesUpdateCounter = action.payload
    })
    .addCase(setShowAssignmentForm, (state, action) => {
      state.showAssignmentForm = action.payload
    })
    .addCase(setAssignmentsPerPolicyInformation, (state, action) => {
      state.assignmentsPerPolicyInformation = action.payload
    })
    .addCase(setAssignmentsPerPolicyInitialValues, (state, action) => {
      state.assignmentsPerPolicyInitialValues = action.payload
    })
    .addCase(setModuleAssignmentsPerPolicy, (state, action) => {
      state.modulesAssignmentsPerPolicy = action.payload
    })
    .addCase(setModulesForTriggers, (state, action) => {
      state.modulesForTriggers = { ...state.modulesForTriggers, ...action.payload }
    })
    .addCase(setPolicies, (state, action) => {
      state.policies = { ...state.policies, ...action.payload }
    })
    .addCase(setBBKActionRoles, (state, action) => {
      state.bbkActionRoles = { ...state.bbkActionRoles, ...action.payload }
    })
    .addCase(setExistingEmailTriggers, (state, action) => {
      state.existingEmailTriggers = action.payload
    })
    .addCase(setGridHeaders, (state, action) => {
      state.gridHeaders = action.payload
    })
    .addCase(setGridRows, (state, action) => {
      state.gridRows = action.payload
    })
    .addCase(setInitialValues, (state, action) => {
      state.initialValues = action.payload
    })
    .addCase(resetSmartAlert, state => {
      copyObject(state, initialState)
    })
})
