import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Typography, Grid } from '@mui/material'
import { PageHeader, Button, AccordionGroup, PartitionCard, Bar, PropertyValue } from 'components/common'
import QuickView from 'components/common/QuickView'
import SiteImage from 'assets/images/site-management/SiteImage.png'
import { useRestriction } from 'permissions'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import AddEditECDetails from './AddEditECDetails'
import Documents from './documents/Documents'
import SiteIdAssociations from './siteIdAssociations/SiteIdAssociations'
import { editEthicCommitteeDetails, fetchEthicCommitteeDetails, fetchEthicsCommittee, resetECDetails, setAddEditECFlow, setECTablePageNumber } from 'store/ducks/siteManagement/ecDirectory/ecManagement'
import { fetchCountriesLookup, fetchEthicsType } from 'store/ducks/common'

const EmptyFooter = styled.div`
  padding: 25px;
`

const SiteCardLogoWrapper = styled.div(() => ({
  '&&': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      width: '60%'
    }
  }
}))

const PrevNextSiteButtons = styled.div(({ theme }) => ({
  '&&': {
    button: {
      marginLeft: theme.spacing(2)
    }
  }
}))

const ECDirectoryDetails = () => {
  const { encrypttedEthicID, instanceID } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [accordionExpanded, setAccordionExpanded] = useState(null)

  const { ecDetails, addEditECFlow, ethicsCommitteeLookup } = useSelector(store => store.ecManagement)

  const selectedECIndex =
    ethicsCommitteeLookup.results.length && ethicsCommitteeLookup.results.findIndex(ec => ec.ethicsId === ecDetails.ethicsCommiteeID)
  useEffect(() => {
    dispatch(fetchEthicCommitteeDetails(encrypttedEthicID))

    if (ethicsCommitteeLookup.results.length === 0) {
      // EC list is required to make the next and previous button work
      dispatch(fetchEthicsCommittee())
    }

    // Cleanup
    return () => {
      dispatch(resetECDetails())
    }
  }, [encrypttedEthicID])

  const goBackHandler = async e => {
    e.preventDefault()
    await dispatch(setECTablePageNumber(0))
    navigate(`/instance/${instanceID}/ec-directory`)
  }

  const fetchFormDependencies = async () => {
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchEthicsType())
  }

  const ecDetailsAccordionItems = [
    {
      label: 'Site Associations',
      component: <SiteIdAssociations />,
      id: 'siteAddresses'
    },
    {
      label: 'Documents',
      component: <Documents />,
      id: 'userActivity'
    }
  ]

  const updateAccordionList = ecDetailsAccordionItems.filter(
    accordion => useRestriction('read', accordion.id) && accordion
  )

  const closeECFlow = async () => {
    await dispatch(setAddEditECFlow(false))
  }

  return (
    <>
      <PageHeader
        title={
          <Typography variant="h3">
            <b>{`Ethics Committee | ${ecDetails?.ethicCommiteeName} |`}</b>{' '}
            {`${ecDetails?.ethicsCommiteeType}`}
          </Typography>
        }
        actionContent={
          <PrevNextSiteButtons>
            <Button
              disabled={selectedECIndex === 0}
              startIcon={<ArrowBackIos />}
              onClick={() => {
                navigate(
                  `/instance/${instanceID}/ec-details/${ethicsCommitteeLookup.results[selectedECIndex - 1].encrypttedEthicID
                  }`
                )
              }}
            >
              Previous
            </Button>
            <Button
              disabled={selectedECIndex === ethicsCommitteeLookup.results.length - 1}
              startIcon={<ArrowForwardIos />}
              onClick={() => {
                navigate(
                  `/instance/${instanceID}/ec-details/${ethicsCommitteeLookup.results[selectedECIndex + 1].encrypttedEthicID
                  }`
                )
              }}
            >
              Next
            </Button>
            <Button color="inherit" size="medium" onClick={goBackHandler}>
              Back
            </Button>
          </PrevNextSiteButtons>
        }
        content={<div></div>}
        expandContent={false}
      />
      <PartitionCard
        cardProps={{ cardContentStyles: { padding: '0px', margin: '0px' } }}
        firstContent={
          <SiteCardLogoWrapper>
            <img alt="Site" src={SiteImage} />
          </SiteCardLogoWrapper>
        }
        firstContentWidth="20%"
        secondContent={
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography gutterBottom variant="h2">
                <b>{ecDetails?.ethicCommiteeName ? ecDetails.ethicCommiteeName : ''}</b>
              </Typography>
              <Bar fullWidth color="midnight" />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={3} pb={2} pt={2}>
              <Grid container pr={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={1}>
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Ethics Committee Name:"
                    value={ecDetails?.ethicCommiteeName ? ecDetails.ethicCommiteeName : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Type:"
                    value={ecDetails?.ethicsCommiteeType ? ecDetails.ethicsCommiteeType : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="State / Province:"
                    value={ecDetails?.state ? ecDetails.state : ''}
                    align="row"
                  />
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    label="Country:"
                    value={ecDetails?.country ? ecDetails.country : ''}
                    align="row"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} pt={3}>
                  <Button
                    size="large"
                    onClick={async () => {
                      await fetchFormDependencies()
                      await dispatch(editEthicCommitteeDetails(ecDetails, encrypttedEthicID))
                      await dispatch(setAddEditECFlow(true))
                      // await dispatch(setAddEditECFlow(true), () => dispatch(editEthicCommitteeDetails(ecDetails, encrypttedEthicID)))
                    }
                    }
                    color="inherit"
                  >
                    Edit EC Information
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        firstContentBackground="gradientBlue"
        secondContentWidth="80%"
        isFirstContentBackgroundGradient={true}
      />

      <AccordionGroup
        accordions={updateAccordionList}
        wrapperStyles={{ marginTop: '28px' }}
        gradient={false}
        color="primary"
        transitionProps={{ unmountOnExit: true }}
        defaultOpenPanel={-1}
        onAccordionExpand={accordionIndex => {
          setAccordionExpanded(accordionIndex)
        }}
      />
      <QuickView
        title="Edit EC Information"
        onClose={() => closeECFlow()}
        dialogContent={<AddEditECDetails
          closeForm={() => closeECFlow()}
          refreshSiteDetails={false}
          accordionExpanded={accordionExpanded}
        />}
        isDialogOpen={addEditECFlow}
        maxWidth="md"
        dialogActions={<EmptyFooter />}
        closeConfirmation={true}
      />
    </>
  )
}
export default ECDirectoryDetails
