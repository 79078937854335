import { createAction, createReducer } from '@reduxjs/toolkit'
import { copyObject, sortList } from 'components/helper/utility'
import {
  forgotPassword,
  getEmailByResetToken,
  getListOfInstances,
  getListOfArchivedInstances,
  getSecurityQuestionsLookup,
  getSecurityQuestionsStatus,
  lastLogin,
  login,
  postLogout,
  postRefreshToken,
  resetSecurityQnA,
  submitNewAnswerToQues,
  submitNewPassword,
  validateStoredAnswer
} from 'services/auth'
import { resetPatientExperienceManager } from './patientExperienceManager'
import { resetUserInteractions, setLoading } from './userInteractions'
import { resetStudyMessage } from './communicationCenter/studyMessages'
import { showError, resetApplication, setSiteClientId, getPolicyPermissions } from './application'
import {
  fetchInstanceConfigDetails,
  resetInstanceConfiguration
} from 'store/ducks/configuration/instanceConfiguration'
import { resetPatientMainWrapper } from './patientRecord/patientMainWrapper'
import { resetAnnouncements } from './communicationCenter/announcements'
import { resetPatientProfile } from './patientRecord/patientProfile'
import { resetReimbursement } from './patientRecord/reimbursement'
import { resetManageAccount } from './patientRecord/manageAccount'
import { resetParticipationProgress } from './patientRecord/participationProgress'
import { resetAdvancedSupport } from './visitServices/advancedSupportRequest'
import { resetAuxillarySupport } from './visitServices/auxillarySupportRequest'
import { resetDeliveryServices } from './visitServices/deliveryServices'
import { resetHomeHealthVisit } from './visitServices/homeHealthVisit'
import { resetInPersonVisit } from './visitServices/inPersonRequest'
import { resetOtherService } from './visitServices/otherServiceRequest'
import { resetRequestDetails } from './visitServices/requestDetails'
import { resetTeleHealthSupport } from './visitServices/telehealthVisit'
import { resetCommon } from './common'
import { resetResourceCenter } from './resourceCenter'
import { resetSiteManagement } from './siteManagement/siteManagement'
import { resetSitePreferences } from './configuration/sitePreferencesConfiguration'
import { resetMyAccount } from './myAccount'
import { resetCardConfiguration } from './configuration/cardConfiguration'
import { resetProtocolCampaignConfiguration } from './configuration/protocolCampaignConfiguration'
import { resetArriveConfiguration } from './configuration/arriveConfiguration'
import { backendAxios } from 'services/backend'
import generalPrivacyPolicy from 'assets/business/generalPrivacyPolicy.pdf'
import fileDownload from 'js-file-download'
import { resetPermissionsConfiguration } from './configuration/permissionsConfiguration'
import { fetchHelperAttachmentModules } from 'store/ducks/common'
import { resetVisitDetailsConfiguration } from './configuration/visitDetailsConfiguration'
import { resetParticipationProgressConfiguration } from './configuration/participationProgressConfiguration'
import { resetResourceCenterConfiguration } from './configuration/resourceCenterConfiguration'
import { loginFailureReasons } from 'components/helper/constants/auth'
import documentRoutes from 'routes/documentRoutes'
import { resetMediaReferralsConfiguration } from './configuration/mediaReferralsConfiguration'
import { resetMediaPlans } from './mediaPlans'
import { resetECManagement } from './siteManagement/ecDirectory/ecManagement'

export const setAuth = createAction('auth/setAuth')
export const resetAuth = createAction('auth/resetAuth')
export const setAuthRedirectPath = createAction('auth/setAuthRedirectPath')
export const setPathname = createAction('auth/setPathname')
export const setPasswordUpdateSuccess = createAction('auth/setPasswordUpdateSuccess')
export const setSelectedInstance = createAction('auth/setSelectedInstance')
export const setSecurityQuestionsLookup = createAction('auth/setSecurityQuestionsLookup')
export const setSecurityStatus = createAction('auth/setSecurityStatus')
export const setAssociatedInstances = createAction('auth/setAssociatedInstances')
export const setArchivedInstances = createAction('auth/setArchivedInstances')
export const setChangePasswordForm = createAction('auth/setChangePasswordForm')
export const setLoggedInAsSite = createAction('auth/setLoggedInAsSite')
export const setConstantStudyList = createAction('auth/setConstantStudyList')

let refreshTimer = null

export const performAuthentication = (email, password) => async (dispatch, getState) => {
  dispatch(setLoading(true))

  const authData = {
    email,
    password
  }

  try {
    const { data } = await login(authData)
    const { isPatient, username, expirationTime, accessToken, refreshToken } = data
    // expiry in seconds
    const jwtExpiry = expirationTime
    const jwtExpirationTime = new Date(new Date().getTime() + jwtExpiry * 1000)

    localStorage.setItem('username', username)
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('jwtExpirationTime', jwtExpirationTime)
    localStorage.setItem('jwtExpiry', expirationTime)
    localStorage.setItem('isPatient', isPatient)

    dispatch(
      setAuth({
        ...data,
        isAuthenticated: true
      })
    )

    // Set timer to refresh the jwtToken.
    dispatch(setJwtTokenTimeOut(jwtExpiry))

    await dispatch(fetchListOfInstances())
    const { associatedInstances } = getState().auth

    dispatch(routeToRightLocation(isPatient, associatedInstances))
  } catch (e) {
    if (e && e.response) {
      dispatch(showError(loginFailureReasons(e.response.data)))
    } else {
      dispatch(
        showError('Authentication failed, please check your credentials or try again later.', e)
      )
      dispatch(performLogout())
    }
  }
  dispatch(setLoading(false))
}

export const submitLastLogin = () => async (dispatch, getState) => {
  try {
    const { isPatient } = getState().auth
    if (!isPatient) {
      await lastLogin()
    }
  } catch (e) {
    dispatch(showError('There was some network error. Please refresh your page.', e))
  }
}

const searchInstances = (searchTerm, instances) => {
  const searchFields = ['projectName', 'instanceName']

  const results = instances.filter(instance => {
    for (const field of searchFields) {
      if (instance[field] && instance[field].toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }
    }
    return false
  })

  return results
}

export const fetchListArchivedOfInstances = filterParams => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { constantStudyList } = getState().auth
    if (filterParams && filterParams.search) {
      const filteredList = searchInstances(filterParams.search, constantStudyList)
      dispatch(setArchivedInstances(filteredList))
    } else {
      const { data } = await getListOfArchivedInstances()
      const sortedData = sortList(data, 'ASC', 'projectName')
      dispatch(setArchivedInstances(sortedData))
    }
  } catch (e) {
    if (filterParams) {
      dispatch(showError('Error when trying to filter the archived list', e))
    } else {
      dispatch(showError('Error when trying to get the list of archived instances', e))
      dispatch(setArchivedInstances([]))
    }
  }
  dispatch(setLoading(false))
}

export const fetchListOfInstances = filterParams => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { constantStudyList } = getState().auth
    if (filterParams && filterParams.search) {
      const filteredList = searchInstances(filterParams.search, constantStudyList)
      dispatch(setAssociatedInstances(filteredList))
    } else {
      const { data } = await getListOfInstances()
      const sortedData = sortList(data, 'ASC', 'projectName')
      dispatch(setAssociatedInstances(sortedData))
      dispatch(setConstantStudyList(data))
    }
  } catch (e) {
    if (filterParams) {
      dispatch(showError('Error! when trying to filter the list', e))
    } else {
      dispatch(showError('Error! when trying to get the list of instances', e))
      dispatch(setAssociatedInstances([]))
    }
  }
  dispatch(setLoading(false))
}

const routeToRightLocation =
  (isPatient, associatedInstances, performSecurityQnACheck = true) =>
    async dispatch => {
      dispatch(setLoading(true))
      try {
        const singleInstance = associatedInstances.length === 1
        if ((isPatient && singleInstance) || singleInstance) {
          const selectedInstance = associatedInstances[0]
          await dispatch(setupSelectedInstance(selectedInstance))
          localStorage.setItem('selectedInstance', JSON.stringify(selectedInstance))

          if (performSecurityQnACheck) {
            await dispatch(checkSecurityStatus())
          } else {
            dispatch(setPathname(`/instance/${selectedInstance.instanceId}/dashboard`))
          }
        } else {
          clearInstanceInterceptor()
          dispatch(setPathname('/portal'))
        }
      } catch {
        dispatch(showError('There was some error. Please try again.'))
        dispatch(performLogout())
        dispatch(setLoading(false))
      } finally {
        dispatch(setLoading(false))
      }
    }

export const clearInstanceInterceptor = async () => {
  const instanceInterceptor = localStorage.getItem('instanceInterceptor')
  await backendAxios.interceptors.request.eject(instanceInterceptor)
}

export const checkSecurityStatus = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getSecurityQuestionsStatus()
    /**
     * Sample Response
     * data: {
     * isResetRequested: false,
     * securityQuestion: 'What is your fav city?'
     * }
     */
    dispatch(setSecurityStatus(data))

    // hold this information, that will be used if user refreshes the page
    localStorage.setItem('securityStatus', JSON.stringify(data))
    if (data.isResetRequested) {
      dispatch(setPathname('/auth/setup-sec-ques'))
    } else {
      dispatch(setPathname('/auth/validate-sec-ques'))
    }
  } catch {
    dispatch(performLogout())
    dispatch(
      showError(
        'There was an issue while trying to check status of the logged in user. Please try again.'
      )
    )
  }
  dispatch(setLoading(false))
}

export const fetchSecurityQuestionsLookup = () => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setSecurityQuestionsLookup({ isLoading: true }))
  try {
    const { data } = await getSecurityQuestionsLookup()
    dispatch(setSecurityQuestionsLookup({ results: data, isLoading: false }))
  } catch {
    dispatch(setSecurityQuestionsLookup({ results: [], isLoading: false }))
    dispatch(showError('There was an issue while trying to fetch questions. Please try again.'))
  }
  dispatch(setLoading(false))
}

export const createAnswerToQuestion = values => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { selectedInstance, isPatient } = getState().auth
  const { answer, securityQuestion } = values

  const payload = {
    securityQuestionId: securityQuestion.id,
    answer
  }

  try {
    const { data } = await submitNewAnswerToQues(payload)
    // const data = true
    if (data) {
      // as user has newly setup the security question
      localStorage.setItem('securityAnswerVerified', true)
      await dispatch(submitLastLogin(selectedInstance.instanceId))
      await dispatch(getPolicyPermissions())
      await dispatch(fetchInstanceConfigDetails())
      dispatch(routeToRightLocation(isPatient, selectedInstance && [selectedInstance], false))
    }
  } catch {
    dispatch(showError('There was an issue while setting up the answer. Please try again.'))
  }
  dispatch(setLoading(false))
}

export const validateExistingAnswer = values => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { selectedInstance, isPatient } = getState().auth
  const { answer } = values

  const payload = {
    answer
  }

  try {
    const { data } = await validateStoredAnswer(payload)
    // const data = true
    if (data) {
      localStorage.setItem('securityAnswerVerified', true)
      await dispatch(submitLastLogin())
      await dispatch(getPolicyPermissions())
      await dispatch(fetchInstanceConfigDetails())
      // needed to fetch Helper attachments in arrive, tcn card
      await dispatch(fetchHelperAttachmentModules())
      dispatch(routeToRightLocation(isPatient, selectedInstance && [selectedInstance], false))
    } else {
      dispatch(showError('Verification failed. Please try again.'))
    }
  } catch {
    dispatch(showError('Verification failed. Please try again.'))
    dispatch(performLogout())
  }
  dispatch(setLoading(false))
}

export const clearApplicationMemory = () => async dispatch => {
  try {
    /**
     * clear Instance interceptor
     */
    await clearInstanceInterceptor()
    localStorage.clear()
    if (refreshTimer) {
      clearTimeout(refreshTimer)
    }

    /**
     * Auth
     */
    dispatch(resetAuth())
    /**
     * Communication center
     */
    dispatch(resetStudyMessage())
    dispatch(resetAnnouncements())
    /**
     * patient section
     */
    dispatch(resetManageAccount())
    dispatch(resetParticipationProgress())
    dispatch(resetPatientMainWrapper())
    dispatch(resetPatientProfile())
    dispatch(resetReimbursement())
    /**
     * Visit services
     */
    dispatch(resetAdvancedSupport())
    dispatch(resetAuxillarySupport())
    dispatch(resetDeliveryServices())
    dispatch(resetHomeHealthVisit())
    dispatch(resetInPersonVisit())
    dispatch(resetOtherService())
    dispatch(resetRequestDetails())
    dispatch(resetTeleHealthSupport())
    /**
     * Application
     */
    dispatch(resetApplication())
    dispatch(resetCommon())
    dispatch(resetUserInteractions())
    /**
     * Patient Experience Manager
     */
    dispatch(resetPatientExperienceManager())
    /**
     * Resource center
     */
    dispatch(resetResourceCenter())
    /**
     * Site management
     */
    dispatch(resetSiteManagement())
    dispatch(resetSitePreferences())
    /**
     * myAccount
     */
    dispatch(resetMyAccount())
    /**
     * Media Plans
     */
    dispatch(resetMediaPlans())
    /**
     * Configuration
     */
    dispatch(resetCardConfiguration())
    dispatch(resetProtocolCampaignConfiguration())
    dispatch(resetResourceCenterConfiguration())
    dispatch(resetArriveConfiguration())
    dispatch(resetPermissionsConfiguration())
    dispatch(resetInstanceConfiguration())
    dispatch(resetVisitDetailsConfiguration())
    dispatch(resetParticipationProgressConfiguration())
    dispatch(resetMediaReferralsConfiguration())
    /**
     * Ethics Committee Management
     */
    dispatch(resetECManagement())
  } catch {
    dispatch(showError('Error! when trying to logout! Please try again.'))
  }
}

export const performLogout =
  (onLogoutSuccess, makeLogoutCall = true) =>
    async (dispatch, getState) => {
      try {
        const accessToken = localStorage.getItem('accessToken')
        const refreshToken = localStorage.getItem('refreshToken')
        const { selectedInstance } = getState().auth
        // from public pages(security questions setup, password setup page), makeLogoutCall will be false\
        if (makeLogoutCall) {
        // as instance id is required in the POST call - URL
          if (selectedInstance?.instanceId) {
            if (accessToken && refreshToken) {
              await postLogout()
            }
          }
        }
        dispatch(clearApplicationMemory())
        if (onLogoutSuccess) {
          onLogoutSuccess()
        }
      } catch (e) {
      // upon session expiry user is not able to logout, therefore just clear the redux/local storage
        if (e.response && e.response.status === 401) {
          dispatch(clearApplicationMemory())
        } else {
          dispatch(showError('Error! when trying to logout! Please try again.'))
        }
      }
    }

export const authCheckState = pathname => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    // on document layout do nothing
    const isDocumentLayout = documentRoutes.map(route => `/doc/${route.to}`).includes(pathname)

    if (!isDocumentLayout) {
      if (refreshToken && accessToken) {
        const {
          username,
          jwtExpirationTime,
          jwtExpiry,
          isPatient,
          siteClientId,
          securityStatus,
          securityAnswerVerified,
          selectedInstance
        } = getStoredValues()

        dispatch(setSiteClientId(siteClientId))
        dispatch(setSecurityStatus(securityStatus))

        dispatch(
          setAuth({
            username,
            accessToken,
            refreshToken,
            jwtExpirationTime,
            jwtExpiry,
            isPatient,
            isAuthenticated: true
          })
        )

        await dispatch(fetchListOfInstances())

        const { associatedInstances } = getState().auth

        if (pathname === '/login' || pathname === '/' || pathname === '/portal') {
          await dispatch(
            routeToRightLocation(isPatient, associatedInstances, !securityAnswerVerified)
          )
        } else {
          await dispatch(setupSelectedInstance(selectedInstance))

          if (!securityAnswerVerified) {
            dispatch(checkSecurityStatus())
          } else {
            await dispatch(getPolicyPermissions())
            await dispatch(fetchInstanceConfigDetails())
            // needed to fetch Helper attachments in arrive, tcn card
            await dispatch(fetchHelperAttachmentModules())
            dispatch(setPathname(pathname))
          }
        }
      } else {
        dispatch(performLogout())
      }
    }
  } catch (e) {
    dispatch(showError('Authentication Check Error!', e))
    dispatch(performLogout())
  } finally {
    dispatch(setLoading(false))
  }
}

const getStoredValues = () => ({
  username: localStorage.getItem('username'),
  jwtExpirationTime: localStorage.getItem('jwtExpirationTime'),
  jwtExpiry: localStorage.getItem('jwtExpiry'),
  isPatient: JSON.parse(localStorage.getItem('isPatient')),
  siteClientId: localStorage.getItem('siteClientId'),
  securityStatus: JSON.parse(localStorage.getItem('securityStatus')),
  securityAnswerVerified: JSON.parse(localStorage.getItem('securityAnswerVerified')),
  selectedInstance: JSON.parse(localStorage.getItem('selectedInstance'))
})

export const forgotSecurityQnA = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await resetSecurityQnA()
    if (data) {
      dispatch(setPathname('/auth/reset-success/security-qna'))
    } else {
      dispatch(showError('There was an error. Please try again.'))
    }
  } catch {
    dispatch(showError('There was an error. Please try again.'))
  }
  dispatch(setLoading(false))
}

export const setJwtTokenTimeOut = jwtExpiry => async dispatch => {
  refreshTimer = setTimeout(async () => {
    const refreshToken = localStorage.getItem('refreshToken')

    if (refreshToken) {
      dispatch(fetchNewRefreshToken())
    } else {
      dispatch(performLogout())
    }
  }, (jwtExpiry - 2 * 60) * 1000) // JWTExpiry time is in seconds // 2 mins before expiry
}

export const fetchNewRefreshToken = () => async (dispatch, getState) => {
  const refreshToken = localStorage.getItem('refreshToken')
  const accessToken = localStorage.getItem('accessToken')

  if (refreshToken && accessToken) {
    const refreshData = {
      refreshToken
    }

    try {
      const { data } = await postRefreshToken(refreshData)
      const { expirationTime, refreshToken, accessToken, username, isPatient } = data

      // expiry in seconds
      const jwtExpirationTime = new Date(new Date().getTime() + expirationTime * 1000)

      localStorage.setItem('userName', username)
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('jwtExpirationTime', jwtExpirationTime)
      localStorage.setItem('jwtExpiry', expirationTime)
      localStorage.setItem('isPatient', isPatient)

      dispatch(
        setAuth({
          username,
          accessToken,
          isPatient,
          isAuthenticated: true
        })
      )

      const { associatedInstances } = getState().auth

      if (associatedInstances.length === 0) {
        dispatch(fetchListOfInstances())
      }

      // Set timer to refresh the jwtToken.
      dispatch(setJwtTokenTimeOut(expirationTime))
    } catch {
      dispatch(performLogout())
    }
  } else {
    dispatch(performLogout())
  }
}

export const onSubmitForgotPassword = values => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { email, captchaToken } = values
    await forgotPassword(email, captchaToken)
    dispatch(setPathname('/reset-success/password'))
  } catch (e) {
    dispatch(showError('Something went wrong. Please try again.', e))
  }
  dispatch(setLoading(false))
}

export const fetchEmailByResetToken = resetToken => async (dispatch, getState) => {
  try {
    dispatch(setChangePasswordForm({ isLoading: true }))
    const { changePasswordForm } = getState().auth
    const { data } = await getEmailByResetToken(resetToken)
    if (data.isValidToken) {
      dispatch(
        setChangePasswordForm({
          ...changePasswordForm,
          email: data.emailAddress,
          isPatient: data.isPatient
        })
      )
    } else {
      dispatch(setPathname('/404'))
      dispatch(showError('There was some error while trying to submit reset password information.'))
    }
  } catch {
    dispatch(setPathname('/404'))
    dispatch(showError('There was some error while trying to submit reset password information.'))
  }
  dispatch(setChangePasswordForm({ isLoading: false }))
}

export const onSubmitNewPassword = values => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { email, confirmPassword, password } = values
    const { changePasswordForm } = getState().auth
    await submitNewPassword({
      email,
      newPassword: password,
      confirmPassword,
      isPatient: changePasswordForm.isPatient
    })
    dispatch(setPasswordUpdateSuccess(true))
  } catch (e) {
    dispatch(showError('Something went wrong. Please try again.', e))
  }
  dispatch(setLoading(false))
}

export const setupSelectedInstance = instance => async dispatch => {
  try {
    clearInstanceInterceptor()

    // Define the interceptor
    const newInstanceInterceptor = await backendAxios.interceptors.request.use(config => {
      // Modify the URL to include the instanceId
      config.url = `/${instance.instanceId}${config.url}`

      return config
    })
    localStorage.setItem('instanceInterceptor', newInstanceInterceptor)

    dispatch(setSelectedInstance(instance))
  } catch {
    dispatch(showError('Something went wrong when trying to select instance.'))
  }
}

export const onDownloadPPPdf = () => async dispatch => {
  try {
    const response = await fetch(generalPrivacyPolicy)
    const blob = await response.blob()
    fileDownload(blob, 'General Privacy Policy.pdf')
  } catch {
    dispatch(
      showError('There was some error while trying to fetch document. Please try again later.')
    )
  }
}

const initialState = {
  accessToken: null,
  username: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  associatedInstances: [],
  constantStudyList: [],
  selectedInstance: '',
  isAuthenticated: false,
  pathname: '/',
  passwordUpdateSuccess: false,
  isPatient: false,
  securityQuestionsLookup: { results: [], isLoading: false },
  securityStatus: { securityQuestion: '' },
  changePasswordForm: {
    email: '',
    password: '',
    confirmPassword: '',
    isLoading: false
  },
  loggedInAsSite: false,
  archivedInstances: []
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setAuth, (state, { payload }) => {
      state.accessToken = payload.accessToken
      state.username = payload.username
      state.error = null
      state.loading = false
      state.isAuthenticated = payload.isAuthenticated
      state.isPatient = payload.isPatient
    })
    .addCase(setLoggedInAsSite, (state, action) => {
      state.loggedInAsSite = action.payload
    })
    .addCase(setConstantStudyList, (state, action) => {
      state.constantStudyList = action.payload
    })
    .addCase(resetAuth, state => {
      copyObject(state, initialState)
    })
    .addCase(setAssociatedInstances, (state, action) => {
      state.associatedInstances = action.payload
    })
    .addCase(setArchivedInstances, (state, action) => {
      state.archivedInstances = action.payload
    })
    .addCase(setSelectedInstance, (state, action) => {
      state.selectedInstance = action.payload
    })
    .addCase(setAuthRedirectPath, (state, action) => {
      state.authRedirectPath = action.payload
    })
    .addCase(setPathname, (state, action) => {
      state.pathname = action.payload
    })
    .addCase(setPasswordUpdateSuccess, (state, action) => {
      state.passwordUpdateSuccess = action.payload
    })
    .addCase(setSecurityQuestionsLookup, (state, { payload }) => {
      state.securityQuestionsLookup = { ...state.securityQuestionsLookup, ...payload }
    })
    .addCase(setSecurityStatus, (state, action) => {
      state.securityStatus = action.payload
    })
    .addCase(setChangePasswordForm, (state, action) => {
      state.changePasswordForm = { ...state.changePasswordForm, ...action.payload }
    })
})
