import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography, IconButton, Grid } from '@mui/material'
import { Add, FilterAlt, QuestionAnswerOutlined } from '@mui/icons-material'
import styled from '@emotion/styled'
import PatientGridFilters from './PatientGridFilters'
import { palette, combinedBaseTheme } from 'styles/baseTheme'
import {
  fetchListOfPatients,
  setShowAddressVerificationPopup,
  setPatientTablePageNumber,
  handleRequestSort,
  setRowsPerPage,
  setIsAddressVerificationRequired,
  setExpandedFilters,
  setSitePrefSuccessModal,
  setSitePrefVerificationFlow,
  setAddPatientFlow,
  setAddedPatientId,
  setAddressVerificationSuccessModal,
  setAlignment,
  initialState,
  setFilters
} from 'store/ducks/patientExperienceManager'
import { fetchFiltersLookup } from 'store/ducks/application'
import { displayDate } from 'components/helper/utility'
import { PageHeader, DataGrid, Pagination, Button, Badge } from 'components/common'
import QuickView from 'components/common/QuickView'
import AddPatientSuccess from 'components/private/patientExperience/AddPatientSuccess'
import SiteAddressConfirmation from './SiteAddressConfirmation'
import SiteTravelPreferences from './SiteTravelPreferences'
import PatientExperienceInfoSuccess from './PatientExperienceInfoSuccess'
import AddressVerificationPopup from './AddressVerificationPopup'
import rsgCard from 'assets/images/rsg-card.png'
import tcnArrive from 'assets/images/tcn-arrive.png'
import patientProfileIcon from 'assets/images/patient-profile.png'
import AddPatient from './AddPatient'
import { useRestriction, Restriction } from 'permissions'
import { patientTabIDs } from 'components/helper/constants/common'
import { fetchUnansweredSitePref } from 'store/ducks/configuration/sitePreferencesConfiguration'

const patientsTableHeaders = [
  { id: 'manage', label: ' ' },
  { id: 'recordCreatedDate', label: 'Created Date' },
  { id: 'tcnId', label: 'TCN #' },
  { id: 'userInitials', label: 'Initials' },
  { id: 'source', label: 'Referral Source' },
  { id: 'participationProgress', label: 'Participation Progress' },
  { id: 'subjectId', label: 'Subject ID' },
  { id: 'sponsorSiteId', label: 'Site ID' },
  { id: 'siteCountry', label: 'Country of Site' }
]

const ActionCellWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`

const ActionIcon = styled.img`
  width: 1rem;
`

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&&': {
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: '30%',
    padding: '6px 7px'
  }
}))

const ToggleButton = styled(Button)({
  '&&': {
    marginLeft: '16px',
    marginTop: '16px'
  }
})

const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isEditMode } = useSelector(store => store.userInteractions)

  const {
    isAddressVerificationRequired,
    addressVerificationSuccessModal,
    showAddressVerificationPopup,
    sitePrefVerificationFlow,
    sitePrefSuccessModal,
    patientsList,
    patientTablePageNumber,
    rowsPerPage,
    order,
    orderBy,
    expandedFilters,
    addPatientFlow,
    addedPatientId,
    alignment
  } = useSelector(store => store.patientExperienceManager)

  const { selectedInstance } = useSelector(store => store.auth)
  const { instanceId } = selectedInstance

  const { participationProgressLookup } = useSelector(store => store.application)

  useEffect(() => {
    dispatch(fetchFiltersLookup())
  }, [])

  useEffect(() => {
    fetchPatients()
  }, [participationProgressLookup])

  const fetchPatients = async () => {
    const preSelectedParticipationProgressLookup = participationProgressLookup.filter(({ displayText }) => !['Disqualified', 'Unable to Contact', 'Not Interested'].includes(displayText))
    await dispatch(setFilters({ ...initialState.filters, participationProgresses: preSelectedParticipationProgressLookup }))
    await dispatch(fetchListOfPatients())
  }

  // check permission of each column
  let updatedHeaders = patientsTableHeaders.filter(
    item => useRestriction('read', `${item.id}PatientListColumn`) && item
  )

  // Toggle button implementation
  const toggleButton = async (event, newAlignment) => {
    event.preventDefault()
    if (newAlignment !== null) {
      if (newAlignment === 'active') {
        const preSelectedParticipationProgressLookup = participationProgressLookup.filter(({ displayText }) => !['Disqualified', 'Unable to Contact', 'Not Interested'].includes(displayText))
        dispatch(setFilters({ ...initialState.filters, participationProgresses: preSelectedParticipationProgressLookup }))
      } else {
        dispatch(setFilters({ ...initialState.filters, participationProgresses: [] }))
      }
      dispatch(setAlignment(newAlignment))
      await dispatch(fetchListOfPatients())
      dispatch(setPatientTablePageNumber(0))
    }
  }

  /* -----DataGrid related actions------ */
  updatedHeaders = updatedHeaders.map((header, index) => {
    const headerProperties = {
      backgroundColor: palette.secondary.main,
      color: palette.primary.main,
      borderBottom: `1px solid ${palette.gray.main}`,
      borderTop: `1px solid ${palette.gray.main}`,
      borderLeft: '0px',
      borderRight: '0px',
      cellType: 'node',
      padding: '24px 8px',
      textAlign: 'center'
    }
    const bodyProperties = {
      backgroundColor: palette.secondary.main,
      borderLeft: '0px',
      borderBottom: `1px solid ${palette.gray.main}`,
      borderRight: `1px solid ${palette.gray.main}`,
      padding: '14px 0px'
    }

    if (header.id === 'manage') {
      return {
        ...header,
        headProperties: {
          ...headerProperties,
          borderRight: `4px solid ${palette.gray.main}`,
          borderLeft: `1px solid ${palette.gray.main}`,
          borderTopLeftRadius: `${combinedBaseTheme.shape.mediumRadius}`,
          minWidth: '120px'
        },
        bodyProperties: {
          ...bodyProperties,
          cellType: 'node',
          borderRight: `4px solid ${palette.gray.main}`,
          borderLeft: `1px solid ${palette.gray.main}`
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else if (header.id === 'participationProgress') {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties
        },
        bodyProperties: {
          ...bodyProperties,
          backgroundColor: palette.gray.light
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else if (header.id === 'subjectId') {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties,
          borderRight: updatedHeaders.length - 1 === index && `1px solid ${palette.gray.main}`,
          borderTopRightRadius:
            updatedHeaders.length - 1 === index && `${combinedBaseTheme.shape.mediumRadius}`
        },
        bodyProperties: {
          ...bodyProperties,
          backgroundColor: palette.gray.light
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else if (header.id === 'siteCountry') {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties,
          borderRight: `1px solid ${palette.gray.main}`,
          borderTopRightRadius: `${combinedBaseTheme.shape.mediumRadius}`
        },
        bodyProperties: {
          ...bodyProperties,
          borderRight: `1px solid ${palette.gray.main}`
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties
        },
        bodyProperties: {
          ...bodyProperties
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    }
  })

  let updatedPatientListData = []

  const filterModuleBasedBadge = (badges, currModule) => {
    if (!badges) return []
    const filteredBadges = badges.filter(({ module }) => module === currModule)
    return filteredBadges
  }

  updatedPatientListData = patientsList.results.map(item => ({
    ...item,
    recordCreatedDate: displayDate(item.recordCreatedDate),
    manage: (
      <ActionCellWrapper>
        <Restriction ability="read" feature="patientDashboard">
          <Badge
            invisible={filterModuleBasedBadge(item.badges, 'Participation Progress').length === 0}
            tooltipItems={filterModuleBasedBadge(item.badges, 'Participation Progress')}
            badgeCount={filterModuleBasedBadge(item.badges, 'Participation Progress')[0]?.count}
          >
            <StyledIconButton
              aria-label="actions"
              aria-haspopup="true"
              onClick={() =>
                navigate(
                  `/instance/${instanceId}/patient-dashboard/${item.userId}/${patientTabIDs.dashboard}`
                )
              }
              color="primary"
            >
              <ActionIcon src={patientProfileIcon} alt="Go to patient profile" />
            </StyledIconButton>
          </Badge>
        </Restriction>
        <Restriction ability="read" feature="reimbursement">
          <StyledIconButton
            aria-label="actions"
            aria-haspopup="true"
            onClick={() =>
              navigate(
                `/instance/${instanceId}/patient-dashboard/${item.userId}/${patientTabIDs.reimbursement}`
              )
            }
            color="primary"
          >
            <ActionIcon src={rsgCard} alt="TCN Card" />
          </StyledIconButton>
        </Restriction>
        <Restriction ability="read" feature="arrive">
          <Badge
            invisible={filterModuleBasedBadge(item.badges, 'Arrive').length === 0}
            tooltipItems={filterModuleBasedBadge(item.badges, 'Arrive')}
            badgeCount={filterModuleBasedBadge(item.badges, 'Arrive')[0]?.count}
          >
            <StyledIconButton
              aria-label="actions"
              aria-haspopup="true"
              onClick={() =>
                navigate(
                  `/instance/${instanceId}/patient-dashboard/${item.userId}/arrive/arriveDashboard`
                )
              }
              color="primary"
            >
              <ActionIcon src={tcnArrive} alt="TCN Arrive" />
            </StyledIconButton>
          </Badge>
        </Restriction>
      </ActionCellWrapper>
    )
  }))

  const handlePageNumber = newPageNumber => {
    dispatch(setPatientTablePageNumber(newPageNumber))
  }

  const handleRowsPerPage = rowsPerPage => {
    dispatch(setRowsPerPage(rowsPerPage))
    dispatch(setPatientTablePageNumber(0))
  }
  const handleExpandedFilters = () => {
    dispatch(setExpandedFilters(!expandedFilters))
  }

  /* -----End of DataGrid related actions------ */

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Home</Typography>}
        actionContent={
          <Grid container spacing={2}>
            <Restriction ability="read" feature="PatientListAddPatientButton">
              <Grid item>
                <Button
                  size="small"
                  onClick={() => dispatch(setAddPatientFlow(true))}
                  startIcon={<Add />}
                >
                  <Typography variant="body1">Add a Patient</Typography>
                </Button>
              </Grid>
            </Restriction>
            {/* <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={onExportButtonClicked}
                startIcon={<GetApp />}
              >
                <Typography variant="body1">Export Data</Typography>
              </Button>
            </Grid> */}
            <ToggleButton
              value="active"
              color="primary"
              onClick={e => toggleButton(e, 'active')}
              size="small"
              disabled={alignment === 'active'}
            >
              <Typography variant="body1">View Active</Typography>
            </ToggleButton>
            <ToggleButton
              value="all"
              color="primary"
              onClick={e => toggleButton(e, 'all')}
              size="small"
              disabled={alignment === 'all'}
            >
              <Typography variant="body1">View All</Typography>
            </ToggleButton>
            <Restriction ability="read" feature="PatientListFilter">
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleExpandedFilters}
                  size="small"
                  startIcon={<FilterAlt />}
                >
                  <Typography variant="body1">Filter / Search</Typography>
                </Button>
              </Grid>
            </Restriction>
          </Grid>
        }
        content={<PatientGridFilters />}
        expandContent={expandedFilters}
      />
      <DataGrid
        headers={updatedHeaders}
        tableData={updatedPatientListData.slice(
          patientTablePageNumber * rowsPerPage,
          patientTablePageNumber * rowsPerPage + rowsPerPage
        )}
        showExportToExcel={false}
        setRequestedSort={orderByValue => dispatch(handleRequestSort(orderByValue))}
        order={order}
        orderBy={orderBy}
        border="none"
        radius="false"
        isLoading={patientsList.isLoading}
      />
      {!patientsList.isLoading && (
        <Pagination
          canChangeRowsPerPage={[20, 50, 100]}
          totalCount={patientsList.totalCount}
          rowsPerPage={rowsPerPage}
          pageNumber={patientTablePageNumber}
          setPageNumber={handlePageNumber}
          setRowsPerPage={handleRowsPerPage}
        />
      )}

      <QuickView
        title={
          <span>
            Welcome to TCN<sup>®</sup> Engage
          </span>
        }
        onClose={() => dispatch(setIsAddressVerificationRequired(false))}
        dialogContent={<SiteAddressConfirmation />}
        isDialogOpen={isAddressVerificationRequired}
        maxWidth="md"
      />
      <QuickView
        title={
          <span>
            Welcome to TCN<sup>®</sup> Engage
          </span>
        }
        onClose={() => dispatch(setAddressVerificationSuccessModal(false))}
        dialogContent={
          <>
            <Typography variant="body1">
              Thank you. You may update your site address information at any time in the Site
              Management section located on the side navigation.
            </Typography>
          </>
        }
        isDialogOpen={addressVerificationSuccessModal}
        maxWidth="md"
        dialogActions={
          <Button
            color="primary"
            size="large"
            onClick={() => {
              dispatch(setAddressVerificationSuccessModal(false))
              dispatch(fetchUnansweredSitePref())
            }}
          >
            Ok
          </Button>
        }
        alignActions="flex-start"
      />
      <QuickView
        title="Let Us Know"
        titleIcon={<QuestionAnswerOutlined />}
        onClose={() => dispatch(setSitePrefVerificationFlow(false))}
        dialogContent={<SiteTravelPreferences />}
        isDialogOpen={sitePrefVerificationFlow}
        maxWidth="md"
      />
      <QuickView
        title={
          <span>
            Welcome to TCN<sup>®</sup> Engage
          </span>
        }
        onClose={() => dispatch(setSitePrefSuccessModal(false))}
        dialogContent={<PatientExperienceInfoSuccess />}
        isDialogOpen={sitePrefSuccessModal}
        maxWidth="md"
      />

      <QuickView
        title="Add a New Patient"
        onClose={() => dispatch(setAddPatientFlow(false))}
        dialogContent={<AddPatient />}
        isDialogOpen={addPatientFlow}
        maxWidth="md"
        dialogActions={<div />}
        closeConfirmation={true}
      />

      <QuickView
        title="Patient Added Successfully"
        onClose={() => dispatch(setAddedPatientId(''))}
        dialogContent={<AddPatientSuccess />}
        isDialogOpen={!!addedPatientId}
        maxWidth="md"
        dialogActions={<div />}
      />

      <QuickView
        title="Address Verification"
        onClose={() => dispatch(setShowAddressVerificationPopup(false))}
        dialogContent={<AddressVerificationPopup />}
        isDialogOpen={showAddressVerificationPopup}
        maxWidth="md"
        dialogActions={<div />}
      />

      {isEditMode
        ? (
          <QuickView
            title={order ? 'Edit List Item' : 'Add List Item'}
            onClose={() => { }}
            dialogContent={<div>Edit Component</div>}
            isDialogOpen={false}
            isSmallDialog={false}
          />
        )
        : (
          <QuickView
            title="View Patient Information"
            onClose={() => { }}
            dialogContent={<div>View Component</div>}
            isDialogOpen={false}
            isSmallDialog={false}
          />
        )}
    </>
  )
}

export default Dashboard
