import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  announcementSendCategoryIDs,
  communicationTabIDs,
  recipientsDetailsCodes,
  sendToOptionsFor
} from 'components/helper/constants/mailbox'
import {
  convertDraftToHTML,
  copyObject,
  displayDate,
  displayTime,
  extractArrayOfIdsForEachKey,
  removeTimePart
} from 'components/helper/utility'
import { EditorState } from 'draft-js'
import {
  createAnnouncements,
  fetchAnnouncementById,
  fetchAnnouncements,
  fetchDistributionList,
  fetchUserAnnouncementById,
  getRecipientDetailsLookupBySendTo,
  updateAnnouncement,
  updateAnnouncementProperty,
  updateUserAnnouncementProperty
} from 'services/announcements'
import { downloadAttachmentByID, getSitesByCountries, postAttachments } from 'services/common'
import { fetchBadgesPerModule, showError, showSuccess } from '../application'
import { fetchMessagesSendCategory } from '../common'
import { setLoading } from '../userInteractions'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'

export const setComposeAnnouncementModal = createAction('announcements/setComposeAnnouncementModal')
export const setAnnouncementsList = createAction('announcements/setAnnouncementsList')
export const setFilters = createAction('announcements/setFilters')
export const setComposeAnnouncement = createAction('announcements/setComposeAnnouncement')
export const setViewAnnouncementDetails = createAction('announcements/setViewAnnouncementDetails')
export const setDeleteAnnouncementConfirmation = createAction(
  'announcements/setDeleteAnnouncementConfirmation'
)
export const setDistributionList = createAction('announcements/setDistributionList')
export const setAnnouncement = createAction('announcements/setAnnouncement')
export const setPreviewAnnouncement = createAction('announcements/setPreviewAnnouncement')
export const setSitesLookupByCountry = createAction('announcements/setSitesLookupByCountry')
export const setRecipientDetailsLookup = createAction('announcements/setRecipientDetailsLookup')
export const resetAnnouncements = createAction('announcements/resetAnnouncements')
export const setSelectedAnnouncement = createAction('announcement/setSelectedAnnouncement')
export const setAnnouncementListLoading = createAction('announcement/setAnnouncementListLoading')

export const getAnnouncements =
  (initialLoad, filters, patientId, selectedCommunicationTabId) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      dispatch(setAnnouncementsList({ isLoading: true }))
      const { isPatient } = getState().auth
      let finalFilters

      // if logged in as bbk or site and trying to fetch announcements for patients
      if (
        !isPatient &&
        selectedCommunicationTabId === communicationTabIDs.announcementsForPatients
      ) {
        const { patientInformation } = getState().patientMainWrapper
        finalFilters = {
          userId: patientInformation.userId ? patientInformation.userId : patientId,
          // isPatient is true to request announcement sent to this patient only
          isPatient: true
        }
      }

      if (filters) {
        const { sentTo, countries, sites, products, vendors } = filters
        const filterProperties = {
          sentTo,
          countries,
          products,
          vendors,
          uniqueSiteProtocolIds: {
            array: sites,
            propertyToExtract: 'alternateIdentifier'
          }
        }

        finalFilters = { ...extractArrayOfIdsForEachKey(filterProperties) }
        dispatch(setFilters(filters))
      } else {
        dispatch(setFilters(initialState.filters))
      }

      const { data } = await fetchAnnouncements(finalFilters)
      if (data.length > 0) {
        if (initialLoad) {
          // This call must happen before parsing the list for UI, as messageSendCategory Lookup is required for parsing
          await dispatch(fetchMessagesSendCategory(sendToOptionsFor[selectedCommunicationTabId]))
        }

        const { messagesSendCategory } = getState().common
        const parsedAnnouncements = data.map(d => ({
          ...d,
          attachments: d.attachments?.length > 0 ? d.attachments : [],
          isViewed: !!d.isViewed,
          distributionDate: displayDate(d.distributionDate),
          distributionTime: displayTime(d.distributionDate),
          createdDate: displayDate(d.createdOn),
          createdTime: displayTime(d.createdOn),
          countries: d.countries ? d.countries : [],
          sendTo: d.sendTo
            ? messagesSendCategory.results.find(category => category.id === d.sendTo)
            : ''
        }))

        dispatch(
          setAnnouncementsList({
            results: parsedAnnouncements,
            totalCount: parsedAnnouncements.length
          })
        )

        if (initialLoad) {
          await dispatch(getAnnouncementById(data[0], selectedCommunicationTabId))
          dispatch(setSelectedAnnouncement(data[0]))
        }
      } else {
        dispatch(setAnnouncementsList({ results: [], totalCount: 0 }))
        dispatch(setAnnouncement(initialState.announcement))
      }
    } catch {
      dispatch(
        showError('There was error while trying to fetch list of announcements. Please try again.')
      )
    }
    dispatch(setAnnouncementsList({ isLoading: false }))
    dispatch(setLoading(false))
  }

export const clearFilters = () => async dispatch => {
  dispatch(setFilters(initialState.filters))
}

export const makeASelectionAfterListCall = oldSubject => (dispatch, getState) => {
  // get latest state of the messagesList
  const { announcementsList, selectedAnnouncement } = getState().announcements

  // Find the index of the messages that user selected
  if (oldSubject) {
    // when announcement edited, user might update the subject
    const selectedAnnouncementIndex = announcementsList.results.findIndex(
      announcement => `${announcement.createdOn} ${oldSubject}` === `${selectedAnnouncement.createdOn} ${selectedAnnouncement.subject}`
    )
    // find the item in the messages list using above index so that selection is made again
    dispatch(setSelectedAnnouncement(announcementsList.results[selectedAnnouncementIndex]))
  } else {
    const selectedAnnouncementIndex = announcementsList.results.findIndex(
      announcement => `${announcement.createdOn} ${announcement.subject}` === `${selectedAnnouncement.createdOn} ${selectedAnnouncement.subject}`
    )
    // find the item in the messages list using above index so that selection is made again
    dispatch(setSelectedAnnouncement(announcementsList.results[selectedAnnouncementIndex]))
  }
}

export const publishAnnouncement =
  (composedAnnouncement, selectedCommunicationTabId) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedAnnouncement } = getState().announcements
      const {
        sendTo,
        recipientDetails,
        countries,
        filterByCountry,
        cohorts,
        vendors,
        products,
        siteProtocols,
        subject,
        body,
        attachments,
        distributionDate
      // displayAsBanner,
      } = composedAnnouncement

      const multiSelectDropDowns = {
        luCountryIds: filterByCountry?.length ? filterByCountry : countries,
        productIds: products,
        uniqueSiteProtocolIds: { array: siteProtocols, propertyToExtract: 'alternateIdentifier' },
        cohortIds: cohorts,
        vendorIds: vendors
      }

      let formattedPayload = {
        sendTo,
        luRecipientGroupIds: recipientDetails?.id && [recipientDetails.id],
        ...extractArrayOfIdsForEachKey(multiSelectDropDowns),
        subject,
        body,
        distributionDate: removeTimePart(distributionDate),
        // displayAsBanner: displayAsBanner ? true : null,
        messageType: '1'
      }

      if (attachments && attachments.length > 0) {
        const newlyUploadedAttachments = attachments.filter(a => !a.id)

        if (newlyUploadedAttachments.length > 0) {
        //  Create an object of formData
          const formData = new FormData()

          newlyUploadedAttachments.forEach(f => {
          // Update the formData object
            formData.append('files', f)
          })

          const { data } = await postAttachments(formData)
          if (data.length > 0) {
            const existingFileIds = attachments.filter(f => f.id).map(f => f.id)
            formattedPayload = {
              ...formattedPayload,
              attachmentIds: [...existingFileIds, ...data]
            }
          }
        }
      }

      if (composedAnnouncement.announcementId) {
        await updateAnnouncement({
          ...formattedPayload,
          announcementId: composedAnnouncement.announcementId,
          attachmentsToBeDeleted: composedAnnouncement.attachmentsToBeDeleted
        })
        dispatch(setViewAnnouncementDetails(false))
        dispatch(setComposeAnnouncementModal(false))

        await dispatch(getAnnouncements(false, false, false, selectedCommunicationTabId))
        // this is to check if user has updated the subject, in that case send the old subejct for comparision and finding the correct list item
        if (formattedPayload.subject !== selectedAnnouncement.subject) {
          dispatch(makeASelectionAfterListCall(selectedAnnouncement.subject))
        } else {
          dispatch(makeASelectionAfterListCall())
        }

        await dispatch(getAnnouncementById(composedAnnouncement, selectedCommunicationTabId))

        dispatch(showSuccess('Announcement updated successfully.'))
      } else {
        await createAnnouncements(formattedPayload)
        dispatch(setViewAnnouncementDetails(false))
        dispatch(setComposeAnnouncementModal(false))
        await dispatch(
          getAnnouncements(
            !!selectedAnnouncement.announcementId,
            false,
            false,
            selectedCommunicationTabId
          )
        )
        dispatch(showSuccess('Announcement created successfully.'))
      }
    } catch (e) {
      dispatch(
        showError(
          'Network call failed. Please try again or contact IT support if you continue to see this issue.', e
        )
      )
    }
    dispatch(setLoading(false))
  }

// eslint-disable-next-line complexity
export const getAnnouncementById = (selectedItem, selectedCommunicationTabId) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { messagesSendCategory } = getState().common
    const { isPatient } = getState().auth
    let response
    // this is to check and who is viewing the message (BBK or Site)
    if (selectedItem.userAnnouncementId) {
      const { data } = await fetchUserAnnouncementById(selectedItem.userAnnouncementId)
      response = data
    } else {
      const { data } = await fetchAnnouncementById(selectedItem.announcementId)
      response = data
    }

    if (response) {
      let countries = []
      let filterByCountry
      const recipientDetails = response.recipients?.length && response.recipients[0]
      const allRecipientCategory = [1, 6, 9, 11]
      if (recipientDetails?.id === recipientsDetailsCodes.patientsBySite) {
        // when user selected "patient by site" from Recipient details dropdown
        filterByCountry = response.countries
      } else if (
        recipientDetails &&
        !allRecipientCategory.includes(recipientDetails.id) &&
        response.countries
      ) {
        countries = response.countries
      }

      const finalRes = {
        ...response,
        sendTo: response.sendTo
          ? messagesSendCategory.results.find(category => category.id === response.sendTo)
          : '',
        countries,
        filterByCountry,
        vendors: response.vendors ? response.vendors : [],
        siteProtocols: response.siteProtocols ? response.siteProtocols : [],
        cohorts: response.cohorts ? response.cohorts : [],
        products: response.products ? response.products : [],
        recipientDetails,
        attachments: response.attachments ? response.attachments : '',
        body: response.body,
        // displayAsBanner: response.displayAsBanner ? response.displayAsBanner : false,
        attachmentsToBeDeleted: [],
        distributionDate: displayDate(response.distributionDate),
        distributionTime: displayTime(response.distributionDate),
        createdOn: displayDate(response.createdOn),
        createdOnTime: displayTime(response.createdOn)
      }
      dispatch(setAnnouncement(finalRes))
      dispatch(setDistributionList([]))
    }

    // if logged in as bbk or site and trying to fetch Announcements for patients
    if (!isPatient &&
    selectedCommunicationTabId !== communicationTabIDs.announcementsForPatients) {
      await dispatch(onViewAnnouncement(selectedItem))
    } else if (isPatient) {
      await dispatch(onViewAnnouncement(selectedItem))
    }
  } catch {
    dispatch(showError('There was some error while trying fetch announcement.'))
  }
  dispatch(setLoading(false))
}

export const getDistributionList = announcement => async dispatch => {
  try {
    dispatch(setLoading(true))
    if (!announcement.userAnnouncementId && announcement.isDistributed) {
      const { data } = await fetchDistributionList(announcement.announcementId)
      if (data.length) {
        dispatch(
          setDistributionList(
            data.map(d => ({
              ...d,
              readUnread: d.isViewed ? 'Read' : 'Unread',
              siteID: d.sponsorSiteId
            }))
          )
        )
      }
    }
  } catch (e) {
    dispatch(showError('There was some issue while trying to fetch distribution list.', e))
  }
  dispatch(setLoading(false))
}

export const onEditAnnouncementClicked = announcement => async dispatch => {
  const sendToValue = announcement.sendTo.id
  // parse the entire announcement information in a way that it can be shown in the edit form
  if (sendToValue !== announcementSendCategoryIDs.allActiveUsers) {
    await dispatch(fetchRecipientDetailsLookupBySendTo(sendToValue))
  }
  if (
    announcement.recipientDetails?.id === recipientsDetailsCodes.patientsBySite &&
    announcement.country
  ) {
    // the selected value of Recipient Details dropdown is "patient by Site"
    await dispatch(fetchSitesByCountries([announcement.country]))
  }
  dispatch(setComposeAnnouncement({ ...announcement, sendTo: sendToValue }))
  dispatch(setComposeAnnouncementModal(true))
}

export const deleteAnnouncement =
  (selectedMessage, patientId, selectedCommunicationTabId) => async dispatch => {
    try {
      dispatch(setLoading(true))
      await updateAnnouncementProperty(selectedMessage.announcementId, 'isVisible', false)

      await dispatch(getAnnouncements(true, false, patientId, selectedCommunicationTabId))
      dispatch(setDeleteAnnouncementConfirmation(false))
      dispatch(setViewAnnouncementDetails(false))
      dispatch(showSuccess('Announcement deleted successfully.'))
    } catch (e) {
      dispatch(
        showError(
          'There was error while trying to fetch distributed list. Please try again later.'
        )
      )
    }

    dispatch(setLoading(false))
  }

export const downloadAttachment = attachment => async dispatch => {
  try {
    dispatch(setLoading(true))
    await downloadAttachmentByID(attachment)
  } catch (e) {
    dispatch(showError('There was some issue with downloading the attachment.', e))
  }

  dispatch(setLoading(false))
}

export const onViewAnnouncement = selectedMessage => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { announcementsList } = getState().announcements
    const { isPatient } = getState().auth
    if (!selectedMessage.isViewed && selectedMessage.userAnnouncementId) {
      await updateUserAnnouncementProperty(selectedMessage.userAnnouncementId, 'isViewed', true)
      // find and change the value of isViewed to true from false
      const newAnnouncementsList = [...announcementsList.results]
      const viewedIndex = announcementsList.results.findIndex(
        announcement => announcement.announcementId === selectedMessage.announcementId
      )
      newAnnouncementsList[viewedIndex] = { ...newAnnouncementsList[viewedIndex], isViewed: true }
      await dispatch(
        setAnnouncementsList({
          results: newAnnouncementsList,
          totalCount: announcementsList.totalCount
        })
      )
      if (isPatient) {
        await dispatch(fetchBadgesForPatientSection())
      } else {
        await dispatch(fetchBadgesPerModule())
      }
    }
  } catch {
    dispatch(
      showError('There was an error. Please check your network connection or try again later.')
    )
  }
  dispatch(setLoading(false))
}

export const resetAnnouncement = () => async dispatch => {
  const myDate = new Date()
  dispatch(
    setComposeAnnouncement({
      ...initialState.composeAnnouncement,
      distributionDate: myDate.toLocaleString('en-us')
    })
  )
}

export const fetchSitesByCountries = countriesLookup => async dispatch => {
  dispatch(setSitesLookupByCountry({ isLoading: true }))
  await getSitesByCountries(countriesLookup.map(country => country.id).toString())
    .then(res => {
      dispatch(setSitesLookupByCountry({ results: res, isLoading: false }))
    })
    .catch(() => {
      dispatch(setSitesLookupByCountry({ isLoading: false }))
      dispatch(
        showError('There was some issue while trying to fetch sites. Trying refreshing your page.')
      )
    })
}
export const fetchRecipientDetailsLookupBySendTo = sendToID => async dispatch => {
  try {
    dispatch(setRecipientDetailsLookup({ isLoading: true }))
    const { data } = await getRecipientDetailsLookupBySendTo(sendToID)
    // ! as cohorts functionality is on hold for now, find and remove "patient by cohorts" option
    const recipientLookup = data
    const indexOfPatientByCohort = data.findIndex(d => d.id === 4)

    if (indexOfPatientByCohort > -1) {
      recipientLookup.splice(indexOfPatientByCohort, 1)
    }
    dispatch(setRecipientDetailsLookup({ isLoading: false, results: recipientLookup }))
  } catch (e) {
    dispatch(setRecipientDetailsLookup({ isLoading: false }))
    dispatch(
      showError(
        'There was some error while trying to fetch dropdown options for Recipient Details.', e
      )
    )
  }
}

const initialState = {
  announcementsList: { totalCount: 0, results: [], isLoading: false },
  filters: {
    sentTo: [],
    countries: [],
    sites: [],
    cohorts: [],
    products: [],
    vendors: []
  },
  composeAnnouncementModal: false,
  previewAnnouncement: false,
  composeAnnouncement: {
    sendTo: 4,
    recipientDetails: { id: '', displayText: '' },
    countries: [],
    filterByCountry: [],
    cohorts: [],
    siteProtocols: [],
    vendors: [],
    products: [],
    subject: '',
    body: convertDraftToHTML(EditorState.createEmpty()),
    attachments: [],
    distributionDate: ''
    // displayAsBanner: false,
  },
  announcement: {
    sendTo: 4,
    recipientDetails: { id: '', displayText: '' },
    countries: [],
    filterByCountry: [],
    cohorts: [],
    siteProtocols: [],
    vendors: [],
    products: [],
    subject: '',
    body: convertDraftToHTML(EditorState.createEmpty()),
    attachments: [],
    distributionDate: ''
    // displayAsBanner: false,
  },
  selectedAnnouncement: {},
  viewAnnouncementDetails: false,
  deleteAnnouncementConfirmation: false,
  distributedList: { totalCount: 0, results: [] },
  sitesLookupByCountry: { results: [], isLoading: false },
  recipientDetailsLookup: { results: [], isLoading: false }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setComposeAnnouncementModal, (state, action) => {
      state.composeAnnouncementModal = action.payload
    })
    .addCase(setAnnouncement, (state, action) => {
      state.announcement = action.payload
    })
    .addCase(setSelectedAnnouncement, (state, action) => {
      state.selectedAnnouncement = action.payload
    })
    .addCase(setComposeAnnouncement, (state, action) => {
      state.composeAnnouncement = action.payload
    })
    .addCase(setAnnouncementsList, (state, { payload }) => {
      state.announcementsList = { ...state.announcementsList, ...payload }
    })
    .addCase(setFilters, (state, action) => {
      state.filters = action.payload
    })
    .addCase(setViewAnnouncementDetails, (state, action) => {
      state.viewAnnouncementDetails = action.payload
    })
    .addCase(setDeleteAnnouncementConfirmation, (state, action) => {
      state.deleteAnnouncementConfirmation = action.payload
    })
    .addCase(setDistributionList, (state, action) => {
      state.distributedList.results = action.payload
      state.distributedList.totalCount = action.payload.length
    })
    .addCase(setPreviewAnnouncement, (state, action) => {
      state.previewAnnouncement = action.payload
    })
    .addCase(setSitesLookupByCountry, (state, { payload }) => {
      state.sitesLookupByCountry = { ...state.sitesLookupByCountry, ...payload }
    })
    .addCase(setRecipientDetailsLookup, (state, { payload }) => {
      state.recipientDetailsLookup = { ...state.recipientDetailsLookup, ...payload }
    })
    .addCase(resetAnnouncements, state => {
      copyObject(state, initialState)
    })
})
