import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, IconButton, Badge, Grid, Chip } from '@mui/material'
import { Add, Error, GroupWork, FilterAlt } from '@mui/icons-material'
import styled from '@emotion/styled'
import { palette, combinedBaseTheme } from 'styles/baseTheme'
import { PageHeader, DataGrid, Pagination, Button } from 'components/common'
import QuickView from 'components/common/QuickView'
// import { Restriction } from 'permissions'
import AddEditECDetails from './AddEditECDetails'
import ECDirectoryFilters from './ECDirectoryFilter'
import { fetchEthicsCommittee, resetAddEditECFlow, setAddEditECFlow, setECTablePageNumber, startAdECSuccess, setRowsPerPage, handleRequestSort } from 'store/ducks/siteManagement/ecDirectory/ecManagement'

const ethicsCommitteeHeaders = [
  { id: 'manage', label: ' ' },
  { id: 'ethicCommiteeName', label: 'Ethics Committee' },
  { id: 'ethicsCommiteeType', label: 'Type ' },
  { id: 'country', label: 'Country' },
  { id: 'associatedSiteID', label: 'Associated Site IDs' }
]

const EmptyFooter = styled.div`
  padding: 25px;
`
const ActionCellWrapper = styled.div`
  width: 60px;
`
const ECPinIcon = styled(GroupWork)`
  width: 15px;
`
const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 8px;
  padding: 1px 3px;
`
)

const SitesListWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    '.MuiChip-root': {
      width: 'fit-content',
      margin: theme.spacing(0.5)
    }
  }
}))

// eslint-disable-next-line max-lines-per-function
const ECDirectoryDashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [expandFilters, setExpandedFilters] = useState(false)

  const { ethicsCommitteeLookup, addEditECFlow, ethicsCommitteeTablePageNumber, rowsPerPage, order, orderBy } = useSelector(
    store => store.ecManagement
  )

  const { instanceID } = useParams()

  useEffect(() => {
    dispatch(fetchEthicsCommittee())
    // Cleanup Redux values for this component/feature
    return () => {
      dispatch(resetAddEditECFlow())
    }
  }, [])

  /* -----DataGrid related actions------ */
  const updatedHeaders = ethicsCommitteeHeaders.map(header => {
    const headerProperties = {
      backgroundColor: palette.secondary.main,
      color: palette.primary.main,
      borderBottom: `1px solid ${palette.gray.main}`,
      borderTop: `1px solid ${palette.gray.main}`,
      borderLeft: '0px',
      borderRight: '0px',
      cellType: 'node',
      padding: '24px 8px',
      textAlign: 'center'
    }
    const bodyProperties = {
      backgroundColor: palette.secondary.main,
      borderLeft: '0px',
      borderBottom: `1px solid ${palette.gray.main}`,
      borderRight: `1px solid ${palette.gray.main}`
    }

    if (header.id === 'manage') {
      return {
        ...header,
        headProperties: {
          ...headerProperties,
          borderRight: `4px solid ${palette.gray.light}`,
          borderLeft: `1px solid ${palette.gray.main}`,
          borderTopLeftRadius: `${combinedBaseTheme.shape.mediumRadius}`
        },
        bodyProperties: {
          ...bodyProperties,
          cellType: 'node',
          borderRight: `4px solid ${palette.gray.main}`,
          margin: '0px !important',
          width: '.5%'
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else if (header.id === 'associatedSiteID') {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties
        },
        bodyProperties: {
          ...bodyProperties,
          cellType: 'node',
          width: '46.5%'
        }
      }
    } else if (header.id === 'ethicCommiteeName') {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties
        },
        bodyProperties: {
          ...bodyProperties,
          cellType: 'node',
          width: '29%'
        }
      }
    } else {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties
        },
        bodyProperties: {
          ...bodyProperties,
          width: '12%'
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    }
  })

  let updatedEthicsCommitteeList = []

  const onTableRowSelection = (event, encrypttedEthicID) => {
    navigate(`/instance/${instanceID}/ec-details/${encrypttedEthicID}`)
  }

  updatedEthicsCommitteeList = ethicsCommitteeLookup?.results && ethicsCommitteeLookup?.results?.map(item => ({
    ...item,
    manage: (
      <ActionCellWrapper>
        <IconButtonWrapper
          aria-label="actions"
          aria-haspopup="true"
          onClick={event => onTableRowSelection(event, item.encrypttedEthicID)}
          color="primary"
        >
          {item.alerts
            ? (
              <Badge
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                badgeContent={<Error color="error" fontSize="small" />}
              >
                <ECPinIcon color="primary" fontSize="small" />{' '}
              </Badge>
            )
            : (
              <>
                <ECPinIcon color="primary" fontSize="small" />{' '}
              </>
            )}
        </IconButtonWrapper>
      </ActionCellWrapper>
    ),
    associatedSiteID: (
      <SitesListWrapper>
        {item.associatedSiteID && item.associatedSiteID.map((site, index) => (
          <Chip variant="outlined" size="small" key={index} label={site} />
        ))}
      </SitesListWrapper>
    )
  }))

  const handlePageNumber = newPageNumber => {
    dispatch(setECTablePageNumber(newPageNumber))
  }

  const handleRowsPerPage = rowsPerPage => {
    dispatch(setRowsPerPage(rowsPerPage))
    dispatch(setECTablePageNumber(0))
  }

  const onAddButtonClicked = () => {
    dispatch(setAddEditECFlow(true))
  }

  const closeAddECFlow = refreshECList => {
    dispatch(setAddEditECFlow(false))

    if (refreshECList) {
      dispatch(startAdECSuccess(true))
    }
  }

  const handleExpandedFilters = () => {
    setExpandedFilters(prevState => !prevState)
  }

  const goBackHandler = async e => {
    e.preventDefault()
    await dispatch(setECTablePageNumber(0))
    navigate(`/instance/${instanceID}/site-management`)
  }

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Ethics Committee Directory</Typography>}
        actionContent={
          <Grid container spacing={2}>
            <Grid item>
              <Button size="small" onClick={onAddButtonClicked} startIcon={<Add />}>
                <Typography variant="body1">Add EC</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleExpandedFilters}
                size="small"
                startIcon={<FilterAlt />}
              >
                <Typography variant="body1">Filter / Search</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button color="inherit" size="medium" onClick={goBackHandler}>
                Back
              </Button>
            </Grid>
          </Grid >
        }
        content={<ECDirectoryFilters />}
        expandContent={expandFilters}
      />
      <DataGrid
        headers={updatedHeaders}
        tableData={updatedEthicsCommitteeList.slice(
          ethicsCommitteeTablePageNumber * rowsPerPage,
          ethicsCommitteeTablePageNumber * rowsPerPage + rowsPerPage
        )}
        showExportToExcel={false}
        setRequestedSort={orderByValue => dispatch(handleRequestSort(orderByValue))}
        order={order}
        orderBy={orderBy}
        border="none"
        radius="false"
      />
      <Pagination
        canChangeRowsPerPage={[20, 50, 100]}
        totalCount={ethicsCommitteeLookup.totalCount}
        rowsPerPage={rowsPerPage}
        pageNumber={ethicsCommitteeTablePageNumber}
        setPageNumber={handlePageNumber}
        setRowsPerPage={handleRowsPerPage}
      />
      <QuickView
        title="Add an Ethics Committee"
        onClose={() => closeAddECFlow(false, null)}
        dialogContent={
          <AddEditECDetails
            closeForm={() => closeAddECFlow(false, null)}
            refreshSiteDetails={true}
          />
        }
        isDialogOpen={addEditECFlow}
        maxWidth="md"
        dialogActions={<EmptyFooter />}
        closeConfirmation={true}
      />
    </>
  )
}

export default ECDirectoryDashboard
