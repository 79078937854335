import React from 'react'
import { Fade, IconButton, MenuItem, TablePagination, Typography } from '@mui/material'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material'

const LastPageButtonsWrapper = styled('div')({
  display: 'flex',
})

const CustomTablePagination = styled(TablePagination)`
  && {
    min-height: 50px;
    color: #595959;

    .MuiIconButton-root {
      color: ${({ theme }) => theme.palette.secondary};
    }
    .Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
    .MuiSelect-icon {
      top: calc(50% - 14px);
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`
const PaginationWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  color: '#595959',
})

const FetchingText = styled('span')({
  display: 'flex',
  alignItems: 'center',
  padding: '15px',
})

const TablePaginationActions = props => {
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <LastPageButtonsWrapper>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page" size="large">
        <FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" size="large">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        <LastPage />
      </IconButton>
    </LastPageButtonsWrapper>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
const Pagination = ({
  fetchingTotalCount,
  canChangeRowsPerPage,
  totalCount,
  rowsPerPage,
  pageNumber,
  setPageNumber,
  setRowsPerPage,
}) => (
  <PaginationWrapper>
    {fetchingTotalCount
      ? (
        <FetchingText>
          <span>
            <Typography variant="subtitle2">Fetching total number of pages...</Typography>
          </span>
        </FetchingText>
      )
      : (
        <Fade in={!fetchingTotalCount} timeout={{ enter: 800, exit: 800 }}>
          <div>
            <CustomTablePagination
              rowsPerPageOptions={canChangeRowsPerPage || [10]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
                value: rowsPerPage,
                children: canChangeRowsPerPage.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                )),
              }}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={(event, newPage) => setPageNumber(newPage)}
              onRowsPerPageChange={event => setRowsPerPage(event.target.value)}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Fade>
      )}
  </PaginationWrapper>
)

Pagination.defaultProps = {
  totalCount: 0
}

Pagination.propTypes = {
  /**
   * When total count is passed, the pagination is calculated
   */
  totalCount: PropTypes.number,
  /**
   * When true, the pagination option will wait.
   */
  fetchingTotalCount: PropTypes.bool,
  /**
   * Hide or show the dropdown to change rows per page
   */
  canChangeRowsPerPage: PropTypes.array,
  /**
   * Current page number
   */
  pageNumber: PropTypes.number,
  /**
   * count for the number of rows per page
   */
  rowsPerPage: PropTypes.number,
  /**
   * Pagination functionality to jump to a particular page
   */
  setPageNumber: PropTypes.func,
  /**
   * Selecting option to show number of rows per page
   */
  setRowsPerPage: PropTypes.func,
}

export default Pagination
