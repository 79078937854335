import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import * as Yup from 'yup'

import { Box, Typography } from '@mui/material'
import Edit from '@mui/icons-material/Edit'

import {
  setForgotPasswordConfirmation,
  setForgotPasswordSuccess,
  submitPatientForgotPassword,
  onPatientEmailAddressChange,
  setIsPatientEmaildAddressReadOnly,
  setPatientReactivationConfirmation,
  submitPatientReactivation,
} from 'store/ducks/patientRecord/patientProfile'

import Button from 'components/common/form/Button'
import TextBoxOuterLabel from 'components/common/form/TextBoxOuterLabel'
import QuickView from 'components/common/QuickView'
import { Restriction } from 'permissions'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 22px;
`
const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
const PatientCredentialsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: theme.spacing(2),
  '.MuiFormControl-root': {
    marginRight: 22
  },
  button: {
    marginRight: 22
  }
}))
const MarginDiv = styled.div(({ theme }) => `
  margin-top: ${theme.spacing(3.3)};
`)
const ConfirmationActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: space-around;
`

const validationSchema = Yup.object({
  emailAddress: Yup.string().required('Email address is required'),
})

const InfoBar = ({ lastLoginDate, tcnEngageAccess }) => {
  const formatDate = date => {
    if (!date) return 'N/A'
    const options = { day: '2-digit', month: 'short', year: 'numeric' }
    return new Date(date).toLocaleDateString('en-US', options)
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      p={1}
      gap={4}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <h4>TCN Engage Access:</h4>
        <Typography variant="body1" component="span" sx={{ fontSize: '14px' }} >
          {tcnEngageAccess || 'N/A'}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <h4>
          Last Login Date:
        </h4>
        <Typography variant="body1" component="span" sx={{ fontSize: '14px' }} >
          {lastLoginDate ? formatDate(lastLoginDate) : 'N/A'}
        </Typography>
      </Box>
    </Box>
  )
}

// Add propTypes for props validation
InfoBar.propTypes = {
  lastLoginDate: PropTypes.string,
  tcnEngageAccess: PropTypes.string,
}

const EditPatientEmailAddress = () => {
  const dispatch = useDispatch()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { selectedInstance } = useSelector(store => store.auth)
  const { instanceId } = selectedInstance
  const { isPatientEmaildAddressReadOnly, patientReactivationConfirmation } = useSelector(
    store => store.patientProfile
  )
  const { forgotPasswordConfirmation, forgotPasswordSuccess } = useSelector(
    store => store.patientProfile
  )

  return (
    <>
      <Formik
        initialValues={{ emailAddress: patientInformation.emailAddress }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={values => {
          dispatch(onPatientEmailAddressChange(values))
        }}
      >
        {({ handleReset }) => (
          <div style={{ width: '100%' }}>
            <Form>
              <Restriction ability="read" feature="patientProfileEditEmail">
                {isPatientEmaildAddressReadOnly && (
                  <IconButtonWrapper>
                    <Button
                      color="secondary"
                      aria-label="Edit"
                      onClick={() => dispatch(setIsPatientEmaildAddressReadOnly(false))}
                      startIcon={<Edit />}
                      size="small"
                    >
                      Edit
                    </Button>
                    &nbsp;
                  </IconButtonWrapper>
                )}
              </Restriction>
              {!isPatientEmaildAddressReadOnly && <MarginDiv />}
              <InfoBar
                lastLoginDate={patientInformation.lastLoginDate}
                tcnEngageAccess={patientInformation.tcnEngageAccess}
              />
              <PatientCredentialsWrapper>
                <TextBoxOuterLabel
                  size="medium"
                  id="emailAddress"
                  label="Email Address / Username"
                  name="emailAddress"
                  type="text"
                  disabled={isPatientEmaildAddressReadOnly}
                  fullWidth={false}
                />
                <Restriction ability="read" feature="patientProfileForgotUsernamePassword">
                  <Button
                    variant="text"
                    onClick={() => dispatch(setForgotPasswordConfirmation(true))}
                  >
                    Reset Password
                  </Button>
                </Restriction>
                {patientInformation.isActivationRequired
                  ? (
                    <Button variant="text" onClick={() => dispatch(setPatientReactivationConfirmation(true))}>
                    Resend Activation Email
                    </Button>
                  )
                  : (
                    ''
                  )}
              </PatientCredentialsWrapper>

              {/* <Restriction ability="read" feature="patientProfileAccountNotActivated">
                    <LinkContainers item xs={12} sm={12} md={3} lg={3}>
                      <Button variant="text" onClick={() => {}}>
                        Account Not Activated
                      </Button>
                    </LinkContainers>
                </Restriction> */}

              {!isPatientEmaildAddressReadOnly && (
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                  &ensp;&ensp;
                  <Button
                    size="large"
                    color="inherit"
                    fullWidth={false}
                    onClick={() => {
                      dispatch(setIsPatientEmaildAddressReadOnly(true))
                      handleReset()
                    }}
                  >
                    Cancel
                  </Button>
                </ActionWrapper>
              )}
              <br />
              <hr />
            </Form>
          </div>
        )}
      </Formik>
      <QuickView
        title="Confirmation"
        onClose={() => dispatch(setForgotPasswordConfirmation(false))}
        dialogContent={
          <>
            <Typography variant="body2" gutterBottom>
              Are you sure this participant forgot their password?
            </Typography>
            <Typography variant="body1">
              Clicking &quot;Yes&quot; will send a Reset Password email to the email address listed
              in the patient profile.
            </Typography>
          </>
        }
        dialogActions={
          <ConfirmationActionsWrapper>
            <Button
              color="primary"
              size="medium"
              onClick={() => {
                dispatch(submitPatientForgotPassword())
              }}
            >
              Yes
            </Button>
            <Button label="No" onClick={() => dispatch(setForgotPasswordConfirmation(false))}>
              No
            </Button>
          </ConfirmationActionsWrapper>
        }
        isDialogOpen={forgotPasswordConfirmation}
        maxWidth="sm"
      />
      <QuickView
        title="Confirmation"
        onClose={() => dispatch(setPatientReactivationConfirmation(false))}
        dialogContent={
          <>
            <Typography variant="body2" gutterBottom>
            Are you sure you want to resend the activation email to this patient?
            </Typography>
            <Typography variant="body1">
              Clicking &quot;Yes&quot; will send an activation email to the email address listed in the patient profile.
            </Typography>
          </>
        }
        dialogActions={
          <ConfirmationActionsWrapper>
            <Button
              color="primary"
              size="medium"
              onClick={() => {
                dispatch(submitPatientReactivation(patientInformation.userId, instanceId))
              }}
            >
              Yes
            </Button>
            <Button
              size="medium"
              onClick={() => {
                dispatch(setPatientReactivationConfirmation(false))
              }}
            >
              No
            </Button>
          </ConfirmationActionsWrapper>
        }
        isDialogOpen={patientReactivationConfirmation}
        maxWidth="sm"
      />
      <QuickView
        title="Confirmation"
        onClose={() => dispatch(setForgotPasswordSuccess(false))}
        contentText="An email has been sent to the participant with the information on how to reset their password."
        dialogActions={
          <>
            <Button
              color="primary"
              size="medium"
              onClick={() => {
                dispatch(setForgotPasswordSuccess(false))
              }}
            >
              Ok
            </Button>
          </>
        }
        isDialogOpen={forgotPasswordSuccess}
        maxWidth="sm"
      />
    </>
  )
}

export default EditPatientEmailAddress
