import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MediaCard from 'components/common/MediaCard'
import { AppBar, Button, PageHeader } from 'components/common'
import { Container, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { FilterAlt } from '@mui/icons-material'
import {
  checkSecurityStatus,
  fetchListArchivedOfInstances,
  performLogout,
  setupSelectedInstance,
  submitLastLogin
} from 'store/ducks/auth'
import TCNLogo from 'assets/images/tcn-logo.png'
import { maskingScrollBars } from 'styles/baseTheme'
import { useNavigate } from 'react-router-dom'
import { getPolicyPermissions } from 'store/ducks/application'
import { fetchInstanceConfigDetails } from 'store/ducks/configuration/instanceConfiguration'
import StudyListFilters from '../studyList/StudyListFilters'

const Wrapper = styled(Container)(
  ({ theme }) => `
  && {
    padding-top: ${theme.spacing(15)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  `
)

const MarginGrid = styled(Grid)`
  margin-bottom: 25px;
`

const ContentWrapper = styled.div({
  maxHeight: '70vh',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'auto',
  justifyContent: 'space-evenly',
  ...maskingScrollBars
})

const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PrivacyPolicyButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.palette.primary.dark};
  text-decoration: unset;
  `
)

const LogoutButton = styled(Button)`
  width: fit-content;
  text-decoration: none;
`

const Archived = () => {
  const { archivedInstances } = useSelector(store => store.auth)
  const { isSidebarOpen } = useSelector(store => store.userInteractions)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    dispatch(fetchListArchivedOfInstances())
  }, [])

  const onInstanceClick = async instance => {
    await dispatch(setupSelectedInstance(instance))
    const securityAnswerVerified = JSON.parse(localStorage.getItem('securityAnswerVerified'))
    localStorage.setItem('selectedInstance', JSON.stringify(instance))
    if (securityAnswerVerified) {
      await dispatch(submitLastLogin(instance.instanceId))
      await dispatch(getPolicyPermissions())
      await dispatch(fetchInstanceConfigDetails())
      navigate(`/instance/${instance.instanceId}/dashboard`)
    } else {
      dispatch(checkSecurityStatus())
    }
  }

  return (
    <>
      <AppBar
        isSidebarOpen={isSidebarOpen}
        logo={<img src={TCNLogo} width="40px" />}
        action={
          <LogoutButton
            variant="text"
            color="inherit"
            size="small"
            fullWidth={true}
            onClick={() => {
              dispatch(performLogout(() => navigate('/login'), false))
            }}
          >
            <Typography variant="body2">Logout</Typography>
          </LogoutButton>
        }
      />

      <Wrapper maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <PageHeader
              title={<Typography variant="h4">Archived List</Typography>}
              actionContent={
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      color="inherit"
                      variant="outlined"
                      size="small"
                      startIcon={<FilterAlt />}
                      onClick={() => {
                        setShowFilters(!showFilters)
                      }}
                    >
                      <Typography variant="body1">Filter / Search</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      fullWidth={true}
                      color="inherit"
                      onClick={() => {
                        navigate('/portal')
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              }
              content={<StudyListFilters />}
              expandContent={showFilters}
            />
          </Grid>
          <MarginGrid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ContentWrapper>
              {archivedInstances && archivedInstances.length > 0
                ? archivedInstances.map((ins, index) => (
                  <div key={`${ins.instanceId}-${index}`}>
                    <MediaCard
                      cardID={ins.instanceId}
                      imageURL={ins.logo}
                      studyTitle={ins.instanceName}
                      onCardClicked={() => onInstanceClick(ins)}
                    />
                  </div>
                ))
                : <Typography variant='h3' align='center'>No archived instances to display</Typography>}
            </ContentWrapper>
          </MarginGrid>
        </Grid>
        <TermsWrapper>
          <PrivacyPolicyButton
            variant="text"
            size="small"
            fullWidth={false}
            onClick={() => window.open('/doc/privacy-notice', '_blank', 'rel=noopener noreferrer')}
          >
            <Typography variant="caption">Privacy Policy</Typography>
          </PrivacyPolicyButton>
          <Typography variant="caption">|</Typography>
          <PrivacyPolicyButton
            variant="text"
            size="small"
            fullWidth={false}
            onClick={() => window.open('/doc/tnc', '_blank', 'rel=noopener noreferrer')}
          >
            <Typography variant="caption">Terms & Conditions</Typography>
          </PrivacyPolicyButton>
        </TermsWrapper>
      </Wrapper>
    </>
  )
}

export default Archived
