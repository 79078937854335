import React from 'react'
import { Grid, Paper, IconButton } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCategoriesLookup,
  // fetchProgramsLookup,
  fetchSitesLookup,
  fetchSystemIDsByLoggedInSite
} from 'store/ducks/common'
import { AutoComplete, Button, Alert, Checkbox } from 'components/common'
import PropTypes from 'prop-types'
import {
  clearFilters,
  fetchStudyMessages,
  fetchSystemByCommunicationTab
} from 'store/ducks/communicationCenter/studyMessages'
import * as Yup from 'yup'
import { communicationTabIDs } from 'components/helper/constants/mailbox'
import { ArrowDropUp } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'

const Wrapper = styled(Paper)`
  padding: 25px 25px 0px 25px;
`
const FormElementsWrapper = styled(Grid)`
  max-height: 22vh;
  overflow-y: auto;
  margin-bottom: 25px;
  padding-right: 16px;
`
const CollapseFilter = styled(Grid)`
  display: flex;
  justify-content: center;
  button {
    padding-bottom: 0px;
  }
`

const StudyMessagesFilters = ({ handleClose }) => {
  const dispatch = useDispatch()
  const {
    categoriesLookup,
    // programsLookup,
    sitesLookup,
    systemIDsByLoggedInSite
  } = useSelector(store => store.common)
  const { filters, systemIDsByCommunicationTab } = useSelector(store => store.studyMessages)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const { loggedInAsSite } = useSelector(store => store.auth)

  const handleClearFilters = async formikProps => {
    formikProps.resetForm()
    dispatch(clearFilters())
    handleClose()
    await dispatch(fetchStudyMessages(true, false, patientInformation.userId, selectedCommunicationTabId))
  }

  const fetchSystemIDOptions = () => {
    if (loggedInAsSite) {
      dispatch(fetchSystemIDsByLoggedInSite())
    } else {
      dispatch(fetchSystemByCommunicationTab(selectedCommunicationTabId))
    }
  }

  let updatedSystemIDOptions = {
    ...systemIDsByCommunicationTab
  }
  if (loggedInAsSite) {
    updatedSystemIDOptions = {
      ...systemIDsByLoggedInSite
    }
  }

  const validations = Yup.object().shape({
    filterFormValidation: Yup.bool().when(
      ['categories', 'sites', 'systemIDs', 'isScheduledMessage', 'isFlagged'],
      {
        is: (categories, sites, systemIDs, isScheduledMessage, isFlagged) => {
          if (
            [categories, sites, systemIDs].filter(t => t.filter(Boolean).length > 0).length > 0 ||
            isScheduledMessage ||
            isFlagged
          ) {
            return false
          } else {
            return true
          }
        },
        then: Yup.bool().required('Minimum one selection is required to filter the list.'),
        otherwise: Yup.bool()
      }
    )
  })

  return (
    <Wrapper elevation={0}>
      <Formik
        initialValues={filters}
        enableReinitialize={true}
        validationSchema={validations}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(fetchStudyMessages(true, values, patientInformation.userId, selectedCommunicationTabId))
          setSubmitting(false)
        }}
      >
        {formikProps => (
          <Form>
            <FormElementsWrapper container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Checkbox
                  name="isScheduledMessage"
                  id="isScheduledMessage"
                  label="Scheduled Messages"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Checkbox name="isFlagged" id="isFlagged" label="Flagged Messages" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                  id="selectCategories"
                  name="categories"
                  label="Category"
                  options={categoriesLookup.results}
                  isLoading={categoriesLookup.isLoading}
                  fetchOptions={() => dispatch(fetchCategoriesLookup(selectedCommunicationTabId))}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                  id="selectPrograms"
                  name="programs"
                  label="Program"
                  options={programsLookup.results}
                  isLoading={programsLookup.isLoading}
                  fetchOptions={() => dispatch(fetchProgramsLookup())}
                />
              </Grid> */}
              {selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AutoComplete
                    multiple={true}
                    hasSelectAllOption={false}
                    size="small"
                    id="selectSites"
                    name="sites"
                    label="Site"
                    options={sitesLookup.results}
                    isLoading={sitesLookup.isLoading}
                    fetchOptions={async () => await dispatch(fetchSitesLookup())}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                  id="selectSystemIds"
                  name="systemIDs"
                  label="TCN #"
                  options={updatedSystemIDOptions.results}
                  fetchOptions={() => {
                    fetchSystemIDOptions()
                  }}
                  isLoading={updatedSystemIDOptions.isLoading}
                />
              </Grid>
            </FormElementsWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Alert name="filterFormValidation" severity="error" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button type="submit" size="medium" fullWidth color="primary">
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button
                  size="medium"
                  fullWidth
                  color="inherit"
                  onClick={() => handleClearFilters(formikProps)}
                >
                  Clear
                </Button>
              </Grid>
              <CollapseFilter item xs={12} sm={12} md={12} lg={12}>
                <IconButton
                  aria-label="Collapse Filters"
                  color="primary"
                  onClick={() => handleClose()}
                  size="large"
                >
                  <ArrowDropUp fontSize="large" />
                </IconButton>
              </CollapseFilter>
            </Grid>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

StudyMessagesFilters.propTypes = {
  handleClose: PropTypes.func
}

export default StudyMessagesFilters
