import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { authCheckState, performLogout } from 'store/ducks/auth'
import ProtectionLayer from 'layouts/ProtectionLayer'
import { publicRoutes, bbkRoutes, patientRoutes } from 'routes'
import UserMessageBar from 'components/common/UserMessageBar'
import { closeUserMessageBar } from 'store/ducks/userMessageBar'
import NotFound from 'components/NotFound'
import Portal from 'components/private/studyList/Portal'
import Archived from 'components/private/archivedInstances/Archived'
import MainAppLayout from 'layouts/MainAppLayout'
import { LoadingIndicator } from 'components/common'
import PublicLayout from 'layouts/PublicLayout'
import documentRoutes from 'routes/documentRoutes'
import DocumentLayout from 'layouts/DocumentLayout'
import CrossInstanceReports from 'components/public/CrossInstanceReports'

const App = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthenticated, isPatient, pathname } = useSelector(store => store.auth)
  const { isLoading, loadingCriteria } = useSelector(store => store.userInteractions)

  const {
    verticalPosition,
    horizontalPosition,
    message,
    messageIsANode,
    autoHideDuration,
    isOpen,
    messageType
  } = useSelector(store => store.userMessageBar)

  useEffect(() => {
    dispatch(authCheckState(location.pathname))
  }, [])

  useEffect(() => {
    if (pathname !== '/') {
      navigate(pathname)
    }
  }, [pathname])

  const handleOnIdle = () => {
    if (isAuthenticated) {
      dispatch(performLogout(() => navigate('/')))
    }
  }

  useIdleTimer({
    timeout: 15 * (60 * 1000), // 15 minutes
    onIdle: handleOnIdle,
    debounce: 500
  })

  const getRouteComponentList = routesList => {
    const finalList = routesList.map((route, index) => {
      const RouteComponent = route.component
      if (route.children) {
        return (
          <Route key={`${route.to}-${index}`} path={route.to} element={<RouteComponent />}>
            {getRouteComponentList(route.children)}
          </Route>
        )
      }
      return <Route key={`${route.to}-${index}`} path={route.to} element={<RouteComponent />} />
    })
    return finalList
  }

  return (
    <>
      {isLoading.length ? <LoadingIndicator loadingCriteria={loadingCriteria} /> : ''}
      <Routes>
        <Route path="/" element={<ProtectionLayer />}>
          {isPatient
            ? (
              <Route path="instance" element={<MainAppLayout />}>
                <Route path=":instanceID">{getRouteComponentList(patientRoutes)}</Route>
              </Route>
            )
            : (
              <>
                <Route path="portal" element={<Portal />} />
                <Route path="archived" element={<Archived/>} />
                <Route path="instance" element={<MainAppLayout />}>
                  <Route path=":instanceID">{getRouteComponentList(bbkRoutes)}</Route>
                </Route>
              </>
            )}
        </Route>

        <Route path="/" element={<PublicLayout />}>
          {getRouteComponentList(publicRoutes)}
        </Route>
        <Route path="/doc" element={<DocumentLayout />}>
          {getRouteComponentList(documentRoutes)}
        </Route>
        <Route path="/crossInstanceReports" element={<CrossInstanceReports />}>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* Setup notifications */}
      <UserMessageBar
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
        message={message}
        messageIsANode={messageIsANode}
        autoHideDuration={autoHideDuration}
        isOpen={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'clickaway') {
            dispatch(closeUserMessageBar())
          }
        }}
        messageType={messageType}
      />
    </>
  )
}

export default App
