import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  convertDraftToHTML,
  copyObject,
  displayDate,
  displayTime,
  extractArrayOfIdsForEachKey
} from 'components/helper/utility'
import { EditorState } from 'draft-js'
import { fetchBadgesPerModule, showError, showSuccess } from '../application'
import { setLoading } from '../userInteractions'
import {
  createStudyMessage,
  getStudyMessages,
  updateStudyMessage,
  getStudyMessageByID,
  getUserStudyMessageByID,
  getUsersBySite,
  getDistributionList,
  updateUserStudyMessageProperty,
  replyToStudyMessage,
  updateStudyMessageProperty,
  getSystemIDsByCommunicationTab,
  flagUserStudyMessage
} from 'services/studyMessages'
import {
  postAttachments,
  getSystemIDs,
  getSitesByCountries,
  downloadAttachmentByID
} from 'services/common'
import {
  communicationTabIDs,
  communicationListType,
  messageSendCategoryIDs,
  sendToOptionsFor
} from 'components/helper/constants/mailbox'
import { fetchMessagesSendCategory } from '../common'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'

export const setIsCompleted = createAction('studyMessages/setIsCompleted')
export const setMessagesList = createAction('studyMessages/setMessagesList')
export const setComposeMessageModal = createAction('studyMessages/setComposeMessageModal')
export const setFilters = createAction('studyMessages/setFilters')
export const setComposeMessage = createAction('studyMessages/setComposeMessage')
export const setIsResponding = createAction('studyMessages/setIsResponding')
export const setSystemIDsBySite = createAction('studyMessages/setSystemIDsBySite')
export const setSitesByCountries = createAction('studyMessages/setSitesByCountries')
export const setUsersBySite = createAction('studyMessages/setUsersBySite')
export const resetStudyMessage = createAction('studyMessages/resetStudyMessage')
export const setDistributionList = createAction('studyMessages/setDistributionList')
export const setShowDistributionList = createAction('studyMessages/setShowDistributionList')
export const setStudyMessage = createAction('studyMessages/setStudyMessage')
export const setSystemIDsByCommunicationTab = createAction(
  'studyMessages/setSystemIDsByCommunicationTab'
)
export const setSelectedMessage = createAction('studyMessages/setSelectedMessage')
export const setDeleteStudyMessageConfirmation = createAction(
  'studyMessages/setDeleteStudyMessageConfirmation'
)

// eslint-disable-next-line complexity
export const fetchStudyMessages =
  (initialLoad, filteringCriteria, patientId, selectedCommunicationTabId) =>
    async (dispatch, getState) => {
      try {
        dispatch(setLoading(true))
        dispatch(setMessagesList({ isLoading: true }))
        const { isCompleted } = getState().studyMessages
        const { isPatient } = getState().auth
        const listType = communicationListType[selectedCommunicationTabId]
        const { patientInformation } = getState().patientMainWrapper
        let payload = { isCompleted, sendTos: listType }

        // if logged in as bbk or site and trying to fetch study messages for patients
        if (
          !isPatient &&
        selectedCommunicationTabId === communicationTabIDs.conciergeMsgsForPatients
        ) {
          payload = {
            ...payload,
            userId: patientInformation.userId ? patientInformation.userId : patientId,
            // isPatient is true to request messages sent to this patient only
            isPatient: true
          }
        }

        if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites) {
          payload = {
            ...payload,
            isWithSite: true
          }
        } else if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients) {
          payload = {
            ...payload,
            isWithSite: false
          }
        }

        if (filteringCriteria) {
          const {
            sites,
            categories,
            // programs,
            systemIDs
          } = filteringCriteria
          dispatch(setFilters(filteringCriteria))
          const multiSelectDropDowns = {
            uniqueSiteProtocols: { array: sites, propertyToExtract: 'alternateIdentifier' },
            // programs,
            systemIDs:
              selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites
                ? { array: systemIDs, propertyToExtract: 'displayText' }
                : systemIDs,
            ...(selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients
              ? { patientCategories: categories }
              : { categories })
          }
          payload = {
            ...payload,
            ...extractArrayOfIdsForEachKey(multiSelectDropDowns),
            isScheduledMessage: filteringCriteria.isScheduledMessage ? true : null,
            isFlagged: filteringCriteria.isFlagged ? true : null
          }
        }

        const { data } = await getStudyMessages(payload)
        if (data.length) {
          dispatch(
            setMessagesList({
              results: data.map(d => ({
                ...d,
                isViewed: d.isViewed ? d.isViewed : false,
                distributionDate: displayDate(d.distributionDate),
                distributionTime: displayTime(d.distributionDate),
                createdDate: displayDate(d.createdOn),
                createdTime: displayTime(d.createdOn),
                isStudyMessage: d.isStudyMessage && d.isStudyMessage,
                category: [
                  communicationTabIDs.conciergeMsgsWithPatients,
                  communicationTabIDs.conciergeMsgsForPatients
                ].includes(selectedCommunicationTabId)
                  ? d.patientCategory?.displayText
                  : d.category?.displayText,
                program: d.program?.displayText,
                senderPolicy: (isPatient && d.senderPolicy?.id === 13) ? '' : d.senderPolicy?.displayText, // if the sendPolicy is patient(13), do not show it for patient
              })),
              totalCount: data.length
            })
          )
          if (initialLoad && data.length) {
            dispatch(setSelectedMessage(data[0]))
            // this lookup call is done here because in the next call(fetchStudyMessageByID),
            // we will refer to this lookup array for showing sendTo value on the UI
            await dispatch(fetchMessagesSendCategory(sendToOptionsFor[selectedCommunicationTabId]))

            await dispatch(fetchStudyMessageByID(data[0], selectedCommunicationTabId))
            // if logged in as bbk or site and trying to fetch study messages for patients
            // We should'nt execute this, when user is concierge and on an individual patient mailbox section
            if ((!isPatient && selectedCommunicationTabId !== communicationTabIDs.conciergeMsgsForPatients) || isPatient) {
              await dispatch(onUserViewMessage(data[0]))
            }
          }
        } else {
          dispatch(setMessagesList({ results: [], totalCount: 0 }))
          dispatch(setSelectedMessage({}))
        }
      } catch {
        dispatch(setMessagesList({ results: [], totalCount: 0 }))
        dispatch(setSelectedMessage({}))
        dispatch(
          showError(
            'There was some issue while trying filter messages list. Please try selecting other filters.'
          )
        )
      }

      dispatch(setMessagesList({ isLoading: false }))
      dispatch(setLoading(false))
    }

export const fetchStudyMessageByID = (selectedItem, selectedCommunicationTabId) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { messagesSendCategory } = getState().common
    const { isPatient } = getState().auth
    let finalRes
    if (selectedItem.isStudyMessage) {
      finalRes = await getStudyMessageByID(selectedItem.id)
    } else {
      finalRes = await getUserStudyMessageByID(
        selectedItem.parentUserStudyMessageId
          ? selectedItem.parentUserStudyMessageId
          : selectedItem.id
      )
    }
    if (finalRes?.data) {
      const studyMessagesThread = Array.isArray(finalRes.data) ? finalRes.data : [finalRes.data]

      const finalMessageConversation = studyMessagesThread.map(message => {
        let sendToObject
        // From the messageSendCategory lookup, get the object{id: , displayText}, that matches with selected message's sendTo
        if (message.receiverFullName) {
          sendToObject = {
            id: message.receiverFullName,
            displayText: message.receiverFullName
          }
        } else if (message.sendTo) {
          // check when there is no sendTo or it is 0
          sendToObject = messagesSendCategory.results.find(
            sendCategory => sendCategory.id === message.sendTo
          )
        } else {
          // this code is executed when sendTo is 0 and no receiverFullName
          // i.e., this studyMessage is sent by site/patient user to all bbk users
          sendToObject = messagesSendCategory.results.find(
            sendCategory => sendCategory.id === messageSendCategoryIDs.bbk
          )
        }

        return {
          ...message,
          sendTo: sendToObject,
          from: message.senderFullName,
          fromPolicy: (isPatient && message.senderPolicy?.id === 13) ? '' : message.senderPolicy?.displayText, // if the sendPolicy is patient(13), do not show it for patient
          distributionDate: displayDate(message.distributionDate),
          distributionTime: displayTime(message.distributionDate),
          createdDate: displayDate(message.createdOn),
          createdTime: displayTime(message.createdOn),
          systemId: message.systemId,
          userEntities: message.userEntities ? message.userEntities : [],
          attachmentsToBeDeleted: [],
          category: [
            communicationTabIDs.conciergeMsgsWithPatients,
            communicationTabIDs.conciergeMsgsForPatients
          ].includes(selectedCommunicationTabId)
            ? message.patientCategory
            : message.category
        }
      })
      dispatch(setStudyMessage(finalMessageConversation))
    }
  } catch {
    dispatch(setStudyMessage(initialState.studyMessage))
    dispatch(
      showError(
        'There was some error while trying to fetch user study message. Please try refreshing your page.'
      )
    )
  }
  dispatch(setLoading(false))
}

export const fetchDistributionList = studyMessageId => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getDistributionList(studyMessageId)
    dispatch(
      setDistributionList({
        results: data.map(d => ({
          ...d,
          readUnread: d.isViewed ? 'Read' : 'Unread'
        })),
        totalCount: data.length
      })
    )

    dispatch(setShowDistributionList(true))
  } catch {
    dispatch(
      showError(
        'There was some error when trying to fetch Sent To list. Please try again after refreshing the page.'
      )
    )
  }

  dispatch(setLoading(false))
}

export const onUserViewMessage = selectedItem => async (dispatch, getState) => {
  const { messagesList } = getState().studyMessages
  const { isPatient } = getState().auth
  try {
    if (!selectedItem.isViewed && !selectedItem.isStudyMessage) {
      await updateUserStudyMessageProperty(selectedItem.id, 'isViewed', true)
      // on success, find the list item and update the isViewed property to true.
      const newMessagesList = [...messagesList.results]

      const itemsIndex = messagesList.results.findIndex(a => a.id === selectedItem.id)

      newMessagesList[itemsIndex] = { ...newMessagesList[itemsIndex], isViewed: true }

      dispatch(setMessagesList({ results: newMessagesList, totalCount: messagesList.totalCount }))
      if (isPatient) {
        await dispatch(fetchBadgesForPatientSection())
      } else {
        await dispatch(fetchBadgesPerModule())
      }
    }
  } catch {
    dispatch(showError('There was some error, please try refreshing your page.'))
  }
}

export const toggleIsCompletedFlag =
  (patientId, selectedCommunicationTabId) => async (dispatch, getState) => {
    try {
      const { selectedMessage } = getState().studyMessages
      if (selectedMessage.isStudyMessage) {
        await updateStudyMessageProperty(selectedMessage.id, '/isCompleted', !selectedMessage.isCompleted)
      } else {
        await updateUserStudyMessageProperty(
          selectedMessage.id,
          '/isCompleted',
          !selectedMessage.isCompleted
        )
      }
      await dispatch(fetchStudyMessages(true, false, patientId, selectedCommunicationTabId))
      if (selectedMessage.isCompleted) {
        dispatch(showSuccess('Message marked as active.'))
      } else {
        dispatch(showSuccess('Message marked as complete.'))
      }
    } catch (e) {
      dispatch(showError('There was some network error. Please refresh your page.', e))
    }
  }

export const flagStudyMessage =
  (flaggingMessage, patientId, selectedCommunicationTabId) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters, selectedMessage } = getState().studyMessages
      if (flaggingMessage.isStudyMessage) {
        await updateStudyMessageProperty(
          flaggingMessage.id,
          '/isFlagged',
          !flaggingMessage.isFlagged
        )
      } else {
        const payload = {
          userStudyMessageId: flaggingMessage.parentUserStudyMessageId
            ? flaggingMessage.parentUserStudyMessageId
            : flaggingMessage.id,
          isFlagged: !flaggingMessage.isFlagged
        }
        await flagUserStudyMessage(payload)
      }

      await dispatch(fetchStudyMessages(false, filters, patientId, selectedCommunicationTabId))
      dispatch(makeASelectionAfterListCall())
      await dispatch(fetchStudyMessageByID(selectedMessage, selectedCommunicationTabId))

      if (flaggingMessage.isFlagged) {
        dispatch(showSuccess('Message un-flagged.'))
      } else {
        dispatch(showSuccess('Message flagged.'))
      }
    } catch {
      dispatch(showError('There was some network error. Please refresh your page.'))
    }
    dispatch(setLoading(false))
  }

export const clearFilters = () => async dispatch => {
  dispatch(setFilters({ ...initialState.filters }))
}

// eslint-disable-next-line max-lines-per-function
export const publishMessage =
  (values, patientId, selectedCommunicationTabId, onSubmit) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { composeMessage, selectedMessage } = getState().studyMessages
      const { loggedInAsSite } = getState().auth
      const {
        sendTo,
        category,
        // program,
        systemId,
        userEntities,
        countries,
        siteProtocols,
        subject,
        body,
        attachments,
        distributionDate
      } = values

      const multiSelectDropDowns = {
        countryIds: countries,
        userEntityIds: userEntities,
        uniqueSiteProtocolIds: {
          array: siteProtocols,
          propertyToExtract: 'alternateIdentifier'
        }
      }

      let formattedPayload = {
        subject,
        body,
        // programId: program.id,
      }

      if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites) {
        if (loggedInAsSite) {
          formattedPayload = {
            ...formattedPayload,
            systemId: systemId?.displayText,
            sendTo: messageSendCategoryIDs.bbk,
            categoryId: category.id,
            distributionDate
          }
        } else {
          formattedPayload = {
            ...formattedPayload,
            ...extractArrayOfIdsForEachKey(multiSelectDropDowns),
            systemId: systemId?.displayText,
            categoryId: category.id,
            sendTo,
            distributionDate
          }
        }
      } else if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients) {
        formattedPayload = {
          ...formattedPayload,
          sendTo: messageSendCategoryIDs.patient,
          userSiteIds: [systemId.id],
          distributionDate,
          systemId: systemId.displayText,
          patientCategoryId: category.id
        }
      } else if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsForPatients) {
        formattedPayload = {
          ...formattedPayload,
          sendTo: messageSendCategoryIDs.bbk,
          patientCategoryId: category.id
        }
      }
      let attachmentsCallSuccess = true

      if (attachments && attachments.length > 0) {
        const newlyUploadedAttachments = attachments.filter(a => !a.id)

        if (newlyUploadedAttachments.length > 0) {
          //  Create an object of formData
          const formData = new FormData()

          newlyUploadedAttachments.forEach(f => {
            // Update the formData object
            formData.append('files', f)
          })

          const { data } = await postAttachments(formData)
          if (data.length > 0) {
            const existingFileIds = attachments.filter(f => f.id).map(f => f.id)
            formattedPayload = {
              ...formattedPayload,
              attachmentIds: [...existingFileIds, ...data]
            }
          } else {
            attachmentsCallSuccess = false
          }
        }
      }

      if (attachmentsCallSuccess) {
        if (composeMessage.id) {
          await updateStudyMessage({
            ...formattedPayload,
            studyMessageId: composeMessage.id,
            attachmentsToBeDeleted: values.attachmentsToBeDeleted
          })

          dispatch(setStudyMessage(initialState.studyMessage))
          dispatch(setComposeMessageModal(false))

          await dispatch(fetchStudyMessages(false, false, patientId, selectedCommunicationTabId))
          // this is to check if user has updated the subject, in that case send the old subejct for comparision and finding the correct list item
          if (formattedPayload.subject !== composeMessage.subject) {
            dispatch(makeASelectionAfterListCall(composeMessage.subject))
          } else {
            dispatch(makeASelectionAfterListCall())
          }

          await dispatch(fetchStudyMessageByID(selectedMessage, selectedCommunicationTabId))
          dispatch(showSuccess('Message updated successfully.'))
        } else {
          if (displayDate(formattedPayload.distributionDate) === displayDate(new Date())) {
            // the reason why time is included in the non-scheduled messages is because, the created/distributed time will show on the UI
            await createStudyMessage(formattedPayload)
            dispatch(showSuccess('Message sent successfully.'))
          } else {
            // for the sceduled messages, backend do not need time from client
            await createStudyMessage({
              ...formattedPayload,
              distributionDate: displayDate(formattedPayload.distributionDate)
            })
            dispatch(showSuccess('Message scheduled successfully.'))
          }
          dispatch(setComposeMessageModal(false))
          await dispatch(
            await fetchStudyMessages(!!selectedMessage.id, false, patientId, selectedCommunicationTabId)
          )
        }
        // this will close the form after success
        onSubmit && onSubmit()
      } else {
        dispatch(
          showError(
            'There was some issue while trying to upload the attachments. Please check your file and try again.'
          )
        )
      }
    } catch {
      dispatch(
        showError(
          'There was some issue while sending a message. Please try again or contact IT support if you continue to see this issue.'
        )
      )
    }
    dispatch(setLoading(false))
  }

export const replyToMessage =
  (values, patientId, selectedCommunicationTabId) => async (dispatch, getState) => {
    try {
      const { studyMessage } = getState().studyMessages
      const { loggedInAsSite, isPatient } = getState().auth
      const { attachments, body } = values
      dispatch(setLoading(true))

      let replyingToMessage

      if (loggedInAsSite || isPatient) {
        // filter out the messages that are from concierge
        replyingToMessage = studyMessage.filter(message => [1, 2, 12].includes(message.senderPolicyId))[0]
      } else {
        // this means bbk is logged in
        // filter out the messages that are from patient or site(based on the tab value the user is on - it can be withSite or withPatients)
        if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients) {
          // this means user is on withPatientsTab
          replyingToMessage = studyMessage.filter(message => message.senderPolicyId === 13)[0]
        } else {
          // this means user is on with sites tab
          replyingToMessage = studyMessage.filter(message => message.senderPolicyId === 5)[0]
        }
      }

      let formattedPayload = {
        subject: replyingToMessage.subject,
        body,
        studyMessageId: replyingToMessage.studyMessageId,
        parentUserStudyMessageId: replyingToMessage.parentUserStudyMessageId
          ? replyingToMessage.parentUserStudyMessageId
          : replyingToMessage.id,
        receiverId: replyingToMessage.senderId,
        receiverFullName: replyingToMessage.senderFullName,
        receiverPolicyId: replyingToMessage.senderPolicy.id,
        systemId: replyingToMessage.systemId,
        sponsorSiteId: replyingToMessage.sponsorSiteId
      }
      let attachmentCallSuccess = true
      if (attachments && attachments.length > 0) {
        const newlyUploadedAttachments = attachments.filter(a => !a.id)

        if (newlyUploadedAttachments.length > 0) {
          //  Create an object of formData
          const formData = new FormData()

          newlyUploadedAttachments.forEach(f => {
            // Update the formData object
            formData.append('files', f)
          })

          const { data } = await postAttachments(formData)
          if (data.length > 0) {
            const existingFileIds = attachments.filter(f => f.id).map(f => f.id)
            formattedPayload = {
              ...formattedPayload,
              attachmentIds: [...existingFileIds, ...data]
            }
          } else {
            attachmentCallSuccess = false
          }
        }
      }
      if (attachmentCallSuccess) {
        await replyToStudyMessage(formattedPayload)

        await dispatch(fetchStudyMessages(false, false, patientId, selectedCommunicationTabId))
        dispatch(makeASelectionAfterListCall())
        // after reply is success, the message must be fetched again for latest data
        await dispatch(fetchStudyMessageByID(studyMessage[0], selectedCommunicationTabId))
        dispatch(setIsResponding(false))
      } else {
        dispatch(
          showError(
            'There was some issue while trying to upload the attachments. Please check your file and try again.'
          )
        )
      }
    } catch (e) {
      dispatch(showError('There was some issue while replying, please try again.', e))
    }

    dispatch(setLoading(false))
  }

export const makeASelectionAfterListCall = oldSubject => (dispatch, getState) => {
  // get latest state of the messagesList
  const { messagesList, selectedMessage } = getState().studyMessages
  // Find the index of the messages that user selected
  if (oldSubject) {
    // when message edited, user might update the subject
    const selectedMessageIndex = messagesList.results.findIndex(
      message => `${message.createdOn} ${oldSubject}` === `${selectedMessage.createdOn} ${selectedMessage.subject}`
    )
    // find the item in the messages list using above index so that selection is made again
    dispatch(setSelectedMessage(messagesList.results[selectedMessageIndex]))
  } else {
    const selectedMessageIndex = messagesList.results.findIndex(
      message => `${message.createdOn} ${message.subject}` === `${selectedMessage.createdOn} ${selectedMessage.subject}`
    )
    // find the item in the messages list using above index so that selection is made again
    dispatch(setSelectedMessage(messagesList.results[selectedMessageIndex]))
  }
}

export const fetchSystemIDsBySite = siteProtocolID => async dispatch => {
  dispatch(setSystemIDsBySite({ isLoading: true }))
  await getSystemIDs(siteProtocolID)
    .then(response => {
      dispatch(setSystemIDsBySite({ isLoading: false, results: response.data }))
    })
    .catch(() => {
      dispatch(setSystemIDsBySite({ isLoading: false }))
      dispatch(
        showError(
          'There was issue fetching system IDs. Please try refreshing your page or come back after sometime.'
        )
      )
    })
}

export const onClearOfCountriesDropdown = () => async dispatch => {
  dispatch(setSitesByCountries({ results: [] }))
  dispatch(setSystemIDsBySite({ results: [] }))
  dispatch(setUsersBySite({ results: [] }))
}

export const fetchSystemByCommunicationTab = selectedCommunicationTabId => async dispatch => {
  dispatch(setSystemIDsByCommunicationTab({ isLoading: true }))
  await getSystemIDsByCommunicationTab(
    selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites,
    selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients
  )
    .then(res => {
      dispatch(setSystemIDsByCommunicationTab({ results: res.data, isLoading: false }))
    })
    .catch(() => {
      dispatch(setSystemIDsByCommunicationTab({ isLoading: false }))
      dispatch(
        showError(
          'There was issue fetching system IDs. Please try refreshing your page or come back after sometime.'
        )
      )
    })
}

export const fetchSitesByCountries = countriesLookup => async dispatch => {
  dispatch(setSitesByCountries({ isLoading: true }))
  await getSitesByCountries(countriesLookup.map(country => country.id).toString())
    .then(res => {
      dispatch(setSitesByCountries({ results: res, isLoading: false }))
    })
    .catch(() => {
      dispatch(setSitesByCountries({ isLoading: false }))
      dispatch(
        showError('There was some issue while trying to fetch sites. Trying refreshing your page.')
      )
    })
}

export const fetchUsersBySite = siteProtocols => async dispatch => {
  dispatch(setUsersBySite({ isLoading: true }))
  await getUsersBySite(siteProtocols.map(site => site.id).toString())
    .then(res => {
      dispatch(setUsersBySite({ results: res.data, isLoading: false }))
    })
    .catch(() => {
      dispatch(setUsersBySite({ isLoading: false }))
      dispatch(
        showError('There was some issue while trying to fetch sites. Trying refreshing your page.')
      )
    })
}

export const resetMessages = selectedCommunicationTabId => async dispatch => {
  let updatedInitialValue = { ...initialState.composeMessage }
  const myDate = new Date()
  // the communication tabs are shown based on user role
  // for example, when bbk user logged in, two tabs - Concierge Messages with sites and Messages with patients
  // for site user just one tab - Concierge Messages
  // Here we will set the initial value of Create Message form based on what tab the user is on
  if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites) {
    updatedInitialValue = {
      ...updatedInitialValue,
      sendTo: messageSendCategoryIDs.allActiveUsers,
      distributionDate: myDate.toLocaleString('en-us')
    }
  } else if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients) {
    updatedInitialValue = {
      ...updatedInitialValue,
      distributionDate: myDate.toLocaleString('en-us')
    }
  }

  dispatch(setComposeMessage(updatedInitialValue))
}

export const downloadAttachment = attachment => async dispatch => {
  try {
    dispatch(setLoading(true))

    await downloadAttachmentByID(attachment)
  } catch (e) {
    dispatch(showError('There was some issue with downloading the attachment.', e))
  }

  dispatch(setLoading(false))
}

export const deleteStudyMessage =
  (selectedMessage, patientId, selectedCommunicationTabId) => async dispatch => {
    dispatch(setLoading(true))
    try {
      await updateStudyMessageProperty(selectedMessage.id, 'isVisible', false)
      await dispatch(fetchStudyMessages(true, false, patientId, selectedCommunicationTabId))
      dispatch(setDeleteStudyMessageConfirmation(false))
      dispatch(showSuccess('Message deleted successfully.'))
    } catch {
      dispatch(
        showError('There was error while trying to fetch distributed list. Please try again later.')
      )
    }

    dispatch(setLoading(false))
  }

const initialState = {
  isCompleted: false,
  messagesList: { isLoading: false, results: [], totalCount: 0 },
  filters: {
    categories: [],
    // programs: [],
    sites: [],
    systemIDs: [],
    isScheduledMessage: false,
    isFlagged: false
  },
  composeMessageModal: false,
  isResponding: false,
  composeMessage: {
    sendTo: '',
    countries: [],
    siteProtocols: [],
    category: null,
    program: null,
    systemId: null,
    subject: '',
    body: convertDraftToHTML(EditorState.createEmpty()),
    attachments: [],
    distributionDate: '',
    userEntities: []
  },
  studyMessage: [
    {
      sendTo: '',
      countries: [],
      siteProtocols: [],
      category: null,
      program: null,
      systemId: null,
      subject: '',
      body: convertDraftToHTML(EditorState.createEmpty()),
      attachments: [],
      distributionDate: ''
    }
  ],
  selectedMessage: {},
  distributionList: { results: [], totalCount: 0 },
  showDistributionList: false,
  systemIDsBySite: { isLoading: false, results: [] },
  sitesByCountries: { isLoading: false, results: [] },
  systemIDsByCommunicationTab: { isLoading: false, results: [] },
  usersBySite: { isLoading: false, results: [] },
  deleteStudyMessageConfirmation: false
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setIsCompleted, (state, { payload }) => {
      state.isCompleted = payload
    })
    .addCase(setMessagesList, (state, { payload }) => {
      state.messagesList = { ...state.messagesList, ...payload }
    })
    .addCase(setComposeMessageModal, (state, action) => {
      state.composeMessageModal = action.payload
    })
    .addCase(setStudyMessage, (state, action) => {
      state.studyMessage = action.payload
    })
    .addCase(setSelectedMessage, (state, action) => {
      state.selectedMessage = action.payload
    })
    .addCase(setFilters, (state, action) => {
      state.filters = action.payload
    })
    .addCase(setComposeMessage, (state, action) => {
      state.composeMessage = action.payload
    })
    .addCase(setIsResponding, (state, action) => {
      state.isResponding = action.payload
    })
    .addCase(setSystemIDsBySite, (state, action) => {
      state.systemIDsBySite = { ...state.systemIDsBySite, ...action.payload }
    })
    .addCase(setSitesByCountries, (state, action) => {
      state.sitesByCountries = { ...state.sitesByCountries, ...action.payload }
    })
    .addCase(setUsersBySite, (state, action) => {
      state.usersBySite = { ...state.usersBySite, ...action.payload }
    })
    .addCase(setDistributionList, (state, action) => {
      state.distributionList = { ...state.distributionList, ...action.payload }
    })
    .addCase(setShowDistributionList, (state, action) => {
      state.showDistributionList = action.payload
    })
    .addCase(setSystemIDsByCommunicationTab, (state, action) => {
      state.systemIDsByCommunicationTab = {
        ...state.systemIDsByCommunicationTab,
        ...action.payload
      }
    })
    .addCase(setDeleteStudyMessageConfirmation, (state, action) => {
      state.deleteStudyMessageConfirmation = action.payload
    })
    .addCase(resetStudyMessage, state => {
      copyObject(state, initialState)
    })
})
