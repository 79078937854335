import Dashboard from 'components/private/patientExperience/Dashboard'
import ResourceCenter from 'components/private/resourceCenter/ResourceCenter'
import Configuration from 'components/private/configuration/Configuration'
import MyAccount from 'components/private/myAccount/MyAccount'
import Logout from 'components/private/Logout'
import PatientMainWrapper from 'components/private/patientSection/PatientMainWrapper'
import CommunicationCenter from 'components/private/communicationCenter/CommunicationCenter'
import SiteDashboard from 'components/private/siteManagement/SiteDashboard'
import SiteDetails from 'components/private/siteManagement/SiteDetails'
import SmartAlerts from 'components/private/smartAlerts/SmartAlerts'
import {
  Mail,
  Settings as MuiSettingsIcon,
  Home,
  Dashboard as MuiDashboardIcon,
  Lock,
  AccountCircle,
  Medication as MedicationIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Notifications as NotificationsIcon,
  InsertChart,
  AdsClick,
} from '@mui/icons-material/'

import {
  communicationCenterTabs,
  myAccountTabs,
  patientTabs,
  configurationTabs
}
  from './navigationRoutes'
import Reports from 'components/private/reports/Reports'
import MediaPlans from 'components/private/mediaPlans/MediaPlans'
import MediaPlanDetails from 'components/private/mediaPlans/MediaPlanDetails'
import ECDirectoryDashboard from 'components/private/siteManagement/ecDirectory/ECDirectoryDashboard'
import ECDirectoryDetails from 'components/private/siteManagement/ecDirectory/ECDirectoryDetails'

const bbkRoutes = [
  {
    id: 'PatientList',
    label: 'Home',
    component: Dashboard,
    icon: (
      <>
        <Home fontSize="small" />
      </>
    ),
    to: 'dashboard',
  },
  {
    id: 'mailBox',
    label: 'Mailbox',
    component: CommunicationCenter,
    icon: (
      <>
        <Mail fontSize="small" />
      </>
    ),
    to: 'communications',
    children: communicationCenterTabs(false),
  },
  {
    id: 'resources',
    label: 'Resources',
    component: ResourceCenter,
    icon: (
      <>
        <MuiDashboardIcon fontSize="small" />
      </>
    ),
    to: 'resource-center',
  },
  {
    id: 'reports',
    label: 'Reports',
    component: Reports,
    icon: (
      <>
        <InsertChart fontSize="small" />
      </>
    ),
    to: 'reports',
  },
  {
    id: 'siteManagement',
    label: 'Site Management',
    component: SiteDashboard,
    icon: (
      <>
        <MedicationIcon fontSize="small" />
      </>
    ),
    to: 'site-management',
  },
  {
    id: 'smartAlerts',
    label: 'Smart Alerts',
    component: SmartAlerts,
    icon: (
      <>
        <NotificationsIcon fontSize="small" />
      </>
    ),
    to: 'smart-alerts',
  },
  {
    id: 'mediaPlan',
    label: 'Media Plans',
    component: MediaPlans,
    icon: (
      <>
        <AdsClick fontSize="small" />
      </>
    ),
    to: 'media-plans',
  },
  {
    label: '',
    component: MediaPlanDetails,
    to: 'media-plans/:mediaPlanId',
  },
  {
    id: 'configuration',
    label: 'Configure',
    component: Configuration,
    icon: (
      <>
        <MuiSettingsIcon fontSize="small" />
      </>
    ),
    to: 'configure',
    children: configurationTabs,
    type: 'bar',
  },
  {
    id: 'myAccount',
    label: 'My Account',
    component: MyAccount,
    icon: (
      <>
        <AccountCircle fontSize="small" />
      </>
    ),
    to: 'myaccount',
    children: myAccountTabs,
  },
  {
    id: 'studyList',
    label: 'Study List',
    component: PatientMainWrapper,
    icon: (
      <>
        <FormatListBulletedIcon fontSize="small" />
      </>
    ),
    to: '../../portal',
  },
  {
    label: '',
    component: ECDirectoryDashboard,
    to: 'ec-directory',
  },
  {
    label: '',
    component: ECDirectoryDetails,
    to: 'ec-details/:encrypttedEthicID',
  },
  {
    label: '',
    component: SiteDetails,
    to: 'site-details/:siteId',
  },
  {
    id: 'patientDashboard',
    label: '',
    component: PatientMainWrapper,
    to: 'patient-dashboard/:patientId',
    children: patientTabs(false),
  },

  {
    id: 'logout',
    label: 'Logout',
    component: Logout,
    icon: (
      <>
        <Lock fontSize="small" />
      </>
    ),
    to: 'logout',
  },
]

export default bbkRoutes
