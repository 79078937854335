/* eslint-disable max-lines-per-function */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'

import {
  TextBoxOuterLabel,
  AutoCompleteWithLabel,
  Button,
} from 'components/common'
import {
  fetchCountriesLookup,
  fetchEthicsType
} from 'store/ducks/common'
import {
  submitECDetails,
  setAddEditECFlow,
} from 'store/ducks/siteManagement/ecDirectory/ecManagement'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import StatesDropdown from 'components/common/form/StatesDropdown'
import { useParams } from 'react-router-dom'
import SitesByCountryDropdown from 'components/common/form/SitesByCountryDropdown'
import PropTypes from 'prop-types'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`
const validationSchema = validationMapper([
  { id: 'ethicsCommiteeName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'ethicsCommiteeType', isResponseRequired: true, answerType: formElements.dropdownMenu },
  { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
  {
    id: 'state',
    isResponseRequired: false,
    answerType: formElements.dropdownMenu,
    dependency: {
      elements: ['country'],
      callBack: countries => countries?.length
    }
  },
  {
    id: 'associatedSiteID',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
    dependency: {
      elements: ['country'],
      callBack: countries => countries?.length
    }
  },
])

const AddEditECDetails = ({ refreshSiteDetails, accordionExpanded }) => {
  const dispatch = useDispatch()
  const { encrypttedEthicID = null } = useParams()
  const {
    countriesLookup,
    ethicsTypesLookup
  } = useSelector(
    store => store.common
  )
  const {
    addEditECDetails, initialECDetails
  } = useSelector(store => store.ecManagement)

  return (
    <Formik
      initialValues={refreshSiteDetails ? initialECDetails : addEditECDetails}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={values => {
        dispatch(submitECDetails({ ...values, encrypttedEthicID, accordionExpanded }))
      }}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextBoxOuterLabel
                size="small"
                required
                id="ethicsCommiteeName"
                label="Ethics Committee Name"
                name="ethicsCommiteeName"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <AutoCompleteWithLabel
                id="ethicsCommiteeType"
                required
                label="Type"
                name="ethicsCommiteeType"
                options={ethicsTypesLookup.results}
                isLoading={ethicsTypesLookup.isLoading}
                fetchOptions={() => dispatch(fetchEthicsType())}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoCompleteWithLabel
                  id="country"
                  required
                  label="Country"
                  name="country"
                  options={countriesLookup.results}
                  isLoading={countriesLookup.isLoading}
                  fetchOptions={() => dispatch(fetchCountriesLookup())}
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <StatesDropdown
                  id="state"
                  label="State / Province"
                  name="state"
                  size="medium"
                  selectedCountry={values?.country?.id}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            </>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <SitesByCountryDropdown
                multiple={true}
                hasSelectAllOption={true}
                id="associatedSiteID"
                label="Site Associations"
                name="associatedSiteID"
                size="medium"
                selectedCountry={values?.country?.id}
                outerLabel={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ActionWrapper>
                <Button size="large" fullWidth={false} color="primary" type="submit">
                  Save
                </Button>
                &ensp;&ensp;
                <Button
                  size="large"
                  color="inherit"
                  fullWidth={false}
                  onClick={() => dispatch(setAddEditECFlow(false))}
                >
                  Cancel
                </Button>
              </ActionWrapper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

AddEditECDetails.propTypes = {
  refreshSiteDetails: PropTypes.bool,
  accordionExpanded: PropTypes.any
}
export default AddEditECDetails
