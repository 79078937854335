import React from 'react'
import { PageHeader } from 'components/common'
import { Paper, Typography } from '@mui/material'
import { TopNavigationBar } from 'components/common/navigation'
import { useRestriction } from 'permissions'
import { Outlet } from 'react-router-dom'
import { communicationCenterTabs } from 'routes/navigationRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { communicationCenterModuleNames } from 'components/helper/constants/badging'
import { resetStudyMessage } from 'store/ducks/communicationCenter/studyMessages'
import { fetchBadgesPerModule } from 'store/ducks/application'

const CommunicationCenter = () => {
  const dispatch = useDispatch()
  const { loggedInAsSite } = useSelector(store => store.auth)

  const { badgesPerModule } = useSelector(store => store.application)
  // argument loggedInAsSite is passed to change the label of the tab
  const newTabs = communicationCenterTabs(loggedInAsSite).filter(
    tab => useRestriction('read', tab.to) && tab
  )

  const finalTabs = newTabs.map(tab => ({
    ...tab,
    badgeCount: badgesPerModule.results.mailBox?.list.filter(
      badge => badge.name === communicationCenterModuleNames(loggedInAsSite)[tab.to]
    )[0]?.count
  }))

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Mailbox</Typography>}
        actionContent={<></>}
        expandContent={true}
        content={
          <TopNavigationBar
            buttons={finalTabs}
            bar={true}
            changeTab={async () => {
              await dispatch(resetStudyMessage())
              await dispatch(fetchBadgesPerModule())
            }}
          />
        }
        bar={false}
      />
      <Paper elevation={0}>
        <Outlet />
      </Paper>
    </>
  )
}

export default CommunicationCenter
