import { createReducer, createAction } from '@reduxjs/toolkit'
import {
  addConfigConciergePrescreenerQuestion,
  addConfigSitePrescreenerQuestion,
  getConfigConciergePrescreenerQuestion,
  getConfigSitePrescreenerQuestion,
  updateIVRIntegration
} from 'services/configuration/participationProgress'
import { getSitesByCountries } from 'services/common'
import { fetchInstanceConfigDetails } from './instanceConfiguration'
import { showError, showSuccess } from '../application'
import { setLoading } from '../userInteractions'
import { questionType as questionTypeConstant } from 'components/helper/constants/participationProgress'
import { copyObject } from 'components/helper/utility'

export const setConciergePrescreeningQuestions = createAction('participationProgressConfiguration/setConciergePrescreeningQuestions')
export const setSitePrescreeningQuestions = createAction('participationProgressConfiguration/setSitePrescreeningQuestions')
export const setConciergePrescreenerSitesByCountries = createAction('participationProgressConfiguration/setConciergePrescreenerSitesByCountries')
export const setSitePrescreenerSitesByCountries = createAction('participationProgressConfiguration/setSitePrescreenerSitesByCountries')
export const resetParticipationProgressConfiguration = createAction('participationProgressConfiguration/resetParticipationProgressConfiguration')

export const fetchConciergePrescreenerQuestionAnswers = () => async dispatch => {
  try {
    dispatch(setConciergePrescreeningQuestions({ isLoading: true }))
    const response = await getConfigConciergePrescreenerQuestion()

    dispatch(setConciergePrescreeningQuestions({ results: response }))
  } catch (e) {
    dispatch(showError('There was some issue while trying to fetch Concierge Prescreening Questions. Please try refreshing the page.', e))
  } finally {
    dispatch(setConciergePrescreeningQuestions({ isLoading: false }))
  }
}

export const fetchSitePrescreenerQuestionAnswers = () => async dispatch => {
  try {
    dispatch(setSitePrescreeningQuestions({ isLoading: true }))
    const response = await getConfigSitePrescreenerQuestion()

    dispatch(setSitePrescreeningQuestions({ results: response }))
  } catch (e) {
    dispatch(showError('There was some issue while trying to fetch Site Prescreening Questions. Please try refreshing the page.', e))
  } finally {
    dispatch(setConciergePrescreeningQuestions({ isLoading: false }))
  }
}

export const fetchSitesByCountries = (countriesLookup, questionType) => async dispatch => {
  const actionToBeCalled = questionType === questionTypeConstant.site ? setSitePrescreenerSitesByCountries : setConciergePrescreenerSitesByCountries

  if (countriesLookup.length > 0) {
    dispatch(actionToBeCalled({ isLoading: true }))
    await getSitesByCountries(countriesLookup.map(country => country.id).toString())
      .then(res => {
        dispatch(actionToBeCalled({ results: res, isLoading: false }))
      })
      .catch(() => {
        dispatch(actionToBeCalled({ isLoading: false }))
        dispatch(showError('There was some issue while trying to fetch sites. Please try refreshing the page.'))
      })
  } else {
    // if countries are cleared, clear the sites by countries
    dispatch(actionToBeCalled({ results: [], isLoading: false }))
  }
}

export const saveConfigPrescreenerQuestion = (data, questionType, resetForm) => async dispatch => {
  try {
    dispatch(setLoading(true))

    const questionData = {
      question: data.question,
      associatedSiteIds: data.siteAssociations.map(site => site.id)
    }

    if (questionType === questionTypeConstant.concierge) {
      await addConfigConciergePrescreenerQuestion(questionData)
    } else if (questionType === questionTypeConstant.site) {
      await addConfigSitePrescreenerQuestion(questionData)
    }
    dispatch(showSuccess('Prescreener Question added successfully!'))
    resetForm(initialValues)
    // Refresh questions
    if (questionType === questionTypeConstant.concierge) {
      dispatch(fetchConciergePrescreenerQuestionAnswers())
      dispatch(setConciergePrescreenerSitesByCountries({ results: [], isLoading: false }))
    } else if (questionType === questionTypeConstant.site) {
      await dispatch(fetchSitePrescreenerQuestionAnswers())
      dispatch(setSitePrescreenerSitesByCountries({ results: [], isLoading: false }))
    }
  } catch (e) {
    dispatch(showError('Unable to save Prescreening Question at this time. Please try again.', e))
  }
  dispatch(setLoading(false))
}

export const saveIVRConfiguration = data => async dispatch => {
  try {
    dispatch(setLoading(true))

    await updateIVRIntegration(data)

    dispatch(showSuccess('Patient Data Integration updated successfully!'))
    // update instance config
    dispatch(fetchInstanceConfigDetails())
  } catch (e) {
    dispatch(showError('Unable to save IVR Integration at this time. Please try again.', e))
  }
  dispatch(setLoading(false))
}

export const initialValues = {
  question: '',
  countries: [],
  siteAssociations: []
}

const initialState = {
  conciergePrescreeningQuestions: { results: [], isLoading: false },
  sitePrescreenerQuestions: { results: [], isLoading: false },
  conciergePrescreenerSitesByCountries: { results: [], isLoading: false },
  sitePrescreenerSitesByCountries: { results: [], isLoading: false },
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setConciergePrescreeningQuestions, (state, action) => {
      state.conciergePrescreeningQuestions = { ...state.conciergePrescreeningQuestions, ...action.payload }
    })
    .addCase(setSitePrescreeningQuestions, (state, action) => {
      state.sitePrescreenerQuestions = { ...state.sitePrescreenerQuestions, ...action.payload }
    })
    .addCase(setConciergePrescreenerSitesByCountries, (state, action) => {
      state.conciergePrescreenerSitesByCountries = { ...state.conciergePrescreenerSitesByCountries, ...action.payload }
    })
    .addCase(setSitePrescreenerSitesByCountries, (state, action) => {
      state.sitePrescreenerSitesByCountries = { ...state.sitePrescreenerSitesByCountries, ...action.payload }
    })
    .addCase(resetParticipationProgressConfiguration, state => {
      copyObject(state, initialState)
    })
})
