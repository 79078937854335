import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { DataGrid } from 'components/common' // Button,
import { palette } from 'styles/baseTheme'
import { displayDate } from 'components/helper/utility'
// import { useRestriction } from 'permissions'
import { Typography } from '@mui/material'
import { fetchEthicCommitteeDocuments } from 'store/ducks/siteManagement/ecDirectory/ecManagement'

const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
`
const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}

const StyledAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main
}))

const ECDetails = () => {
  const { siteId } = useParams()
  const dispatch = useDispatch()
  const { ecDocumentsDetails } = useSelector(store => store.ecManagement)
  let formattedECDocumentsDetails = []
  if (ecDocumentsDetails && ecDocumentsDetails.length > 0) {
    formattedECDocumentsDetails = ecDocumentsDetails.map(doc => ({
      ...doc,
      docName: <Typography variant='body2'><StyledAnchor href={doc.docName} download>{doc.docName}</StyledAnchor></Typography>,
      approvalDate: displayDate(doc.approvalDate),
      uploadedDate: displayDate(doc.uploadedDate),
    }))
  }

  useEffect(() => {
    dispatch(fetchEthicCommitteeDocuments(siteId, true))
  }, [siteId])

  const headers = [
    { id: 'docName', label: 'Document Name', isSortable: false, headProperties: { ...headerProperties } },
    { id: 'approvalDate', label: 'Approval Date ', isSortable: false, headProperties: { ...headerProperties } },
    {
      id: 'uploadedDate',
      label: 'Uploaded Date',
      bodyProperties: {
        width: '20%'
      },
      isSortable: false,
      headProperties: { ...headerProperties }
    },
    { id: 'uploadedBy', label: 'Uploaded By', isSortable: false, headProperties: { ...headerProperties } }
  ]

  // check permission of each column
  // const updatedHeaders = headers.filter(
  //   item => useRestriction('read', item.id) && item
  // )

  return (
    <>
      <TableWrapper>
        <DataGrid
          headers={headers}
          tableData={formattedECDocumentsDetails}
          alternateRowColored={true}
          noRowsFoundMessage="No Ethics Committee document(s) found."
        />
      </TableWrapper>
    </>
  )
}

export default ECDetails
