import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useField, useFormikContext } from 'formik'
import { AutoCompleteWithLabel, AutoComplete } from 'components/common'
import { showError } from 'store/ducks/application'
import { getSitesByCountries } from 'services/common'

const SitesByCountryDropdown = props => {
  const { selectedCountry, countryChange, outerLabel, ...rest } = props
  const [sitesLookup, setSitesLookup] = useState([])
  const dispatch = useDispatch()
  const [loadingSites, setLoadingSites] = useState(false)
  const { setFieldValue } = useFormikContext() || {}
  const [field] = useField(props)

  const fetchSitesLookup = async () => {
    if (selectedCountry) {
      setLoadingSites(true)
      try {
        const data = await getSitesByCountries(selectedCountry)
        // check if the options list has the already selected value,
        // if not reset the state dropdown to ''
        setSitesLookup(data)
        setFieldValue(field.name, field.value)

        const optionExists = field.value && data.map(res => res.id).includes(field.value.id)

        const compareCriterion = (a, b) => a.displayText === b
        const compareObjects = (left, right, compareFunction) =>
          left.filter(leftValue =>
            right.some(rightValue =>
              compareFunction(leftValue, rightValue)))
        const matchingDisplayText = compareObjects(data, field.value, compareCriterion)

        if (optionExists) {
          setFieldValue(field.name, field.value)
        } else if (matchingDisplayText.length > 0) {
          setFieldValue(field.name, matchingDisplayText)
        } else {
          setFieldValue(field.name, [])
        }
        if (props.noSitesFoundForSelectedCountry) {
          props.noSitesFoundForSelectedCountry(data.length === 0)
        }
      } catch {
        dispatch(
          showError(
            'There was issue while fetching sites dropdown options. Please refresh your page or try after some time.',
          ),
        )
      }
      setLoadingSites(false)
    }
  }

  useEffect(() => {
    !rest.disabled && fetchSitesLookup()
  }, [selectedCountry, rest.disabled])

  return outerLabel
    ? (
      <AutoCompleteWithLabel options={sitesLookup} isLoading={loadingSites} helperText={'Select a Site'} {...rest} />
    )
    : (
      <AutoComplete options={sitesLookup} isLoading={loadingSites} {...rest} />
    )
}

SitesByCountryDropdown.propTypes = {
  selectedCountry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  countryChange: PropTypes.number,
  options: PropTypes.array,
  outerLabel: PropTypes.bool,
  noSitesFoundForSelectedCountry: PropTypes.func,
}
SitesByCountryDropdown.defaultProps = {
  outerLabel: true,
  noSitesFoundForSelectedCountry: undefined,
}
export default SitesByCountryDropdown
