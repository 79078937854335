import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Grid, IconButton, Typography } from '@mui/material'
import { TextBox, AutoCompleteWithLabel, Button, Checkbox, RichTextEditor } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  // fetchSitePreferenceQuestionsData,
  fetchSitePrefLoadData,
  fetchSitesByCountries,
  onSubmitNewQuestion,
  setAddQuestion,
  setSitesByCountries
} from 'store/ducks/configuration/sitePreferencesConfiguration'
import styled from '@emotion/styled'
import { validationMapper } from 'components/helper/validationEngine'
import { AddCircle, RemoveCircle } from '@mui/icons-material'
import { convertDraftToHTML, convertHtmlToDraft } from 'components/helper/utility'
import { formElements } from 'components/helper/constants/common'

const ElementWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    marginBottom: `${theme.spacing(2)}`
  }
}))

const ActionWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    marginTop: `${theme.spacing(1)}`,
    justifyContent: 'center'
  }
}))

const OptionsHeader = styled.div(() => ({
  '&&': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
  }
}))

const ElementWrapperForOptions = styled(Grid)(({ theme }) => ({
  '&&': {
    '.MuiFormControl-root': {
      width: '90%',
      marginBottom: `${theme.spacing(2)}`
    }
  }
}))

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const AddQuestion = () => {
  const dispatch = useDispatch()
  const { category, answerType, countries, addQuestion, sitesByCountries } = useSelector(
    store => store.sitePreferencesConfiguration
  )
  const [noOfOptions, setNoOfOptions] = useState([])

  useEffect(() => {
    fetchSitePref()
  }, [])

  const fetchSitePref = async () => await dispatch(fetchSitePrefLoadData())

  const updatedAddQuestion = {
    ...addQuestion,
    question: convertHtmlToDraft(addQuestion.question)
  }

  const onCountriesChange = (selectedValues, reason, values) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      // Dynamic dropdown options for Sites based on country selection
      if (selectedValues.find(option => option.id === 'select-all')) {
        dispatch(
          setAddQuestion({
            ...addQuestion,
            countries: countries.results,
            question: convertDraftToHTML(values.question)
          })
        )
        dispatch(fetchSitesByCountries(countries.results))
      } else {
        dispatch(
          setAddQuestion({
            ...addQuestion,
            countries: selectedValues,
            question: convertDraftToHTML(values.question)
          })
        )

        if (selectedValues.length > 0) {
          dispatch(fetchSitesByCountries(selectedValues))
        } else {
          dispatch(setSitesByCountries({ results: [] }))
        }
        if (reason === 'removeOption') {
          // Empty the selected sites if any
          dispatch(
            setAddQuestion({
              ...addQuestion,
              siteAssociations: [],
              countries: selectedValues,
              question: convertDraftToHTML(values.question)
            })
          )
        }
      }
    } else if (reason === 'clear') {
      dispatch(
        setAddQuestion({
          ...addQuestion,
          countries: [],
          siteAssociations: [],
          question: convertDraftToHTML(values.question)
        })
      )
      dispatch(setSitesByCountries({ results: [] }))
    }
  }

  const onAnswerTypeChange = (selectedValues, reason, values) => {
    if (reason === 'selectOption') {
      if (selectedValues.hasOptions) {
        setNoOfOptions(['option0'])
        dispatch(
          setAddQuestion({
            ...addQuestion,
            answerType: selectedValues,
            question: convertDraftToHTML(values.question),
            option0: ''
          })
        )
      } else {
        dispatch(
          setAddQuestion({
            ...addQuestion,
            answerType: selectedValues,
            question: convertDraftToHTML(values.question)
          })
        )
        setNoOfOptions([])
      }
    } else if (reason === 'clear') {
      dispatch(
        setAddQuestion({
          ...addQuestion,
          answerType: null,
          question: convertDraftToHTML(values.question)
        })
      )
      setNoOfOptions([])
    }
  }

  const onAddNewOption = (setFieldValue, values) => {
    const optionsInitialValues = {}
    optionsInitialValues[`option${noOfOptions.length}`] = ''
    setFieldValue(`option${noOfOptions.length}`, '')
    dispatch(
      setAddQuestion({
        ...addQuestion,
        question: convertDraftToHTML(values.question),
        ...optionsInitialValues
      })
    )
    const optionsList = [...noOfOptions]
    optionsList.push(`option${noOfOptions.length}`)
    setNoOfOptions(optionsList)
  }

  const onRemoveOptions = (optionToBeRemoved, values) => {
    const newObject = { ...addQuestion }

    delete newObject[optionToBeRemoved]

    dispatch(
      setAddQuestion({
        ...newObject,
        question: convertDraftToHTML(values.question),
      })
    )
    const optionsList = [...noOfOptions]
    setNoOfOptions(optionsList.filter(option => option !== optionToBeRemoved))
  }

  const validationSchema = () => {
    let validationList = [
      {
        id: 'category',
        isResponseRequired: true,
        answerType: formElements.dropdownMenu
      },
      { id: 'question', isResponseRequired: true, answerType: formElements.richTextEditor },
      {
        id: 'answerType',
        isResponseRequired: true,
        answerType: formElements.dropdownMenu
      },
      {
        id: 'countries',
        isResponseRequired: true,
        answerType: formElements.multiSelectDropdown
      },
      {
        id: 'siteAssociations',
        isResponseRequired: true,
        answerType: formElements.multiSelectDropdown
      }
    ]

    if (noOfOptions.length) {
      const optionValidationList = noOfOptions.map(option => ({
        id: option,
        isResponseRequired: true,
        answerType: formElements.openText
      }))
      validationList = [...validationList, ...optionValidationList]
    }
    return validationMapper(validationList)
  }

  const onSiteChangeHandler = (event, selectedValues, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValues.find(option => option.id === 'select-all')) {
        dispatch(setAddQuestion({ ...addQuestion, siteAssociations: sitesByCountries.results }))
      } else {
        dispatch(setAddQuestion({ ...addQuestion, siteAssociations: selectedValues }))
      }
    } else if (reason === 'clear') {
      dispatch(setAddQuestion({ ...addQuestion, siteAssociations: [] }))
    }
  }

  return (
    <Formik
      initialValues={updatedAddQuestion}
      validationSchema={validationSchema()}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(onSubmitNewQuestion({ ...values, question: convertDraftToHTML(values.question) }, noOfOptions))
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form noValidate>
          <Grid container spacing={2}>
            <ElementWrapper item xs={12} sm={12} md={4} lg={4}>
              <AutoCompleteWithLabel
                multiple={false}
                hasSelectAllOption={false}
                size="medium"
                id="category"
                label="Category:"
                name="category"
                isLoading={category.isLoading}
                options={category.results}
                onChange={(event, selectedValues, reason) => {
                  if (reason === 'selectOption') {
                    dispatch(
                      setAddQuestion({
                        ...addQuestion,
                        category: selectedValues,
                        question: convertDraftToHTML(values.question)
                      })
                    )
                  } else if (reason === 'clear') {
                    dispatch(
                      setAddQuestion({
                        ...addQuestion,
                        category: null,
                        question: convertDraftToHTML(values.question)
                      })
                    )
                  }
                }}
              />
            </ElementWrapper>
            <ElementWrapper item xs={12} sm={12} md={12} lg={12}>
              <RichTextEditor label="Question:" id="question" name="question" />
            </ElementWrapper>
          </Grid>
          <Grid container spacing={2}>
            <ElementWrapper item xs={12} sm={12} md={4} lg={4}>
              <AutoCompleteWithLabel
                multiple={false}
                hasSelectAllOption={false}
                size="medium"
                id="answerType"
                label="Answer Type:"
                name="answerType"
                isLoading={answerType.isLoading}
                options={answerType.results}
                onChange={(event, selectedValues, reason) => {
                  onAnswerTypeChange(selectedValues, reason, values)
                }}
              />
            </ElementWrapper>
            <ElementWrapper item xs={0} sm={12} md={1} lg={1} />
            {values.answerType?.hasOptions && (
              <ElementWrapperForOptions item xs={12} sm={12} md={7} lg={7}>
                <OptionsHeader>
                  <Typography variant="body2">Options:</Typography>
                  <IconButton
                    variant="contained"
                    size="small"
                    color="primary"
                    aria-label="new-option"
                    onClick={() => onAddNewOption(setFieldValue, values)}
                  >
                    <AddCircle />
                  </IconButton>
                </OptionsHeader>

                {noOfOptions.map((option, index) => (
                  <OptionWrapper key={index}>
                    <TextBox
                      size="medium"
                      id={`${option}`}
                      label={`Option ${index + 1}`}
                      name={option}
                      type="text"
                      onChange={e => {
                        const newOptionValue = {}
                        newOptionValue[`${option}`] = e.target.value
                        dispatch(
                          setAddQuestion({
                            ...values,
                            question: convertDraftToHTML(values.question),
                            ...newOptionValue
                          })
                        )
                      }}
                    />
                    {index > 0 && <IconButton
                      variant="contained"
                      size="small"
                      color="primary"
                      aria-label="new-option"
                      onClick={() => onRemoveOptions(option, values)}
                    >
                      <RemoveCircle />
                    </IconButton>}
                  </OptionWrapper>
                ))}
              </ElementWrapperForOptions>
            )}
          </Grid>
          <Grid container spacing={2}>
            <ElementWrapper item xs={12} sm={12} md={4} lg={4} data-testid="countries-container">
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="medium"
                id="countries"
                label="Countries:"
                name="countries"
                onChange={(event, selectedValues, reason) =>
                  onCountriesChange(selectedValues, reason, values)
                }
                options={countries.results}
                isLoading={countries.isLoading}
              />
            </ElementWrapper>
            <ElementWrapper item xs={0} sm={12} md={1} lg={1} />
            <ElementWrapper item xs={12} sm={12} md={6.5} lg={6.5}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="medium"
                id="siteAssociations"
                label="Site Associations:"
                name="siteAssociations"
                options={sitesByCountries.results}
                isLoading={sitesByCountries.isLoading}
                onChange={onSiteChangeHandler}
              />
            </ElementWrapper>
          </Grid>
          <Grid container spacing={2}>
            <ElementWrapper item xs={12} sm={12} md={4} lg={4}>
              <Checkbox
                name="answerIsRequired"
                id="answerIsRequired"
                label="Answer is Required"
                size="medium"
                onChange={e => {
                  dispatch(setAddQuestion({ ...addQuestion, answerIsRequired: e.target.checked }))
                }}
              />
            </ElementWrapper>
          </Grid>
          <ActionWrapper container>
            <ElementWrapper item xs={12} sm={12} md={2} lg={2}>
              <Button color="primary" type="submit" size="large" fullWidth={true}>
                Submit
              </Button>
            </ElementWrapper>
          </ActionWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default AddQuestion
