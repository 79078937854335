import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Button, DataGrid } from 'components/common'
import { palette } from 'styles/baseTheme'
import { displayDate } from 'components/helper/utility'
import UploadECDoc from './UploadECDoc'
import QuickView from 'components/common/QuickView'
import { Delete } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { deletedECFile, downloadECAttachment, fetchEthicCommitteeDocuments, setShowECDocUpload } from 'store/ducks/siteManagement/ecDirectory/ecManagement'
import { showError } from 'store/ducks/application'

const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
`
const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}
const Container = styled.div(({ theme }) => ({
  padding: theme.spacing(2, 0, 3, 0),
  width: '100%',
  overflow: 'hidden'
}))
const HeadingContainer = styled.div(() => ({
  width: '80%',
  float: 'left'
}))
const ButtonContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))
const EmptyFooter = styled.div`
  padding: 25px;
`
const ActionCellWrapper = styled.div`
  width: 60px;
`
const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 8px;
  padding: 1px 3px;
`
)
const DeleteIcon = styled(Delete)`
  width: 15px;
`
const StyledAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}))

const ActionCellWrapperFullWidth = styled.div`
  width: 100%;
`

// eslint-disable-next-line max-lines-per-function
const Documents = () => {
  const { encrypttedEthicID } = useParams()
  const dispatch = useDispatch()

  const { showECDocUpload, ecDocumentsDetails } = useSelector(store => store.ecManagement)

  const onTableRowSelection = async (event, encrypttedAttachID) => {
    if (encrypttedAttachID !== '') {
      const payload = { encrypttedEthicID, encrypttedAttachID }
      await dispatch(deletedECFile(payload))
      await refreshDocs()
    } else {
      dispatch(showError('Invalid document ID. Unable to perform delete operation.'))
    }
  }

  const onDownload = async (event, name, id) => {
    const file = {
      name,
      id
    }
    await dispatch(downloadECAttachment(file))
  }
  let formattedECDocData = []
  if (ecDocumentsDetails && ecDocumentsDetails.length > 0) {
    formattedECDocData = ecDocumentsDetails.map(doc => ({
      ...doc,
      docName: <ActionCellWrapperFullWidth>
        <StyledAnchor
          onClick={event => onDownload(event, doc.docName, doc?.encrypttedAttachID)}
          download
        >
          <>
            <Typography variant='body2'>
              {doc.docName}
            </Typography>
          </>
        </StyledAnchor>
      </ActionCellWrapperFullWidth>,
      approvalDate: displayDate(doc.approvalDate),
      uploadedDate: displayDate(doc.uploadedDate),
      manage: <ActionCellWrapper>
        <IconButtonWrapper
          aria-label="actions"
          aria-haspopup="true"
          onClick={event => onTableRowSelection(event, doc?.encrypttedAttachID)}
          color="primary"
        >
          <>
            <DeleteIcon color="primary" fontSize="small" />
          </>
        </IconButtonWrapper>
      </ActionCellWrapper>
    }))
  }

  const closeECDocUploadForm = () => {
    dispatch(setShowECDocUpload(false))
  }
  const refreshDocs = () => {
    dispatch(fetchEthicCommitteeDocuments(encrypttedEthicID))
  }

  useEffect(() => {
    refreshDocs()
  }, [encrypttedEthicID])

  const headers = [
    {
      id: 'docName',
      label: 'Document Name',
      bodyProperties: {
        width: '49%',
        textAlign: 'left'
      },
      headProperties: { ...headerProperties, textAlign: 'left' }
    },
    {
      id: 'approvalDate',
      label: 'Approval Date',
      bodyProperties: {
        width: '15%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'uploadedDate',
      label: 'Uploaded Date',
      bodyProperties: {
        width: '15%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'uploadedBy',
      label: 'Uploaded By',
      bodyProperties: {
        width: '15%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'manage',
      label: ' ',
      bodyProperties: {
        width: '1%',
      },
      headProperties: {
        ...headerProperties
      }
    }
  ]

  return (
    <React.Fragment>
      <Container>
        <HeadingContainer></HeadingContainer>
        <ButtonContainer>
          <Button
            label="Upload"
            size="medium"
            fullWidth={false}
            color="primary"
            onClick={() => dispatch(setShowECDocUpload(true))}
          >
            Upload
          </Button>
        </ButtonContainer>
      </Container>
      <TableWrapper>
        <DataGrid
          headers={headers}
          tableData={formattedECDocData}
          setRequestedSort={() => { }}
          order={'DESC'}
          orderBy={'createdDate'}
          alternateRowColored={true}
          noRowsFoundMessage="No results found."
        />
      </TableWrapper>
      <QuickView
        title="Upload Ethics Committee Document"
        onClose={closeECDocUploadForm}
        dialogContent={
          <UploadECDoc
            encrypttedEthicID={encrypttedEthicID}
          />
        }
        isDialogOpen={showECDocUpload}
        maxWidth="sm"
        dialogActions={<EmptyFooter />}
        closeConfirmation={true}
      />
    </React.Fragment>
  )
}

export default Documents
