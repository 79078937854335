import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
  Autocomplete,
  FormHelperText,
  TextField,
  Typography,
  Skeleton,
  InputLabel,
  Chip
} from '@mui/material'
import { useField } from 'formik'

const StyledAutoComplete = styled(Autocomplete)(
  ({ theme }) => `
&& {
  
  .MuiOutlinedInput-root {
    max-height: 100px;
    min-height: 45px;
    overflow: hidden;
    background-color: ${theme.palette.white.dark};
    margin-bottom: 4px;
    padding-top: 4px;
    fieldset .MuiOutlinedInput-notchedOutline {
      top: -5px;
      border: 2px solid ${theme.palette.background.disabled};
    }
  }
  .Mui-disabled{
    fieldset {
      background-color: ${theme.palette.background.disabled};
      border: unset;
    }
    input {
      z-index: ${theme.zIndex.minorUiElement};
    }
    .MuiAutocomplete-endAdornment{
      svg{
        color: ${theme.palette.gray.dark};
      }
    }
    .MuiButtonBase-root{
      z-index: ${theme.zIndex.minorUiElement};
    }
}
`
)

const CaptionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
`

const LoadingSkeleton = styled(Skeleton)`
    position: relative;
    top: 10px;
}
`
const StyledInputLabel = styled(InputLabel)`
  && {
    transform: unset;
    display: flex;
    width: fit-content;
    white-space: pre-wrap;
    margin-top: 1px;
    font-weight: 600;
    color: #333333;
  }
`
const DisabledSkeleton = styled.div(({ theme, multiple }) => ({
  border: `2px solid ${theme.palette.background.disabled}`,
  height: '45px',
  backgroundColor: `${theme.palette.background.disabled}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  color: `${theme.palette.white.contrastText}`,
  padding: `${theme.spacing(0, 1.1, 0, 1.5)}`,
  display: 'flex',
  alignItems: 'center',
  overflowY: 'auto',
  flexWrap: 'wrap',
  '.MuiChip-root': multiple && {
    marginRight: theme.spacing(1),
    marginTop: '6px'
  },
  p: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}))

const AutoCompleteWithLabel = props => {
  const {
    label,
    disableTypographyForLabel,
    id,
    name,
    options,
    multiple,
    matchWith,
    fetchOptions,
    hasSelectAllOption,
    disabled,
    selectedValue,
    onChange,
    onPostChange,
    onBlur,
    error,
    helperText,
    fullWidth,
    size,
    limitTags,
    required,
    formik,
    captionText,
    isLoading,
    placeholder,
    dataTestId,
    resetOnUnMount
  } = props

  let field, meta, setValue

  const onFieldValueChange = (event, selectedValues, reason) => {
    if (multiple) {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (hasSelectAllOption && selectedValues.find(option => option.id === 'select-all')) {
          setValue(options)
        } else {
          setValue(selectedValues)
        }
      } else if (reason === 'clear') {
        setValue([])
      }
    } else {
      setValue(selectedValues)
    }

    // Call onPostChange if provided
    if (onPostChange) {
      onPostChange()
    }
  }

  if (formik === 'true') {
    ;[field, meta, { setValue }] = useField(props)
    field = { ...field, onChange: onChange || onFieldValueChange }
  } else {
    field = {
      onChange,
      onBlur
    }
  }

  useEffect(() => {
    fetchOptions && !disabled && fetchOptions()
  }, [disabled])

  useEffect(
    () => () => {
      // This will reset components values on unmount
      if (formik === 'true' && resetOnUnMount) {
        if (multiple) {
          setValue([])
        } else {
          setValue(null)
        }
      }
    },
    []
  )

  let formattedOptions = options
  if (multiple && hasSelectAllOption && options && options.length > 1) {
    formattedOptions = [{ id: 'select-all', displayText: 'Select All' }, ...options]
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <>
      {isLoading
        ? (
          <LoadingSkeleton height={62} animation="wave" variant="rectangular" />
        )
        : (
          <>
            {disableTypographyForLabel
              ? (
                label
              )
              : (
                <StyledInputLabel required={required} shrink htmlFor={id} error={isError}>
                  {label}
                </StyledInputLabel>
              )}
            {disabled
              ? (
                <DisabledSkeleton multiple={multiple}>
                  {field.value
                    ? (
                      multiple
                        ? (
                          field.value?.length
                            ? (
                              field.value.map((v, index) => (
                                <Chip size="small" key={index} label={v.displayText} variant="outlined" />
                              ))
                            )
                            : (
                              ''
                            )
                        )
                        : (
                          <Typography variant="body1">{field.value.displayText}</Typography>
                        )
                    )
                    : (
                      ''
                    )}
                </DisabledSkeleton>
              )
              : (
                <StyledAutoComplete
                  limitTags={limitTags}
                  getOptionLabel={option => option.displayText}
                  options={formattedOptions}
                  id={id}
                  fullWidth={fullWidth}
                  renderInput={params => {
                    const { InputLabelProps, InputProps, ...rest } = params
                    return (
                      <TextField
                        {...rest}
                        InputLabelProps={{ ...InputLabelProps, shrink: true }}
                        InputProps={{ ...InputProps, notched: false }}
                        error={isError}
                        fullWidth={fullWidth}
                        required={required}
                        placeholder={placeholder}
                      />
                    )
                  }}
                  name={name}
                  isOptionEqualToValue={(option, value) => option[matchWith] === value[matchWith]}
                  multiple={multiple}
                  disabled={disabled}
                  ChipProps={{ size: 'small' }}
                  size={size}
                  value={selectedValue}
                  data-testid={dataTestId}
                  {...field}
                />
              )}
          </>
        )}

      {isError && errorText && (
        <FormHelperText component="div" error={isError}>
          {errorText}
        </FormHelperText>
      )}
      {captionText && (
        <CaptionContainer>
          <Typography variant="caption">{captionText}</Typography>
        </CaptionContainer>
      )}
    </>
  )
}

AutoCompleteWithLabel.propTypes = {
  /**
   * Options must be in array of objects format with each field as id and display text.
   */
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, displayText: PropTypes.string }))
    .isRequired,
  /**
   * The label content. Note, this is of type node that means you must pass this as React element ex: Typography
   * The reason this is defined as node is that the label can be a combination of text and some icon.
   */
  label: PropTypes.node.isRequired,
  /**
   * Disabled the Typography around the label
   */
  disableTypographyForLabel: PropTypes.bool,
  /**
   * ID for the field.
   */
  id: PropTypes.string.isRequired,
  /**
   * Name for the field. When formik is true, this will also used for validations.
   */
  name: PropTypes.any.isRequired,
  /**
   * When true, user can selected multiple values.
   * @default false
   */
  multiple: PropTypes.bool,
  /**
   * Pass a service call that returns array of options.
   */
  fetchOptions: PropTypes.func,
  /**
   * @default id
   * This key will be used to match the value with one of the lookup values
   */
  matchWith: PropTypes.oneOf(['id', 'displayText', 'alternateIdentifier']),
  /**
   * When true, there will be a select all option in the dropdown.
   * This functionality is available when multiple props is true and when formik is true.
   * @default false
   */

  hasSelectAllOption: PropTypes.bool,
  /**
   * When true, field is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle  onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),
  /**
   * Use this only for non-formik forms, because when formik, the initial value given in the formik wrapper will be passed to this field
   * inside a `field` object and field.value will override this props.
   * - For single select dropdown i.e when `multiple` is false, the value must be an object with id and display text.
   * - Whereas for `multiple` is true, the value must be an array of objects with id and display text.
   */
  selectedValue: PropTypes.oneOfType([
    PropTypes.shape({ id: PropTypes.any, displayText: PropTypes.string }),
    PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, displayText: PropTypes.any }))
  ]),
  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own onChange
   * inside a `field` object and field.onChange will override this props.
   * * Here is onChange example, which will take care all the scenarios - multi-select, select all, single select
   * `const onFieldValueChange = (event, selectedValues, reason) => {`
    `if (multiple) {`
    `if (reason === 'selectOption' || reason === 'removeOption') {`
        `if (hasSelectAllOption && selectedValues.find((option) => option.id === 'select-all')) {`
          `setValue(options)`
        `} else {`
          `setValue(selectedValues)`
        `}`
     ` } else if (reason === 'clear') {`
       ` setValue([])`
      `}`
    `} else {`
      `setValue(selectedValues)`
   ` }`
 ` }`
   */

  onChange: PropTypes.func,
  /**
   * Optional prop. If provided, call post onChange
   */
  onPostChange: PropTypes.func,
  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own onBlur
   * inside a `field` object and field.onBlur will override this props.
   * - Information on onChange https://mui.com/api/autocomplete/
   */
  onBlur: PropTypes.func,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * When true, The width of this field is spread covering its parent wrapper or container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * Decides the size of the component.
   * @default 'small'
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Limits the number of selected options in input field. Works only when multiple is true.
   */
  limitTags: PropTypes.number,
  /**
   * Makes this field required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * Text shown at the bottom of the form element.
   *
   */
  captionText: PropTypes.string,
  /**
   * Control the loading state of the element
   *
   * when `true` a skeleton loading animation is shown.
   * @default false
   */
  isLoading: PropTypes.bool,
  /**
   * text to show before selection
   */
  placeholder: PropTypes.string,
  /**
   * Pass data-testid to the control
   * data-testid is an attribute used to identify a DOM node for testing purposes
   */
  dataTestId: PropTypes.string,
  /**
   * @default true
   * resets selectedValue on component is unmounted
   */
  resetOnUnMount: PropTypes.bool
}

AutoCompleteWithLabel.defaultProps = {
  multiple: false,
  disabled: false,
  formik: 'true',
  fullWidth: true,
  size: 'small',
  hasSelectAllOption: false,
  limitTags: 2,
  required: false,
  isLoading: false,
  matchWith: 'id',
  resetOnUnMount: true
}

export default AutoCompleteWithLabel
