import { communicationTabIDs } from 'components/helper/constants/mailbox'

const missingPermissions = [
  // These permissions will be by default be true,
  // that means always be shown to the all users
  // not controlled by API
  {
    feature: 'managePatientListColumn',
    ability: 'read',
    permission: true
  },
  {
    feature: 'recordCreatedDatePatientListColumn',
    ability: 'read',
    permission: true
  },

  {
    feature: 'tcnIdPatientListColumn',
    ability: 'read',
    permission: true
  },

  {
    feature: 'sourcePatientListColumn',
    ability: 'read',
    permission: true
  },

  {
    feature: 'participationProgressPatientListColumn',
    ability: 'read',
    permission: true
  },

  {
    feature: 'subjectIdPatientListColumn',
    ability: 'read',
    permission: true
  },
  { feature: 'firstName', ability: 'read', permission: true },
  { feature: 'lastName', ability: 'read', permission: true },
  { feature: 'role', ability: 'read', permission: true },
  { feature: 'phone', ability: 'read', permission: true },
  { feature: 'email', ability: 'read', permission: true },
  { feature: 'userStatusDisplay', ability: 'read', permission: true },
  { feature: 'lastLoginDate', ability: 'read', permission: true },
]

const mockPermissions = [
  {
    feature: 'arriveGuidelinesButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'patientDashboard',
    ability: 'read',
    permission: true
  },
  {
    feature: 'dashboardHelpCenterbutton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'dashboardConciergeHelpbutton',
    ability: 'read',
    permission: true
  },
  // {
  //   feature: 'dashboardExport',
  //   ability: 'read',
  //   permission: true
  // },
  {
    feature: `${communicationTabIDs.announcementTab}WriteAnnouncement`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.announcementTab}FilterSearchButton`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.announcementTab}ViewAnnouncementButton`,
    ability: 'read',
    permission: true
  },
  { feature: `${communicationTabIDs.announcementTab}`, ability: 'read', permission: true },
  { feature: communicationTabIDs.conciergeMsgsWithSites, ability: 'read', permission: true },
  { feature: communicationTabIDs.conciergeMsgsWithPatients, ability: 'read', permission: true },
  {
    feature: `${communicationTabIDs.conciergeMsgsWithSites}MoveToCompletedButton`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.conciergeMsgsWithPatients}MoveToCompletedButton`,
    ability: 'read',
    permission: true
  },

  { feature: `${communicationTabIDs.announcementTab}SentTo`, ability: 'read', permission: true },

  {
    feature: `${communicationTabIDs.conciergeMsgsWithSites}WriteMessage`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.conciergeMsgsWithPatients}WriteMessage`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.conciergeMsgsWithSites}SendToRadioButtons`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.conciergeMsgsWithSites}CountriesDropdown`,
    ability: 'read',
    permission: true
  },
  { feature: 'conciergeMsgsWithSitesDistributionDate', ability: 'read', permission: true },
  {
    feature: `${communicationTabIDs.conciergeMsgsWithSites}SitesDropdown`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.announcementTab}ViewOrEditAnnouncementButton`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.announcementTab}DeleteAnnouncementButton`,
    ability: 'read',
    permission: true
  },
  {
    feature: 'conciergeMsgsWithSitesEditButton',
    ability: 'read',
    permission: true
  },

  {
    feature: `${communicationTabIDs.conciergeMsgsWithSites}ViewMessageRecipients`,
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.conciergeMsgsWithPatients}ViewMessageRecipients`,
    ability: 'read',
    permission: true
  },
  {
    feature: 'conciergeMsgsWithSitesMoveToActiveButton',
    ability: 'read',
    permission: true
  },

  {
    feature: 'conciergeMsgsWithSitesCompletedMsgbutton',
    ability: 'read',
    permission: true
  },
  {
    feature: `${communicationTabIDs.conciergeMsgsForPatients}ReplyButton`,
    ability: 'read',
    permission: true
  },
  {
    feature: 'PatientList',
    ability: 'read',
    permission: true
  },
  {
    feature: 'PatientListFilter',
    ability: 'read',
    permission: true
  },
  {
    feature: 'PatientListSiteIDDropdown',
    ability: 'read',
    permission: true
  },
  {
    feature: 'mailBox',
    ability: 'read',
    permission: true
  },
  { feature: 'arrive', ability: 'read', permission: true },
  {
    feature: 'arriveMakeRequestButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'arriveManageRequestButton',
    ability: 'read',
    permission: true
  },
  { feature: 'arriveApproveButton', ability: 'read', permission: true },
  {
    feature: 'arriveNotApproveButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'arriveCancelRequestButton',
    ability: 'read',
    permission: true
  },
  { feature: 'arriveUploadButton', ability: 'read', permission: true },
  { feature: 'arriveWriteMsgButton', ability: 'read', permission: true },
  { feature: 'arriveHistoryGrid', ability: 'read', permission: true },
  {
    feature: 'arriveMarkAsCompletedButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'conciergeMsgsFilterSearchButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'patientProfileGeneralInformation',
    ability: 'read',
    permission: true
  },
  {
    feature: 'patientProfileEditEmail',
    ability: 'read',
    permission: true
  },
  {
    feature: 'patientProfileEditContactInfo',
    ability: 'read',
    permission: true
  },
  {
    feature: 'patientProfileCompanionInformation',
    ability: 'read',
    permission: true
  },
  {
    feature: 'patientProfileCompanionAddButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'profileNoteDelete',
    ability: 'read',
    permission: true
  },
  {
    feature: 'profileNoteAdd',
    ability: 'read',
    permission: true
  },

  //! missing
  {
    feature: 'PatientListManageAssignmentButton', // 'PatientListSiteIDColumn'
    ability: 'read',
    permission: false
  },
  {
    feature: 'sponsorSiteIdPatientListColumn', // 'PatientListSiteIDColumn'
    ability: 'read',
    permission: false
  },

  {
    feature: 'siteCountryPatientListColumn', // PatientListSiteCountryColumn
    ability: 'read',
    permission: false
  },

  {
    feature: 'userInitialsPatientListColumn', // PatientListPatientInitialsColumn
    ability: 'read',
    permission: true
  },

  {
    feature: 'conciergeMsgsHeader',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reimbursement',
    ability: 'read',
    permission: true
  },
  {
    feature: 'signUpSection',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reimbursementSignUpSubmitButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'participationProgressNotesGrid',
    ability: 'read',
    permission: true
  },
  {
    feature: 'participationProgressSubjectId',
    ability: 'read',
    permission: true
  },
  {
    feature: 'participationProgressSiteId',
    ability: 'read',
    permission: true
  },
  {
    feature: 'participationProgressAddNoteButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'participationProgressPreconsentStatusDD',
    ability: 'read',
    permission: true
  },
  {
    feature: 'participationProgressPreconsentStatusDD',
    ability: 'edit',
    permission: true
  },
  {
    feature: 'participationProgress',
    ability: 'read',
    permission: true
  },
  {
    feature: 'addFundsSection',
    ability: 'read',
    permission: true
  },
  {
    feature: 'addFundsApproveButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'addFundsFundsHistorygrid',
    ability: 'read',
    permission: true
  },
  {
    feature: 'addFundsReceiptsColumn',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountSection',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountCardExpiration',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountViewVirtualCardButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountRequestPlasticCardButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountActivatePlasticCardButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountReportLostStolenButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountViewHistoryButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountCancelAndReplaceButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountGetPINButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'manageAccountTransferToBankAccountButton',
    ability: 'read',
    permission: true
  },
  { feature: 'siteAddresses', ability: 'read', permission: true },
  { feature: 'userActivity', ability: 'read', permission: true },
  { feature: 'siteNotes', ability: 'read', permission: true },
  { feature: 'dataPoints', ability: 'read', permission: true },
  { feature: 'sitePreferencesColumn', ability: 'read', permission: true },

  { feature: 'resources', ability: 'read', permission: true },
  { feature: 'smartAlerts', ability: 'read', permission: true },
  { feature: 'configuration', ability: 'read', permission: true },
  { feature: 'myAccount', ability: 'read', permission: true },
  { feature: 'studyList', ability: 'read', permission: true },
  { feature: 'logout', ability: 'read', permission: true },
  { feature: 'reports', ability: 'read', permission: true },

  { feature: 'patientProfile', ability: 'read', permission: true },
  { feature: 'studyTools', ability: 'read', permission: true },
  // { feature: 'participationProgress', ability: 'read', permission: true },
  // { feature: 'patientMailbox', ability: 'read', permission: true },

  { feature: 'secondaryPrescreeningEditButton', ability: 'read', permission: true },
  { feature: 'secondaryPrescreening', ability: 'read', permission: true },
  { feature: 'additionalPrescreening', ability: 'read', permission: true },
  { feature: 'additionalPrescreeningEditButton', ability: 'read', permission: true },
  { feature: 'patientSurveyAnswers', ability: 'read', permission: true },
  { feature: 'dashboardConciergeHelpbutton', ability: 'read', permission: true },
  { feature: 'patientConciergeMsgsSystemId', ability: 'read', permission: true },

  { feature: 'conciergeProfileNotes', ability: 'read', permission: true },
  { feature: 'patientProfileArrive', ability: 'read', permission: true },

  { feature: 'manageAccountViewHistoryButton', ability: 'read', permission: true },
  { feature: 'manageAccountReportLostStolenButton', ability: 'read', permission: true },
  { feature: 'siteManagement', ability: 'read', permission: true },

  /** Dashboard Grid Columns permissions */
  { feature: 'managePatientListColumn', ability: 'read', permission: true },
  { feature: 'recordCreatedDatePatientListColumn', ability: 'read', permission: true },
  { feature: 'tcnIdPatientListColumn', ability: 'read', permission: true },
  { feature: 'userInitialsPatientListColumn', ability: 'read', permission: true },
  { feature: 'sourcePatientListColumn', ability: 'read', permission: true },
  { feature: 'participationProgressPatientListColumn', ability: 'read', permission: true },
  { feature: 'subjectIdPatientListColumn', ability: 'read', permission: true },
  { feature: 'sponsorSiteIdPatientListColumn', ability: 'read', permission: true },
  { feature: 'siteCountryPatientListColumn', ability: 'read', permission: true },
  /** Dashboard Add Patient button */
  { feature: 'PatientListAddPatientButton', ability: 'read', permission: true },
  /** Dashboard Filter button */
  { feature: 'PatientListFilter', ability: 'read', permission: true },
  /** Patient Dashboard button */
  { feature: 'dashboardVisitScheduleAndAssessments', ability: 'read', permission: true },
  { feature: 'dashboardVisitInformation', ability: 'read', permission: true },
  { feature: 'patientProfileEditEmailCompanion', ability: 'read', permission: true },
  {
    feature: 'ecDirectoryButton',
    ability: 'read',
    permission: true
  },
  {
    feature: 'ecDocumentsTab',
    ability: 'read',
    permission: true
  },
  {
    feature: 'patientProfileForgotUsernamePassword',
    ability: 'read',
    permission: true
  }
]

const patientPermissions = [
  {
    feature: 'patientDashboardSideBar',
    ability: 'read',
    permission: true
  },
  { feature: communicationTabIDs.conciergeMsgsForPatients, ability: 'read', permission: true },
  { feature: communicationTabIDs.announcementTab, ability: 'read', permission: true },
  {
    feature: `${communicationTabIDs.conciergeMsgsForPatients}ReplyToMessage`,
    ability: 'read',
    permission: true
  },
  {
    feature: 'conciergeMessageHeader',
    ability: 'read',
    permission: true
  },
  {
    feature: 'conciergeMsgsNoteToPatient',
    ability: 'read',
    permission: true
  }
]

const reportPermissions = [
  {
    feature: 'reportParticipationProgress',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportParticipationProgressNotes',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportParticipantStatusHistory',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportReimbursementsByVisit',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSiteStatusAndAddresses',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSitePerformance',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportTCNArriveImpact',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportCardPatientUsage',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportRequestSummary',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportPatientProfile',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportPatientUsageActivity',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSiteInsightsDataPoints',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSiteInsightsNotes',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSiteInsightsPreferences',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportMediaPlannedvsActual',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportPrescreener',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportPrescreenerWithNotes',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSecondaryPrescreening',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportAdditionalPrescreening',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportMediaOverview',
    ability: 'read',
    permission: true
  }
]

const reportPermissionsForCRA = [
  {
    feature: 'reportParticipationProgress',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportParticipantStatusHistory',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportReimbursementsByVisit',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSiteStatusAndAddresses',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportSitePerformance',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportTCNArriveImpact',
    ability: 'read',
    permission: true
  },
  {
    feature: 'reportPrescreener',
    ability: 'read',
    permission: true
  }
]

export { mockPermissions, patientPermissions, missingPermissions, reportPermissions, reportPermissionsForCRA }
