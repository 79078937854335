import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import {
  AutoCompleteWithLabel,
  Button,
  Checkbox,
  DatePickerWithLabel,
  TextBoxOuterLabel
} from 'components/common'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchEnrollmentCodesLookup,
  fetchParticipationStatusLookup,
  fetchSubjectSourcesLookup,
  setEnrollmentCodesLookup
} from 'store/ducks/application'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import styled from '@emotion/styled'
import {
  fetchSitesLookup,
  fetchCountriesLookup,
  fetchLanguagesLookup,
  fetchEnglishSkillsLookup
} from 'store/ducks/common'
import { onAddPatient } from 'store/ducks/patientExperienceManager'
import { useRestriction } from 'permissions'

const SiteWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    '.MuiFormControl-root': {
      [theme.breakpoints.up('sm')]: {
        width: '40%'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  }
}))
const Note = styled(Typography)(() => ({
  '&&': {
    fontWeight: 500,
    fontStyle: 'italic'
  }
}))

const AcknowledgeTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CaptionTypography = styled(Typography)(
  ({ theme }) => `
    padding-left: ${theme.spacing(0.5)};
    padding-top: ${theme.spacing(1)};
  `
)
const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(1)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: start;
      justify-content: flex-start;
  `
)

const validationSchema = (isAbleToSelectSite, isAppointmentDateRequired) =>
  validationMapper([
    { id: 'firstName', isResponseRequired: true, answerType: formElements.openText },
    { id: 'lastName', isResponseRequired: true, answerType: formElements.openText },
    { id: 'emailAddress', isResponseRequired: true, answerType: formElements.emailOpenText },
    {
      id: 'confirmEmailAddress',
      isResponseRequired: true,
      answerType: formElements.openText,
      dependency: {
        matchWith: true,
        ref: 'emailAddress',
        message: 'Email must match'
      }
    },

    { id: 'isAcknowledged', isResponseRequired: true, answerType: formElements.checkbox },
    {
      id: 'siteClientId',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
      dependency: {
        elements: [],
        callBack: () => isAbleToSelectSite
      }
    },
    // { id: 'gender', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'preferredLanguage', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'participationProgress',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu
    },
    {
      id: 'appointmentDate',
      isResponseRequired: isAppointmentDateRequired,
      answerType: formElements.date,
      condition: {
        min: new Date(),
        minError: 'Appointment Date must be in the future'
      },
      error: 'Appointment Date is required and must be a valid date'
    },
    // {
    //   id: 'subjectId',
    //   isResponseRequired: true,
    //   answerType: formElements.dropdownMenu,
    //   dependency: {
    //     elements: ['participationProgress'],
    //     callBack: participationProgress =>
    //       /**
    //        * when a selected option from participation progress dropdown has true isSubjectID property
    //        */
    //       !!participationProgress?.isSubjectId
    //   }
    // },
    { id: 'source', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'englishSkill', isResponseRequired: true, answerType: formElements.dropdownMenu }
  ])

const AddPatient = () => {
  const dispatch = useDispatch()
  const { subjectSourcesLookup, participationStatusLookup, enrollmentCodesLookup } = useSelector(
    store => store.application
  )
  const {
    sitesLookup,
    countriesLookup,
    // genderLookup,
    languagesLookup,
    englishSkillsLookup
  } = useSelector(store => store.common)
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)
  const [isAppointmentDateRequired, setAppointmentDateRequired] = useState(false)
  const subjectIDIsOpenText = !instanceConfig.results.IVRIntegration === 'true'
  const canSelectSite = useRestriction('read', 'PatientListSiteIDDropdown')

  useEffect(() => {
    lookupsForAddPatient()
  }, [])

  const lookupsForAddPatient = async () => {
    await dispatch(fetchSitesLookup())
    await dispatch(fetchSubjectSourcesLookup())
    await dispatch(fetchParticipationStatusLookup())
    await dispatch(fetchLanguagesLookup())
    await dispatch(fetchEnglishSkillsLookup())
  }

  // Get only options with isAdditionalNote === false
  const updatedParticipationStatusLookup = participationStatusLookup.results.filter(
    item => item.isAddPatient
  )

  const onParticipationProgressChange = (selectedValues, reason, setFieldValue) => {
    if (reason === 'clear') {
      setFieldValue('participationProgress', null)
      setAppointmentDateRequired(false)
    } else {
      // when "Appointment Scheduled" option is selected
      if (selectedValues.id === 22) {
        setAppointmentDateRequired(true)
      } else {
        setAppointmentDateRequired(false)
      }
      setFieldValue('participationProgress', selectedValues)
    }
  }

  const onSiteSelection = (selectedValues, reason, setFieldValue) => {
    if (reason === 'clear') {
      setFieldValue('siteClientId', null)
      dispatch(setEnrollmentCodesLookup({ results: [] }))
    } else {
      // fetch lookup for subject ID only when user selects a site
      if (canSelectSite && !subjectIDIsOpenText) {
        dispatch(fetchEnrollmentCodesLookup(selectedValues.alternateIdentifier))
      }
      setFieldValue('siteClientId', selectedValues)
    }
  }

  const patientInformation = {
    siteClientId: null,
    firstName: '',
    middleInitial: '',
    lastName: '',
    emailAddress: '',
    confirmEmailAddress: '',
    // gender: null,
    country: null,
    englishSkill: null,
    preferredLanguage: null,
    source: null,
    participationProgress: null,
    appointmentDate: '',
    subjectId: subjectIDIsOpenText ? '' : null,
    // patientIXRS: null,
    isAcknowledged: false
  }

  return (
    <>
      <Formik
        initialValues={patientInformation}
        enableReinitialize={false}
        validationSchema={validationSchema(canSelectSite, isAppointmentDateRequired)}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(onAddPatient(values))
          setSubmitting(false)
        }}
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              {/* Todo: AutoSelection of Site based on login role */}
              {canSelectSite && (
                <SiteWrapper item xs={12} sm={12} md={12} lg={12}>
                  <AutoCompleteWithLabel
                    multiple={false}
                    hasSelectAllOption={false}
                    size="small"
                    id="siteClientId"
                    label="Site"
                    name="siteClientId"
                    options={sitesLookup.results}
                    isLoading={sitesLookup.isLoading}
                    required
                    onChange={(event, selectedValues, reason) =>
                      onSiteSelection(selectedValues, reason, setFieldValue)
                    }
                  />
                </SiteWrapper>
              )}

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextBoxOuterLabel
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  fullWidth={true}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <TextBoxOuterLabel
                  id="middleInitial"
                  label="Middle Initial"
                  name="middleInitial"
                  fullWidth={true}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxOuterLabel
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  fullWidth={true}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={8} md={1} lg={1} />
              {/* <Grid item xs={12} sm={6} md={3} lg={3}>
                <AutoCompleteWithLabel
                  multiple={false}
                  hasSelectAllOption={false}
                  size="small"
                  id="gender"
                  name="gender"
                  label="Gender"
                  options={genderLookup.results}
                  isLoading={genderLookup.isLoading}
                  required
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AutoCompleteWithLabel
                  id="country"
                  label="Country of Residence"
                  name="country"
                  options={countriesLookup.results}
                  isLoading={countriesLookup.isLoading}
                  fetchOptions={() => dispatch(fetchCountriesLookup())}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AutoCompleteWithLabel
                  multiple={false}
                  hasSelectAllOption={false}
                  size="small"
                  id="source"
                  name="source"
                  label="Source"
                  options={subjectSourcesLookup.results}
                  isLoading={subjectSourcesLookup.isLoading}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={5}>
                <AutoCompleteWithLabel
                  multiple={false}
                  hasSelectAllOption={false}
                  size="small"
                  id="participationProgress"
                  name="participationProgress"
                  label="Participation Progress"
                  options={updatedParticipationStatusLookup}
                  isLoading={participationStatusLookup.isLoading}
                  onChange={(event, selectedValues, reason) =>
                    onParticipationProgressChange(selectedValues, reason, setFieldValue)
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1} lg={1} />
              {isAppointmentDateRequired && (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <DatePickerWithLabel
                    id="appointment-date"
                    label="Appointment Date:"
                    required={true}
                    name="appointmentDate"
                    minDate={new Date()}
                    size="small"
                  />
                </Grid>
              )}
              {/*
               * Show Subject ID dropdown only when a selected option from participation progress dropdown has true isSubjectID property
               */}
              {values.participationProgress?.isSubjectId && (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {subjectIDIsOpenText
                    ? (
                      <TextBoxOuterLabel
                        size="small"
                        id="subjectId"
                        name="subjectId"
                        label="Subject ID (when available)"
                        fullWidth={true}
                      />
                    )
                    : (
                      <AutoCompleteWithLabel
                        multiple={false}
                        hasSelectAllOption={false}
                        size="small"
                        id="subjectId"
                        name="subjectId"
                        label="Subject ID (when available)"
                        options={enrollmentCodesLookup.results}
                        isLoading={enrollmentCodesLookup.isLoading}
                      />
                    )}
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AutoCompleteWithLabel
                  multiple={false}
                  hasSelectAllOption={false}
                  size="small"
                  id="preferredLanguage"
                  name="preferredLanguage"
                  label="Preferred Language"
                  options={languagesLookup.results}
                  isLoading={languagesLookup.isLoading}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <AutoCompleteWithLabel
                  multiple={false}
                  hasSelectAllOption={false}
                  size="small"
                  id="englishSkill"
                  name="englishSkill"
                  label="How well does the patient understand English?"
                  options={englishSkillsLookup.results}
                  isLoading={englishSkillsLookup.isLoading}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Note variant="caption">
                  The patient view of this portal, study materials, etc., will be made available in
                  the languages of your site&apos;s country. If your patient&apos;s preferred
                  language is not one of these, please contact the Concierge for assistance.
                </Note>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextBoxOuterLabel
                  id="emailAddress"
                  label="Primary Email Address"
                  name="emailAddress"
                  fullWidth={true}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextBoxOuterLabel
                  id="confirmEmailAddress"
                  label="Confirm Primary Email Address"
                  name="confirmEmailAddress"
                  fullWidth={true}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={0} sm={0} md={3} lg={3} />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Note variant="caption">
                  If the patient does not have an email account or is unable to manage email
                  communications, please provide an email address for a caregiver who will be
                  supporting this patient during their study participation. If no email address is
                  available, please contact the Concierge for assistance.
                </Note>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Checkbox
                  name="isAcknowledged"
                  id="acknowledgement"
                  label={
                    <AcknowledgeTextWrapper>
                      <Typography variant="body1" gutterBottom>
                        I confirm the information above is complete and correct, and that the person
                        identified with this information is willing to share their data.
                        Furthermore, the information will not be shared with anyone other than
                        study-related personnel or entities.
                      </Typography>
                      <CaptionTypography variant="caption">
                        Please Note: In accordance with the Terms and Conditions and Privacy Policy
                        of TrialCentralNet®, this information also may be relevant for
                        program-related administrative communication regarding other patient support
                        services as available for this study.
                      </CaptionTypography>
                    </AcknowledgeTextWrapper>
                  }
                  size="medium"
                  disableTypographyForLabel={true}
                />
              </Grid>
              <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
                <Button size="large" fullWidth={false} color="primary" type="submit">
                  Save
                </Button>
              </ActionWrapper>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddPatient
