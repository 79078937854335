import fileDownload from 'js-file-download'
import { backendAxios, getConfig, getConfigForDownload } from './backend'

export const postEthicsCommittee = requestData =>
  backendAxios
    .post('/ethicscommittee/ethicscommitee', requestData, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getEthicsCommittee = () =>
  backendAxios
    .get('/ethicscommittee/ethicscommitees', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getEthicsCommitteeByID = encrypttedEthicID =>
  backendAxios
    .get(`/ethicscommittee/ethicscommitees?eCId=${encrypttedEthicID}`, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getEthicsCommitteeSiteIdAssociation = encrypttedEthicID =>
  backendAxios
    .get(`/ethicscommittee/ecsiteidassociation?eCId=${encrypttedEthicID}`, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getEthicsCommitteeAttachments = (encrypttedEthicID, type = 'eCId') =>
  backendAxios
    .get(`/ethicscommittee/ecattachments?${type}=${encrypttedEthicID}`, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getEthicsCommitteeDetails = encrypttedEthicID =>
  backendAxios
    .get(`/ethicscommittee/ethicscommiteedetails?eCId=${encrypttedEthicID}`, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const downloadECAttachmentByID = attachment =>
  backendAxios
    .get(`/ethicscommittee/downloadethicscommitees/${attachment.id}`, getConfigForDownload())
    .then(response => {
      fileDownload(response.data, `${attachment.name}`)
    })
    .catch(err => Promise.reject(err.message))
