import { format, parse } from 'date-fns'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import * as XLSX from 'xlsx'
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate'

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
})

export const checkValidity = (value, rules) => {
  let isValid = true
  if (!rules) {
    return true
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    isValid = pattern.test(value) && isValid
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/
    isValid = pattern.test(value) && isValid
  }

  return isValid
}

/**
 * This will return the last element from the URL
 * Example: from this URLS xyz/abc/my-route/2, returns 2
 */
export const getSelectedId = pathname => {
  const pathParts = pathname.split('/')
  const lastPart = pathParts.pop()
  if (lastPart === '') {
    return pathParts.pop()
  }
  if (pathParts.includes('arrive')) {
    const getRoute = pathname.split('=/')
    return getRoute.pop()
  }
  return lastPart
}

export const helpers = {
  convertToRadioOptions: valuesObject => {
    const selectOptions = valuesObject.map(obj => ({ label: obj.displayText, value: obj.id }))
    return selectOptions
  }
}

export const sortNumericArray = arr => arr.sort((a, b) => a - b)

export const sortArrayByProperty = (array, property) =>
  array.sort((a, b) => (a[property] > b[property] ? 1 : -1))

export const sortList = (list, order, orderBy) =>
  list.slice().sort(getComparator(order, orderBy, order))

const unifyForSorting = val => {
  if (typeof val === 'number') {
    return Infinity
  } else if (Array.isArray(val)) {
    return val.length
  } else if (val && val.trim()) {
    // Check if the value is in a valid date format
    const dateRegex = /^\d{1,2}\s(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/
    if (dateRegex.test(val.trim())) {
      // Attempt to convert value to a Date object
      const dateValue = new Date(val.trim())
      // Check if the conversion was successful and the date is valid
      if (!isNaN(dateValue.getTime()) && dateValue.toDateString() !== 'Invalid Date') {
        return dateValue
      }
    }
    return val.trim().toLocaleLowerCase()
  } else {
    return ''
  }
}

const descendingComparator = (a, b, orderBy, order) => {
  const firstValue = unifyForSorting(b[orderBy])
  const secondValue = unifyForSorting(a[orderBy])

  if (firstValue < secondValue) {
    return -1
  }
  if (firstValue > secondValue) {
    return 1
  }

  if (firstValue === secondValue) {
    return 0
  }

  // if we're descending, lowest sorts first
  if (order === 'DESC') {
    return firstValue < secondValue ? 1 : -1
  }

  // otherwise if ascending, highest sorts first
  return firstValue < secondValue ? -1 : 1
}

const getComparator = (order, orderBy) =>
  order === 'DESC'
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order)

export const convertToFormField = question => {
  switch (question.answerType) {
    case 'Radio Buttons':
      return {
        fieldType: 'radio',
        label: question.question,
        value: '',
        selectOptions: question.options
          ? question.options.map(option => ({ label: option, value: option }))
          : [],
        fieldconfig: {
          fullWidth: false,
          row: true,
          disabled: true
        },
        gridWidth: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        }
      }

    case 'Open Text':
      return {
        fieldType: 'text',
        label: question.question,
        value: '',
        fieldconfig: {
          fullWidth: false
        },
        gridWidth: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        }
      }

    case 'Date':
      return {
        fieldType: 'date',
        label: question.question,
        value: '',
        gridWidth: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        }
      }

    case 'Check Boxes':
      return {
        fieldType: 'checkboxgroup',
        label: question.question,
        value: '',
        selectOptions: question.options
          ? question.options.map(option => ({ id: option, displayText: option }))
          : [],
        gridWidth: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        }
      }

    case 'Dropdown':
      return {
        fieldType: 'select',
        label: question.question,
        value: { id: question.response, displayText: question.response },
        selectOptions: question.options
          ? question.options.map(option => ({ id: option, displayText: option }))
          : [{ displayText: 'none', id: 1 }],
        fieldconfig: {
          fullWidth: false
        },
        gridWidth: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        }
      }

    case 'Dropdown Menu':
      return {
        fieldType: 'select',
        label: question.question,
        value: { id: question.response, displayText: question.response },
        selectOptions: question.options
          ? question.options.map(option => ({ id: option, displayText: option }))
          : [{ displayText: 'none', id: 1 }],

        gridWidth: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        }
      }

    default:
      return {
        fieldType: 'nomatch'
      }
  }
}

export const extractArrayOfIdsForEachKey = objectOfArrays =>
  /**
   * @params
   * { firstKey: [{id: 1, displayText: 'simpleText'}, {id: 2, displayText: 'secondText'}],
   *   secondKey: [{id: 22, displayText: 'simpleText'}, {id: 33, displayText: 'secondText'}] }
   *
   *    * Output example
   * { firstKey: [1,2], secondKey: [22, 33] }
   *
   *
   * Also takes care, if we want to extract any other property instead of ID
   * @params
   * {  uniqueSiteProtocolIds: {
   *      array:  [{id: 1, displayText: 'simpleText', alternateIdentifier: 101}, {id: 2, displayText: 'secondText', alternateIdentifier: 202}],
   *       propertyToExtract: 'alternateIdentifier'
   *      },
   *    firstKey: [{id: 1, displayText: 'simpleText'}, {id: 2, displayText: 'secondText'}],
   *    secondKey: [{id: 22, displayText: 'simpleText'}, {id: 33, displayText: 'secondText'}]
   * }
   *
   *    * Output example
   * { uniqueSiteProtocolIds: [101, 202] firstKey: [1,2], secondKey: [22, 33] }
   *
   */
  Object.entries(objectOfArrays).reduce((acc, val) => {
    if (val[1]) {
      if (Array.isArray(val[1]) && val[1].length) {
        acc[val[0]] = val[1].map(item => item.id)
      } else {
        if (val[1].array?.length) {
          acc[val[0]] = val[1].array.map(item => item[val[1].propertyToExtract])
        } else {
          acc[val[0]] = null
        }
      }
    } else {
      acc[val[0]] = null
    }
    return acc
  }, {})

export const displayDate = (givenDate, requiredFormat = 'dd MMM yyyy', givenFormat) => {
  if (givenDate) {
    if (givenFormat) {
      const parsedDate = parse(givenDate, givenFormat, new Date())
      return format(parsedDate, requiredFormat)
    } else {
      return format(new Date(givenDate), requiredFormat)
    }
  }
  return ''
}

export const displayTime = givenTimeStamp => {
  if (givenTimeStamp) {
    const date = new Date(givenTimeStamp)
    return ` ${format(date, 'h:mm:ss a')}`
  }
  return ''
}

// todo: replace this method with the removeTimePart
export const removeTime = date => {
  // Removed Minutes, Second and MilliSeconds part
  const returnDate = new Date(date)
  returnDate.setUTCHours(returnDate.getUTCHours(), 0, 0, 0) // Preserve the original hours value
  return returnDate.toISOString()
}

export const removeTimePart = date => {
  const returnDate = new Date(date)
  returnDate.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to zero
  return returnDate.toISOString().split('T')[0] // Return only the date part
}

export const copyObject = (toObject, fromObject) => {
  Object.keys(fromObject).forEach(key => {
    toObject[key] = fromObject[key]
  })
}

export const stripEnclosingPTag = text => text && text.replace('<p>', '').replace('</p>', '')

export const stripHtml = html => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const convertDraftToHTML = richTextValue =>
  draftToHtml(convertToRaw(richTextValue.getCurrentContent()))

export const convertHtmlToDraft = htmlContent => {
  const contentBlock = htmlToDraft(htmlContent)
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  return EditorState.createWithContent(contentState)
}

export const deepEqual = (v1, v2) => {
  if (v1 === v2) {
    return true
  }

  if (v1 === null || v2 === null || typeof v1 !== 'object' || typeof v2 !== 'object') {
    return false
  }

  const v1keys = Object.keys(v1)
  const v2keys = Object.keys(v2)

  if (v1keys.length !== v2keys.length) {
    return false
  }

  for (const key of v1keys) {
    if (!v2keys.includes(key) || !deepEqual(v1[key], v2[key])) {
      return false
    }
  }
  return true
}

export const getKeyByValue = (object, value) =>
  Object.keys(object).find(key => object[key] === value)

export const convertTo12HrTime = _24HrTime => {
  if (typeof _24HrTime !== 'string' || _24HrTime.indexOf(':') === -1) {
    return _24HrTime
  }

  const hour = parseInt(_24HrTime.split(':')[0])
  const formattedHour = ((hour + 11) % 12) + 1
  const formattedminutes = _24HrTime.split(':')[1]
  const suffix = hour >= 12 ? 'PM' : 'AM'

  return `${formattedHour}:${formattedminutes} ${suffix}`
}

/**
 * Returns filtered requests
 * @param {array[object], array[number]} word
 * @returns {array[object]}
 */
export const filterByRequestStatusId = (requests, requestIds) => {
  if (!requests || !Array.isArray(requestIds)) {
    return []
  }
  return requests.filter(request => requestIds.includes(request.requestStatusId))
}

export const formInitialValues = componentDetails => {
  const radio = () => componentDetails.response || ''
  const dropdown = () =>
    componentDetails.response
      ? { id: componentDetails.response, displayText: componentDetails.response }
      : null

  // CheckboxGroup value is saved a comma separated string
  // To bind its value should be in same format as options
  // [{ id: 'abc', displayText: 'xyz' }]
  const checkbox = () =>
    componentDetails.response
      ? componentDetails.response.split(',').map(value => ({ id: value, displayText: value }))
      : []

  const openText = () => componentDetails.response || ''
  const date = () => componentDetails.response || ''

  const defaultComponent = () => componentDetails.response || ''

  const componentsList = {
    'Dropdown Menu': dropdown,
    'Radio Buttons': radio,
    'Check Boxes': checkbox,
    'Open Text': openText,
    Date: date,
    default: defaultComponent
  }
  return componentsList[componentDetails.answerType]
    ? componentsList[componentDetails.answerType]()
    : componentsList.default()
}

export const shallowEqual = (object1, object2) => {
  if (object1 === undefined && object2 === undefined) {
    return true
  }
  if (object1 === null && object2 === null) {
    return true
  }

  if (object1 === undefined || object2 === undefined) {
    return false
  }
  if (object1 === null || object2 === null) {
    return false
  }

  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}

export const removeDuplicateItemsFromArray = (arrayToBeCleaned, removeByProperty) => {
  if (removeByProperty) {
    return [...new Map(arrayToBeCleaned.map(m => [m[removeByProperty], m])).values()]
  } else {
    return [...new Map(arrayToBeCleaned.map(m => [m[removeByProperty], m])).values()]
  }
}

export const formatCurrency = number => Number(number).toFixed(2)

/**
 *
 * @param {existingObject} { count: 0, list: [{"name": "New Concierge Message","module": "Study Message","displayText": null,"count": 5}] }
 * @param {newObject} {"name": "New Concierge Message","module": "Study Message","displayText": null,"count": 5}
 * @returns {*} {"mailBox": {"list": [{"name": "New Concierge Message","module": "Study Message","displayText": null,"count": 5},{"name": "New Concierge Message","module": "Announcements","displayText": null,"count": 2}],"count": 7},}
 */
export const createModuleObject = ({ count = 0, list = [] } = {}, newObject) => ({
  list: [...list, newObject],
  count: count + newObject.count
})

const s2ab = s => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length)

  // create a 8 bit integer array
  const view = new Uint8Array(buf)

  // charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i)
  }

  return buf
}

export const assignAlphabetKeys = obj => {
  const keys = Object.keys(obj)
  const result = {}

  let alphabetIndex = 0
  let alphabet = 'A'

  for (let i = 0; i < keys.length; i++) {
    result[alphabet] = obj[keys[i]]

    if (alphabetIndex === 25) {
      // Reached 'Z', jump to two characters
      alphabet = 'AA'
    } else if (alphabetIndex >= 51) {
      // Reached two characters limit, jump to three characters
      let charIndex = alphabet.length - 1
      let carry = true

      while (carry && charIndex >= 0) {
        const charCode = alphabet.charCodeAt(charIndex)
        if (charCode === 90) {
          // Reached 'Z', need to carry over to the next character
          alphabet = `${alphabet.substring(0, charIndex)}A${alphabet.substring(charIndex + 1)}`
          charIndex--
        } else {
          // Increment the current character
          alphabet =
            alphabet.substring(0, charIndex) +
            String.fromCharCode(charCode + 1) +
            alphabet.substring(charIndex + 1)
          carry = false
        }
      }

      if (carry) {
        // Need to add an additional character at the beginning
        alphabet = `A${alphabet}`
      }
    } else {
      // Increment the last character of the key
      const charCode = alphabet.charCodeAt(alphabet.length - 1)
      alphabet = alphabet.substring(0, alphabet.length - 1) + String.fromCharCode(charCode + 1)
    }

    alphabetIndex++
  }

  return result
}

export const addStyle = (workbookBlob, data) =>
  XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
    workbook.sheets().forEach((sheet, index) => {
      const { columnStyles, rangeStyles } = data[index].styles
      Object.keys(rangeStyles).forEach(rangeToBeStyled => {
        sheet.range(rangeToBeStyled).style(rangeStyles[rangeToBeStyled])
      })

      if (columnStyles) {
        Object.keys(columnStyles).forEach(columnToBeStyled => {
          sheet.column(columnToBeStyled).style(columnStyles[columnToBeStyled])
        })
      }
    })

    return workbook.outputAsync().then(workbookBlob => URL.createObjectURL(workbookBlob))
  })

export const exportToCSV = (data, fileName) => {
  const fileExtension = '.xlsx'

  // create a new workbook
  const wb = XLSX.utils.book_new()

  data.forEach(eachSheet => {
    const sheet = XLSX.utils.json_to_sheet(eachSheet.sheet, {
      skipHeader: true
    })
    XLSX.utils.book_append_sheet(wb, sheet, eachSheet.sheetName)
  })

  // binary large object
  // Since blobs can store binary data, they can be used to store images or other multimedia files.

  const wopts = {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  }

  const wbout = XLSX.write(wb, wopts)

  // The application/octet-stream MIME type is used for unknown binary files.
  // It preserves the file contents, but requires the receiver to determine file type,
  // for example, from the filename extension.
  const blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream'
  })

  addStyle(blob, data).then(url => {
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', url)
    downloadAnchorNode.setAttribute('download', fileName + fileExtension)
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  })
}

export const getSponsorSiteIdFromSiteName = siteName => {
  // site name "9999-United States",
  // the first part before - is the sponsorSiteId
  if (siteName) {
    return siteName.split('-')[0]
  }
  return null
}

/**
 * Returns Capitalized word (first letter caps)
 * @param {string} word
 * @returns {string}
 */
export const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)

export const setUniqueIdentifier = ({ countryId, siteId, uniqueSiteProtocolId, id, displayText }) =>
  `${countryId}|${siteId}|${uniqueSiteProtocolId}|${id}|${displayText}`

export const getIndividualProps = identifier => {
  const [countryId, siteId, uniqueSiteProtocolId, id, displayText] = identifier.split('|')

  return {
    countryId,
    siteId,
    uniqueSiteProtocolId,
    id,
    displayText
  }
}

/**
 * Returns date with number of days from today, if no argument provided returns tomorrow's date
 * @param {number} noOfDays
 * @returns {string} // 08/31/2023
 */
export const getFutureDate = noOfDays => {
  const currentDate = new Date()
  if (noOfDays && typeof noOfDays === 'number') {
    currentDate.setDate(currentDate.getDate() + noOfDays)
  } else {
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return (format(currentDate, 'MM/dd/yyyy'))
}

export const addCommas = num =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, '')

export const convertUTCDateToLocalDate = date => {
  if (!date) return null
  else {
    const dateInput = new Date(date)
    return new Date(dateInput.getTime() - dateInput.getTimezoneOffset() * 60 * 1000)
  }
}

export const checkPermissions = (key, policyPermissions) => {
  try {
    const permissions = policyPermissions.filter(({ feature }) => feature === key)
    if (permissions && permissions.length) return permissions[0]?.permission
    else return false
  } catch (e) {
    // eslint-disable-next-line
    log.debug('err', e)
  }
}

export const createFinalSheet = (reportsArray = []) => {
  const finalSheets = []
  if (reportsArray && reportsArray.length === 0) return finalSheets
  reportsArray.map(i => {
    if (Object.keys(i).length > 0) {
      finalSheets.push(
        {
          sheet: i?.sheet,
          styles: i?.styles,
          sheetName: i.sheetName
        }
      )
    }
    return finalSheets
  })
  return finalSheets
}

// Helper function to create the basic report structure
export const createReportBase = reportHeaders => {
  const { protocolNo, campaignName, title, reportDate } = reportHeaders
  return [
    { A: 'TCN® Engage' },
    { A: protocolNo },
    { A: campaignName },
    { A: title },
    { A: `${displayDate(reportDate)} ${displayTime(reportDate)}` },
    {} // Empty row for spacing
  ]
}

// Helper function to apply standard styles
export const applyStandardStyles = (headers, dateColumns = []) => {
  const rangeStylesObject = {
    'A1:A5': {
      bold: true,
      horizontalAlignment: 'left',
      fontFamily: 'Arial',
      fontSize: 10
    }
  }

  const columnStylesObject = {}
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) {
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  const lastColumn = Object.keys(headers).pop()
  rangeStylesObject[`A7:${lastColumn}7`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }

  return { rangeStylesObject, columnStylesObject }
}
