/* eslint-disable no-unused-vars */
import { createAction, createReducer } from '@reduxjs/toolkit'

import {
  copyObject,
  sortList
} from 'components/helper/utility'

import { setLoading } from '../../userInteractions'
import { showError, showSuccess } from '../../application'
import {
  downloadECAttachmentByID,
  getEthicsCommittee,
  getEthicsCommitteeAttachments,
  getEthicsCommitteeByID,
  getEthicsCommitteeDetails,
  getEthicsCommitteeSiteIdAssociation,
  postEthicsCommittee,
} from 'services/ecManagement'
import { deleteECAttachment, uploadECAttachments } from 'services/common'
const setFilters = createAction('ecManagement/setFilters')
export const setECDetails = createAction('ecManagement/setECDetails')
export const setRequestedSort = createAction('ecManagement/setRequestedSort')
export const setECTablePageNumber = createAction('ecManagement/setECTablePageNumber')//
export const setRowsPerPage = createAction('ecManagement/setRowsPerPage')
export const setAddEditECFlow = createAction('ecManagement/setAddEditECFlow')
const setAddECSuccess = createAction('ecManagement/setAddECSuccess')
const resetAddEditEC = createAction('ecManagement/resetAddEditEC')
export const resetECDetails = createAction('ecManagement/resetECDetails')
export const resetECManagement = createAction('ecManagement/resetECManagement')

export const setShowECDocUpload = createAction('ecManagement/setShowECDocUpload')
export const setEthicsCommitteelookup = createAction('ecManagement/setEthicsCommitteelookup')
export const resetEthicsCommitteelookup = createAction('ecManagement/resetEthicsCommitteelookup')
export const setECAssociationDetails = createAction('ecManagement/setECAssociationDetails')
export const setECDocumentsList = createAction('ecManagement/setECDocumentsList')
export const setECAddEditDetails = createAction('ecManagement/setECAddEditDetails')

export const fetchEthicsCommittee = () => async dispatch => {
  try {
    dispatch(setEthicsCommitteelookup({ isLoading: true }))
    const res = await getEthicsCommittee()
    const finalRes = res?.map(data => ({
      ...data,
      associatedSiteID: data.associatedSiteID.split(',')
    }))
    dispatch(setEthicsCommitteelookup({ results: finalRes || [], totalCount: finalRes?.length || 0, allResults: finalRes || [] }))
  } catch (e) {
    dispatch(() => {
      dispatch(
        showError(
          'There was some issue while trying to fetch Ethics Committee, Please try refreshing your page.', e
        )
      )
    })
  }

  dispatch(setEthicsCommitteelookup({ isLoading: false }))
}

export const handleRequestSort = orderByValue => (dispatch, getState) => {
  dispatch(setLoading(true))
  const { ethicsCommitteeLookup } = getState().ecManagement
  dispatch(setRequestedSort(orderByValue))
  const { order, orderBy } = getState().ecManagement

  dispatch(
    setEthicsCommitteelookup({
      results: sortList(ethicsCommitteeLookup.results, order, orderBy),
      totalCount: ethicsCommitteeLookup.totalCount
    })
  )
  dispatch(setLoading(false))
}

const searchList = (searchTerm, list) => {
  const searchFields = [
    'manage',
    'ethicCommiteeName',
    'ethicsCommiteeType',
    'country',
    'associatedSiteID'
  ]

  const results = list.filter(item => {
    for (const field of searchFields) {
      if (item[field] && item[field].toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }
    }
    return false
  })

  return results
}

export const fetchFilteredEC = filters => async dispatch => {
  try {
    dispatch(setLoading(true))
    const ethicsCommittee = await getEthicsCommittee()
    let formattedEthicsCommittee = ethicsCommittee?.map(data => ({
      ...data,
      associatedSiteID: data.associatedSiteID.split(',')
    }))
    const allResult = ethicsCommittee?.map(data => ({
      ...data,
      associatedSiteID: data.associatedSiteID.split(',')
    }))

    if (filters) {
      // siteIds filter
      const filterSiteIDs = filters.associatedSiteID.map(ec => ec.displayText.slice(0, ec.displayText.lastIndexOf('-')))
      if (filterSiteIDs.length) {
        const compareCriterion = (a, b) => {
          const siteIds = a.associatedSiteID.map(ec => ec.slice(0, ec.lastIndexOf(' - ')))
          return siteIds.includes(b)
        }
        const compareObjects = (left, right, compareFunction) =>
          left.filter(leftValue =>
            right.some(rightValue =>
              compareFunction(leftValue, rightValue)))
        const filteredEthicsCommittee = compareObjects(formattedEthicsCommittee, filterSiteIDs, compareCriterion)
        formattedEthicsCommittee = [...filteredEthicsCommittee]
      }

      // Countries filter
      const filterCountries = filters.country.map(country => country.displayText)
      if (filterCountries.length) {
        const filteredEthicsCommittee = formattedEthicsCommittee.filter(ec => filterCountries.includes(ec.country))
        formattedEthicsCommittee = [...filteredEthicsCommittee]
      }

      // Ethics Committee filter
      const filterSiteStatuses = filters.ethicsCommiteeType.map(siteStatus => siteStatus.displayText)
      if (filterSiteStatuses.length) {
        const filteredEthicsCommittee = formattedEthicsCommittee.filter(ec =>
          filterSiteStatuses.includes(ec.ethicsCommiteeType)
        )
        formattedEthicsCommittee = [...filteredEthicsCommittee]
      }
      // search term entered, submit btn clicked or hit enter
      const { search } = filters
      if (search) {
        const filteredEthicsCommittee = searchList(search, formattedEthicsCommittee)
        formattedEthicsCommittee = [...filteredEthicsCommittee]
      }
    }
    dispatch(
      setEthicsCommitteelookup({
        results: formattedEthicsCommittee,
        allResults: allResult,
        totalCount: formattedEthicsCommittee.length
      })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const searchInEC = filters => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { ethicsCommitteeLookup } = await getState().ecManagement
    const { search, associatedSiteID, country, ethicsCommiteeType } = filters
    const filtersSelected = associatedSiteID.length > 0 || country.length > 0 || ethicsCommiteeType.length > 0
    let formattedSites = ethicsCommitteeLookup?.allResults
    if (search && filtersSelected) { // search term entered after filtering data
      const filteredSites = searchList(search, ethicsCommitteeLookup?.results)
      formattedSites = [...filteredSites]
    } else if (search.length === 0 && filtersSelected) { // search term deleted after filtering data
      dispatch(fetchFilteredEC(filters))
    } else if (search.length > 0 && !filtersSelected) { // only search term entered
      const filteredSites = searchList(search, ethicsCommitteeLookup?.allResults)
      formattedSites = [...filteredSites]
    } else {
      formattedSites = ethicsCommitteeLookup?.allResults
    }
    dispatch(
      setEthicsCommitteelookup({
        results: formattedSites,
        totalCount: formattedSites.length
      })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const submitECDetails = values => async (dispatch, getState) => {
  const siteIdsAssociatedList = []
  values?.associatedSiteID && values?.associatedSiteID?.map(data => siteIdsAssociatedList.push(data.id))
  dispatch(setLoading(true))
  try {
    const { selectedInstance } = getState().auth
    const requestData = {
      siteIdsAssociatedList, // ['C3A24724-62A9-4672-B18F-EDE7459ED723', 'C3A24724-62A9-4672-B18F-EDE7459ED726', '7137041E-0F5E-4E2A-AD1E-9B149933EFFE'],
      ethicsCommiteeId: values.ethicsCommiteeID || null,
      ethicTypeId: values.ethicsCommiteeType?.id,
      ethicsCommiteeName: values.ethicsCommiteeName,
      stateId: values.state?.id,
      countryId: values.country.id,
      currentInstanceId: selectedInstance.instanceId,
    }

    await postEthicsCommittee({ ...requestData })

    if (values.encrypttedEthicID) {
      // Means user is editing a site - Refresh Site Data
      await dispatch(fetchEthicCommitteeDetails(values.encrypttedEthicID))
      if (values.accordionExpanded === 0) await dispatch(fetchEthicCommitteeAssociationDetails(values.encrypttedEthicID))
    } else {
      // user is adding a new site - refresh the site list
      dispatch(fetchEthicsCommittee())
    }
    // Close the Form Popup
    dispatch(setAddEditECFlow(false))
    dispatch(showSuccess('Ethics Committee Information saved successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

export const startAdECSuccess = value => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setAddECSuccess(value))
  dispatch(setLoading(false))
}

export const resetAddEditECFlow = () => async dispatch => {
  dispatch(resetAddEditEC())
}

export const fetchEthicCommitteeDetails = encrypttedEthicID => async dispatch => {
  try {
    dispatch(setLoading(true))
    const ecDetails = await getEthicsCommitteeDetails(encrypttedEthicID)
    const formattedDetails = ecDetails ? ecDetails[0] : {}

    dispatch(setECDetails(formattedDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const editEthicCommitteeDetails = (data, encrypttedEthicID) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const {
      countriesLookup,
      ethicsTypesLookup
    } = getState().common

    const getAssociatedSiteId = await getEthicsCommitteeByID(encrypttedEthicID)

    let selectedCountry = countriesLookup.results.filter(country => data.country === country.displayText)
    selectedCountry = (selectedCountry && selectedCountry[0]) || initialECDetails?.country

    let selectedECType = ethicsTypesLookup.results.filter(ec => data.ethicsCommiteeType === ec.displayText)
    selectedECType = (selectedECType && selectedECType[0]) || initialECDetails?.ethicsCommiteeType

    const formattedDetails = {
      ...initialECDetails,
      ethicsCommiteeName: data?.ethicCommiteeName,
      ethicsCommiteeID: encrypttedEthicID,
      country: selectedCountry,
      ethicsCommiteeType: selectedECType,
      state: data?.state,
      associatedSiteID: (getAssociatedSiteId && getAssociatedSiteId[0].associatedSiteID.split(',')) || []
    }

    dispatch(setECAddEditDetails(formattedDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchEthicCommitteeAssociationDetails = encrypttedEthicID => async dispatch => {
  try {
    dispatch(setLoading(true))
    const ecAssociationDetails = await getEthicsCommitteeSiteIdAssociation(encrypttedEthicID)

    dispatch(setECAssociationDetails(ecAssociationDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchEthicCommitteeDocuments = (encrypttedID, isSite = false) => async dispatch => {
  try {
    dispatch(setLoading(true))
    let ecDocuments = []
    if (isSite) ecDocuments = await getEthicsCommitteeAttachments(encrypttedID, 'siteId')
    else ecDocuments = await getEthicsCommitteeAttachments(encrypttedID, 'eCId')

    dispatch(setECDocumentsList(ecDocuments))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const deletedECFile = payload => async dispatch => {
  try {
    dispatch(setLoading(true))
    await deleteECAttachment({ id: payload?.encrypttedAttachID })
    dispatch(showSuccess('Selected Ethics Committee document deleted successfully!'))
  } catch {
    dispatch(showError('There was some issue while trying to delete the document. Please try again later.'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const saveECAttachments = (data, encrypttedEthicID) => async dispatch => {
  try {
    dispatch(setLoading(true))
    if (data.attachments.length > 0) {
      const newlyUploadedAttachments = data?.attachments.filter(a => !a.id)
      //  Create an object of formData
      const formData = new FormData()
      const formDate = data.ApprovalDate === '' ? '' : new Date(data.ApprovalDate).toLocaleString('en-us')
      formData.set('LUEntityTypeId', 10)
      newlyUploadedAttachments.forEach(f => {
        // Update the formData object
        formData.append('Files', f)
      })
      formData.set('ApprovalDate', formDate)
      formData.set('EthicsCommiteeId', encrypttedEthicID)
      formData.set('MimeType', data.attachments[0].type)
      formData.set('AllowedMimeTypes', '')
      await uploadECAttachments(formData)
      dispatch(showSuccess('Ethics Committee document uploaded successfully!'))
      await dispatch(setShowECDocUpload(false))
    }
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const downloadECAttachment = attachment => async dispatch => {
  try {
    dispatch(setLoading(true))
    await downloadECAttachmentByID(attachment)
  } catch (e) {
    dispatch(showError('There was some issue with downloading the attachment.', e))
  }

  dispatch(setLoading(false))
}

const initialECDetails = {
  ethicsCommiteeName: '',
  ethicsCommiteeType: { id: '', displayText: '' },
  country: { id: '', displayText: '' },
  state: { id: '', displayText: '' },
  associatedSiteID: []
}

const initialState = {
  ecDetails: {
    ethicsCommiteeName: '',
    ethicsCommiteeType: null,
    country: null,
    state: null,
    associatedSiteID: [],
  },
  initialECDetails: { ...initialECDetails },
  addEditECDetails: { ...initialECDetails },
  filters: {
    associatedSiteID: [],
    country: [],
    ethicsCommiteeType: [],
    search: '',
  },
  orderBy: 'ethicCommiteeName',
  order: 'ASC',
  ethicsCommitteeTablePageNumber: 0,
  rowsPerPage: 20,
  addEditECFlow: false,
  addECSuccess: false,
  ethicsCommitteeLookup: { results: [], isLoading: false }, //
  showECDocUpload: false,
  ecAssociationDetails: {
    id: null,
    piLastName: null,
    provision: '',
    ethicsCommiteeType: null,
    country: '',
    associatedSiteIDs: []
  },
  ecDocumentsDetails: {
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setFilters, (state, action) => {
      state.filters = action.payload
    })
    .addCase(setECDetails, (state, action) => {
      state.ecDetails = action.payload
    })
    .addCase(setECAddEditDetails, (state, action) => {
      state.addEditECDetails = action.payload
    })
    .addCase(setRequestedSort, (state, action) => {
      const isDesc = state.orderBy === action.payload && state.order === 'DESC'
      state.order = isDesc ? 'ASC' : 'DESC'
      state.orderBy = action.payload
    })
    .addCase(setECTablePageNumber, (state, action) => {
      state.ethicsCommitteeTablePageNumber = action.payload
    })
    .addCase(setRowsPerPage, (state, action) => {
      state.rowsPerPage = parseInt(action.payload, 10)
      state.ethicsCommitteeTablePageNumber = 0
    })
    .addCase(setAddEditECFlow, (state, action) => {
      state.addEditECFlow = action.payload
    })
    .addCase(setAddECSuccess, (state, action) => {
      state.addECSuccess = action.payload
    })
    .addCase(resetAddEditEC, state => {
      state.addEditECFlow = false
      state.addECSuccess = false
    })
    .addCase(setEthicsCommitteelookup, (state, { payload }) => { //
      state.ethicsCommitteeLookup = { ...state.ethicsCommitteeLookup, ...payload }
    })
    .addCase(resetEthicsCommitteelookup, state => { //
      state.ethicsCommitteeLookup = { results: [], isLoading: false }
    })
    .addCase(setShowECDocUpload, (state, action) => {
      state.showECDocUpload = action.payload
    })
    .addCase(resetECManagement, state => {
      copyObject(state, initialState)
    })
    .addCase(setECAssociationDetails, (state, action) => {
      state.ecAssociationDetails = action.payload
    })
    .addCase(setECDocumentsList, (state, action) => {
      state.ecDocumentsDetails = action.payload
    })
})
