/* eslint-disable no-unused-vars */
import { createAction, createReducer } from '@reduxjs/toolkit'

import { shallowEqual, displayDate, copyObject, sortList } from 'components/helper/utility'

import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import {
  getSites,
  getSiteDetails,
  saveSiteDetails,
  getSiteNotes,
  addSiteNote,
  getSiteDataPoints,
  addCustomDataPoint,
  updateDataPointAnswers,
  addSite
} from 'services/siteManagement'
import {
  getSiteAddressesForSite,
  saveSiteAddress,
  getAddressDropdownValues
} from 'services/settings'
import { getSitesByCountries } from 'services/common'
import { DataPointType } from 'components/helper/constants/siteManagement'
import { addressTypes } from 'components/helper/constants/patientExperienceManager'

export const setSites = createAction('siteManagement/setSites')
const setFilters = createAction('siteManagement/setFilters')
export const setSiteDetails = createAction('siteManagement/setSiteDetails')
export const setSiteNotes = createAction('siteManagement/setSiteNotes')
const setDataPoints = createAction('siteManagement/setDataPoints')
export const setDataPointsInformation = createAction('siteManagement/setDataPointsInformation')
export const setDataPointsInitialValues = createAction('siteManagement/setDataPointsInitialValues')
export const setSiteAddresses = createAction('siteManagement/setSiteAddresses')
const setAddressLookup = createAction('siteManagement/setAddressLookup')
export const setRequestedSort = createAction('siteManagement/setRequestedSort')
export const setSiteTablePageNumber = createAction('siteManagement/setSiteTablePageNumber')
export const setRowsPerPage = createAction('siteManagement/setRowsPerPage')
export const setAddEditSiteFlow = createAction('siteManagement/setAddEditSiteFlow')
const setAddSiteSuccess = createAction('siteManagement/setAddSiteSuccess')
const resetAddEditSite = createAction('siteManagement/resetAddEditSite')
export const resetSiteDetails = createAction('siteManagement/resetSiteDetails')
export const resetSiteManagement = createAction('siteManagement/resetSiteManagement')

export const setShowAddNote = createAction('siteManagement/setShowAddNote')
export const setSitesLookupByCountry = createAction('siteManagement/setSitesLookupByCountry')
export const resetSitesLookupByCountry = createAction('siteManagement/resetSitesLookupByCountry')

export const handleRequestSort = orderByValue => (dispatch, getState) => {
  dispatch(setLoading(true))
  const { sites } = getState().siteManagement
  dispatch(setRequestedSort(orderByValue))
  const { order, orderBy } = getState().siteManagement

  dispatch(
    setSites({
      results: sortList(sites.results, order, orderBy),
      totalCount: sites.totalCount
    })
  )
  dispatch(setLoading(false))
}

export const fetchAddressLookup = () => async (dispatch, getState) => {
  try {
    const { siteDetails } = getState().siteManagement
    const { patientInformation } = getState().patientMainWrapper
    const siteClientId = siteDetails.siteClientId || patientInformation.siteClientId
    const result = await getAddressDropdownValues(siteClientId)

    // Show 'Other' if not present
    // User should be able to add multiple 'Other' type addresses
    let addressTypeLookup = result.addressTypeLookup
    if (result.addressTypeLookup.filter(lookup => lookup.displayText === 'Other').length === 0) {
      addressTypeLookup = [
        ...result.addressTypeLookup,
        {
          id: 33,
          displayText: 'Other',
          alternateIdentifier: null
        }
      ]
    }

    dispatch(setAddressLookup(addressTypeLookup))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

const searchList = (searchTerm, list) => {
  const searchFields = [
    'manage',
    'siteId',
    'piLastName',
    'state',
    'country',
    'siteStatus',
    'plannedSiteActivationDate',
    'activationDate',
    'monthsActive',
    'totalScreened',
    'totalRandomized'
  ]

  const results = list.filter(item => {
    for (const field of searchFields) {
      if (item[field] && item[field].toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }
    }
    return false
  })

  return results
}

export const fetchSites = filters => async (dispatch, getState) => {
  // const { filters } = getState().advancedSupportRequest
  try {
    dispatch(setLoading(true))
    const sites = await getSites()
    let formattedSites = sites

    if (filters) {
      // siteIds filter
      const filterSiteIDs = filters.siteIDs.map(site => site.displayText.slice(0, site.displayText.lastIndexOf('-')))
      if (filterSiteIDs.length) {
        const filteredSites = formattedSites.filter(site => filterSiteIDs.includes(site.siteId))
        formattedSites = [...filteredSites]
      }

      // Countries filter
      const filterCountries = filters.countries.map(country => country.displayText)
      if (filterCountries.length) {
        const filteredSites = formattedSites.filter(site => filterCountries.includes(site.country))
        formattedSites = [...filteredSites]
      }

      // Site Status filter
      const filterSiteStatuses = filters.siteStatuses.map(siteStatus => siteStatus.displayText)
      if (filterSiteStatuses.length) {
        const filteredSites = formattedSites.filter(site =>
          filterSiteStatuses.includes(site.siteStatus)
        )
        formattedSites = [...filteredSites]
      }
      // search term entered, submit btn clicked or hit enter
      const { search } = filters
      if (search) {
        const filteredSites = searchList(search, formattedSites)
        formattedSites = [...filteredSites]
      }
    }
    dispatch(
      setSites({
        results: formattedSites,
        allResults: sites,
        totalCount: formattedSites.length
      })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const searchInSites = filters => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { sites } = await getState().siteManagement
    const { search, siteIDs, countries, siteStatuses } = filters
    const filtersSelected = siteIDs.length > 0 || countries.length > 0 || siteStatuses.length > 0
    let formattedSites = sites?.allResults
    if (search && filtersSelected) { // search term entered after filtering data
      const filteredSites = searchList(search, sites?.results)
      formattedSites = [...filteredSites]
    } else if (search.length === 0 && filtersSelected) { // search term deleted after filtering data
      dispatch(fetchSites(filters))
    } else if (search.length > 0 && !filtersSelected) { // only search term entered
      const filteredSites = searchList(search, sites?.allResults)
      formattedSites = [...filteredSites]
    } else {
      formattedSites = sites?.allResults
    }
    dispatch(
      setSites({
        results: formattedSites,
        totalCount: formattedSites.length
      })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchSiteAddresses = siteClientId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await getSiteAddressesForSite(siteClientId)
    const siteAddresses = response.map(address => ({
      ...address,
      phone: address.phone || '',
      state: address.state || '',
      address2: address.address2 || '',
      locationName: address.locationName || '',
      attentionOfContact: address.attentionOfContact && {
        ...address.attentionOfContact,
        id: address.alternateIdentifier
      },
      isAddressVerified: address.isAddressVerified || false
    }))
    dispatch(setSiteAddresses(siteAddresses))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const submitSiteAddress = (data, onSubmitSiteAddress) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { siteDetails } = getState().siteManagement
    const { patientInformation } = getState().patientMainWrapper
    const { loggedInAsSite } = getState().auth

    // If SiteClientId is sent as prop (SiteManagement) - use it
    // Else use the patientInformation.siteClientId.
    // Which is current patient's siteClientId
    const siteClientId = siteDetails.siteClientId || patientInformation.siteClientId

    let addressData = {
      siteClientId,
      locationName: data.locationName,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      luStateId: +data.state?.id,
      luCountryId: +data.country?.id,
      zipCode: data.zipCode,
      phone: data.phone,
      addressTypeId: data.addressType.id,
      // For New Address - Address is verified if Site adds / updates it
      isAddressVerified: loggedInAsSite
    }

    // Existing Address
    if (data.addressId) {
      addressData = {
        ...addressData,
        addressId: data.addressId,
        isAddressVerified: data.isAddressVerified
      }
    }
    if (data.addressType.id === addressTypes.shipping && data.attentionOfContact) {
      addressData = {
        ...addressData,
        attentionOfContactUserEntityId: data.attentionOfContact.id,
        attentionOfContactUserName: data.attentionOfContact.displayText
      }
    }

    await saveSiteAddress(addressData)
    await dispatch(fetchSiteAddresses(siteClientId))
    dispatch(showSuccess('Site Address saved successfully!'))
    await dispatch(fetchAddressLookup())
    const { siteAddresses } = getState().siteManagement
    // get the latest/recently added address' addressId and send it as params to onSubmitSiteAddress()
    // this addressId is used for setting newly added address to the InPersonRequestForm's SelectService section
    onSubmitSiteAddress && onSubmitSiteAddress(siteAddresses[siteAddresses.length - 1])
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

export const fetchSiteDetails = siteId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const siteDetails = await getSiteDetails(siteId)
    const formattedSiteDetails = {
      siteId: siteDetails.siteId || '',
      siteProtocolId: siteDetails.siteProtocolId || '',
      siteClientId: siteDetails.siteClientId || '',
      siteName: siteDetails.siteName || '',
      country: siteDetails.country || '',
      piLastName: siteDetails.piLastName || '',
      siteStatus: siteDetails.siteStatus,
      siteType: siteDetails.siteType,
      irbecName: siteDetails.irbecName || '',
      irbecType: siteDetails.irbecType || '',
      screeningTarget: siteDetails.screeningTarget || '',
      randomizationTarget: siteDetails.randomizationTarget || '',
      plannedSiteActivationDate: siteDetails.plannedSiteActivationDate || '',
      activationDate: siteDetails.activationDate || '',
      enrollmentPausedDate: siteDetails.enrollmentPausedDate || '',
      reactivationDate: siteDetails.reactivationDate || '',
      droppedDate: siteDetails.droppedDate || '',
      closureDate: siteDetails.closureDate || '',
      imAttendanceDate: siteDetails.imAttendanceDate || ''
    }

    dispatch(setSiteDetails(formattedSiteDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchSiteNotes = siteId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const siteNotes = await getSiteNotes(siteId)
    dispatch(setSiteNotes(siteNotes))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchDataPoints = siteId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const dataPoints = await getSiteDataPoints(siteId)
    const newInitialValues = {}
    let dataPointInformation = {}
    if (dataPoints && dataPoints.length > 0) {
      /**
       * tuple = [moduleName, [formattedDataPoint1, formattedDataPoint2]]
       */
      const formattedDataPointsTuples = dataPoints.map(dataPoint => [
        dataPoint.module,
        dataPoints
          .filter(dp => dp.module === dataPoint.module)
          .map(dp => ({
            ...dp,
            updatedOn: displayDate(dp.updatedOn),
            isManual: dp.isManual
          }))
      ])

      /**
       * Getting in this format
       * {
       *    module1Name: [
       *      formattedDatapoint1,
       *      formattedDatapoint3,
       *    ],
       *    module2Name: [
       *      formattedDatapoint8,
       *      formattedDatapoint5,
       *      formattedDatapoint4,
       *    ],
       * }
       */
      dataPointInformation = Object.fromEntries(formattedDataPointsTuples)

      // Get InitialValues
      // Need to format answers
      dataPoints.forEach(dataPoint => {
        if ([DataPointType.Text, DataPointType.Number].includes(dataPoint.dataPointType)) {
          newInitialValues[dataPoint.dataPointId] = dataPoint.answer || ''
        } else {
          newInitialValues[dataPoint.dataPointId] = dataPoint.answer
        }
      })
    }

    dispatch(setDataPointsInitialValues(newInitialValues))
    dispatch(setDataPointsInformation(dataPointInformation))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
  // dispatch(setDataPointsInitialValues(newInitialValues))
  // dispatch(setDataPointsInformation(dataPointInformation))
}

export const saveSiteNote = (data, siteId, siteProtocolId) => async dispatch => {
  try {
    dispatch(setLoading(true))
    const notesData = {
      note: data.note,
      noteTypeId: data.noteTypeId.id,
      siteProtocolId
    }
    await addSiteNote(notesData)
    await dispatch(fetchSiteNotes(siteId))
    await dispatch(setShowAddNote(false))
    dispatch(showSuccess('Note saved successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const submitSiteDetails = values => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { selectedInstance } = getState().auth
    let requestData = {
      siteId: values.siteId,
      siteName: values.siteName,
      piLastName: values.piLastName,
      siteStatusId: values.siteStatus?.id,
      siteTypeId: values.siteType?.id,
      screeningTarget: values.screeningTarget,
      randomizationTarget: values.randomizationTarget,
      plannedSiteActivationDate: values.plannedSiteActivationDate
    }

    if (values.encrypttedSiteId) {
      requestData = {
        ...requestData,
        encrypttedSiteId: values.encrypttedSiteId,
        activationDate: values.activationDate,
        enrollmentPausedDate: values.enrollmentPausedDate,
        reactivationDate: values.reactivationDate,
        droppedDate: values.droppedDate,
        closureDate: values.closureDate,
        imAttendanceDate: values.imAttendanceDate,
        currentInstanceId: selectedInstance.instanceId
      }
      await saveSiteDetails({ ...requestData })
    } else {
      requestData = {
        ...requestData,
        initialActivationDate: values.activationDate,
        location: values.locationName,
        addressTypeId: values.addressType.id,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        stateId: values.state.id,
        countryId: values.country.id,
        zip: values.zipCode,
        phone: values.phone
      }
      await addSite(requestData)
    }

    if (values.encrypttedSiteId) {
      // Means user is editing a site - Refresh Site Data
      await dispatch(fetchSiteDetails(values.encrypttedSiteId))
    } else {
      // user is adding a new site - refresh the site list
      dispatch(fetchSites())
    }
    // Close the Form Popup
    dispatch(setAddEditSiteFlow(false))
    dispatch(showSuccess('Site Information saved successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

export const fetchSitesByCountries = countriesLookup => async dispatch => {
  dispatch(setSitesLookupByCountry({ isLoading: true }))
  await getSitesByCountries(countriesLookup.map(country => country.id).toString())
    .then(sites => {
      /**
       *  alternateIdentifier is siteProtocolId,
       *  which needs to sent while creating Custom Data Point
       */
      const formattedSites = sites.map(site => ({
        id: site.alternateIdentifier,
        displayText: site.displayText
      }))
      dispatch(setSitesLookupByCountry({ results: formattedSites, isLoading: false }))
    })
    .catch(() => {
      dispatch(setSitesLookupByCountry({ isLoading: false }))
      dispatch(
        showError('There was some issue while trying to fetch sites. Trying refreshing your page.')
      )
    })
}

export const startAddSiteSuccess = value => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setAddSiteSuccess(value))
  dispatch(setLoading(false))
}

export const resetAddEditSiteFlow = () => async dispatch => {
  dispatch(resetAddEditSite())
}

export const saveDataPoint = (values, siteId) => async dispatch => {
  dispatch(setLoading(true))

  const requestData = {
    countryIds: values.countries.map(country => country.id),
    siteClientIds: values.sites.map(site => site.id),
    dataPointTypeId: values.answerType?.id,
    dataPoint: values.dataPoint
  }

  try {
    await addCustomDataPoint(requestData)
    // Refresh Site Data
    await dispatch(fetchDataPoints(siteId))
    dispatch(showSuccess('Request submitted successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const saveDataPointAnswers = (values, siteId) => async (dispatch, getState) => {
  const { dataPointsInitialValues } = getState().siteManagement
  dispatch(setLoading(true))
  try {
    if (!shallowEqual(dataPointsInitialValues, values)) {
      const updatedDataPoints = Object.keys(values)
        .filter(key => dataPointsInitialValues[key] !== values[key])
        .map(key => ({
          dataPointId: key,
          answer: values[key]
        }))

      const payload = {
        siteId,
        dataPoints: updatedDataPoints
      }
      await updateDataPointAnswers(payload)
      // Refresh Site Data
      await dispatch(fetchDataPoints(payload.siteId))
      dispatch(showSuccess('Answers saved successfully!'))
    } else {
      dispatch(showError('No Data Points updated!'))
    }
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialSiteDetails = {
  siteId: '',
  siteName: '',
  piLastName: '',
  siteStatus: null,
  siteType: null,
  screeningTarget: '',
  randomizationTarget: '',
  plannedSiteActivationDate: '',
  activationDate: '',
  enrollmentPausedDate: '',
  reactivationDate: '',
  droppedDate: '',
  closureDate: '',
  imAttendanceDate: '',
  addressType: {
    id: 3,
    displayText: 'Business Address',
    alternateIdentifier: null
  },
  locationName: '',
  address1: '',
  address2: '',
  city: '',
  state: null,
  country: null,
  zipCode: '',
  phone: ''
}

const initialState = {
  siteDetails: { ...initialSiteDetails },
  filters: {
    siteIDs: [],
    countries: [],
    siteStatuses: [],
    search: '',
  },
  siteAddress: {
    addressType: null,
    locationName: '',
    address1: '',
    address2: '',
    city: '',
    state: null,
    country: null,
    zipCode: '',
    phone: '',
    attentionOfContact: null,
    isAddressVerified: false
  },
  siteNotes: [],
  dataPoints: [],
  dataPointsInformation: {},
  dataPointsInitialValues: {},
  siteAddresses: [],
  addressLookup: [],
  orderBy: 'recordCreatedDate',
  order: 'DESC',
  sites: { totalCount: 0, results: [] },
  siteTablePageNumber: 0,
  rowsPerPage: 20,
  addEditSiteFlow: false,
  addSiteSuccess: false,
  noteSaved: false,
  sitesLookupByCountry: { results: [], isLoading: false },
  showAddNote: false
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setSites, (state, action) => {
      state.sites = { ...state.sites, ...action.payload }
    })
    .addCase(setFilters, (state, action) => {
      state.filters = action.payload
    })
    .addCase(setSiteDetails, (state, action) => {
      state.siteDetails = action.payload
    })

    .addCase(setSiteNotes, (state, action) => {
      state.siteNotes = action.payload
    })
    .addCase(setDataPoints, (state, action) => {
      state.dataPoints = action.payload
    })
    .addCase(setDataPointsInitialValues, (state, action) => {
      state.dataPointsInitialValues = action.payload
    })
    .addCase(setDataPointsInformation, (state, action) => {
      state.dataPointsInformation = action.payload
    })
    .addCase(setSiteAddresses, (state, action) => {
      state.siteAddresses = action.payload
    })
    .addCase(setRequestedSort, (state, action) => {
      const isDesc = state.orderBy === action.payload && state.order === 'DESC'
      state.order = isDesc ? 'ASC' : 'DESC'
      state.orderBy = action.payload
    })
    .addCase(setSiteTablePageNumber, (state, action) => {
      state.siteTablePageNumber = action.payload
    })
    .addCase(setRowsPerPage, (state, action) => {
      state.rowsPerPage = parseInt(action.payload, 10)
      state.siteTablePageNumber = 0
    })
    .addCase(setAddEditSiteFlow, (state, action) => {
      state.addEditSiteFlow = action.payload
    })
    .addCase(setAddSiteSuccess, (state, action) => {
      state.addSiteSuccess = action.payload
    })
    .addCase(setAddressLookup, (state, action) => {
      state.addressLookup = action.payload
    })
    .addCase(resetAddEditSite, state => {
      state.addEditSiteFlow = false
      state.addSiteSuccess = false
    })

    .addCase(setSitesLookupByCountry, (state, { payload }) => {
      state.sitesLookupByCountry = { ...state.sitesLookupByCountry, ...payload }
    })
    .addCase(resetSitesLookupByCountry, state => {
      state.sitesLookupByCountry = { results: [], isLoading: false }
    })
    .addCase(setShowAddNote, (state, action) => {
      state.showAddNote = action.payload
    })
    .addCase(resetSiteDetails, state => {
      state.siteDetails = { ...initialSiteDetails }
      state.siteAddresses = []
      state.siteNotes = []
      state.addressLookup = []
    })

    .addCase(resetSiteManagement, state => {
      copyObject(state, initialState)
    })
})
