import { createAction, createReducer } from '@reduxjs/toolkit'
import { getTcnUsers, getUserListFilterLoadData, policyPermissions } from 'services/applications'
import { copyObject, createModuleObject } from 'components/helper/utility'
import { getEnrollmentCodes, getParticipationStatus, getSubjectSources } from 'services/common'
import { setLoading } from './userInteractions'
import { openUserMessageBar } from './userMessageBar'
import { performLogout } from './auth'
import { missingPermissions } from 'mockData'
import { getBadgesPerModule } from 'services/badging'

export const setTcnUsersLookup = createAction('application/setTcnUsersLookup')
const setStatesLookup = createAction('application/setStatesLookup')
export const setSiteClientId = createAction('application/setSiteClientId')
const setParticipationProgressLookup = createAction('application/setParticipationProgressLookup')
const setSourceLookup = createAction('application/setSourceLookup')
export const setEnrollmentCodesLookup = createAction('application/setEnrollmentCodesLookup')
const setParticipationStatusLookup = createAction('application/setParticipationStatusLookup')
const setSubjectSourcesLookup = createAction('application/setSubjectSourcesLookup')
export const setPolicyPermissions = createAction('application/setPolicyPermissions')
export const setBadgesPerModule = createAction('application/setBadgesPerModule')
export const resetApplication = createAction('application/resetApplication')

export const showSuccess = message => async dispatch => {
  const verticalPosition = 'bottom'
  const horizontalPosition = 'center'
  const messageType = 'success'
  dispatch(openUserMessageBar({ horizontalPosition, verticalPosition, message, messageType }))
}

export const showError =
  (message, customError, messageIsANode = false) =>
    async dispatch => {
      const verticalPosition = 'bottom'
      const horizontalPosition = 'center'
      const messageType = 'error'
      if (customError?.response?.data && typeof customError.response.data === 'string') {
        dispatch(
          openUserMessageBar({
            horizontalPosition,
            verticalPosition,
            message: customError.response.data,
            messageIsANode,
            messageType
          })
        )
      } else {
        dispatch(
          openUserMessageBar({
            horizontalPosition,
            verticalPosition,
            message,
            messageIsANode,
            messageType
          })
        )
      }
    }

export const showWarning = (message, customError) => async dispatch => {
  const verticalPosition = 'bottom'
  const horizontalPosition = 'center'
  const messageType = 'warning'
  if (customError?.response?.data && typeof customError.response.data === 'string') {
    dispatch(
      openUserMessageBar({
        horizontalPosition,
        verticalPosition,
        message: customError.response.data,
        messageType
      })
    )
  } else {
    dispatch(openUserMessageBar({ horizontalPosition, verticalPosition, message, messageType }))
  }
}

export const getPolicyPermissions = () => async (dispatch, getState) => {
  const { isPatient, selectedInstance } = getState().auth
  try {
    if (selectedInstance) {
      const { data } = await policyPermissions()
      if (isPatient) {
        dispatch(setPolicyPermissions([...data]))
      } else {
        await dispatch(setPolicyPermissions([...missingPermissions, ...data]))
        await dispatch(fetchBadgesPerModule())
      }
    }
  } catch (e) {
    dispatch(
      showError(
        'There was some error while trying to fetch permissions, please try refreshing your page.',
        e
      )
    )
    dispatch(setPolicyPermissions(false))
    dispatch(performLogout())
  }
}

export const fetchFiltersLookup = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { participationProgressLookup, sourceLookup } = getState().application
    if (participationProgressLookup.length === 0 || sourceLookup.length === 0) {
      const { data } = await getUserListFilterLoadData()

      dispatch(setParticipationProgressLookup(data.participationProgressLookup))
      dispatch(setSourceLookup(data.sourceLookup))
    }
  } catch (e) {
    dispatch(showError('Error, when trying to fetch filter lookup', e))
  }
  dispatch(setLoading(false))
}

export const fetchTcnUsersLookup = siteClientId => async (dispatch, getState) => {
  try {
    dispatch(setTcnUsersLookup({ isLoading: true }))
    const { patientInformation } = getState().patientMainWrapper

    const response = await getTcnUsers(siteClientId || patientInformation.siteClientId)
    const finalResponse = response.map(user => ({
      id: user.userEntityId,
      displayText: `${user.fullName} (${user.role})`,
      alternateIdentifier: user.userEntityId
    }))
    dispatch(setTcnUsersLookup({ results: finalResponse }))

    dispatch(setTcnUsersLookup({ isLoading: false }))
  } catch (e) {
    dispatch(
      showError(
        'There was issue fetching "TCN users" options. Please try refreshing your page or come back after sometime.',
        e
      )
    )
    dispatch(setTcnUsersLookup({ isLoading: false }))
  }
}

export const fetchEnrollmentCodesLookup = uniqueSiteProtocolId => async dispatch => {
  try {
    dispatch(setEnrollmentCodesLookup({ isLoading: true }))

    // Always fetch Enrollment codes as they keep getting assigned
    const { data } = await getEnrollmentCodes(uniqueSiteProtocolId)
    await dispatch(setEnrollmentCodesLookup({ results: data }))
  } catch (e) {
    dispatch(setEnrollmentCodesLookup({ results: [] }))
    dispatch(showError('Error while fetching Subject IDs', e))
  }
  dispatch(setEnrollmentCodesLookup({ isLoading: false }))
}

export const fetchSubjectSourcesLookup = () => async (dispatch, getState) => {
  try {
    const { subjectSourcesLookup } = getState().application
    dispatch(setSubjectSourcesLookup({ isLoading: true }))
    if (subjectSourcesLookup.results.length === 0) {
      const result = await getSubjectSources()
      dispatch(setSubjectSourcesLookup({ results: result }))
    }
  } catch (e) {
    dispatch(showError(e.title))
  }
  dispatch(setSubjectSourcesLookup({ isLoading: false }))
}

export const fetchParticipationStatusLookup = () => async (dispatch, getState) => {
  // If IVRConfiguration has changed, refetch ParticipationStatusLookup
  try {
    const { participationStatusLookup } = getState().application
    const { instanceConfig } = getState().instanceConfiguration
    if (participationStatusLookup.IVRIntegration !== instanceConfig.results.IVRIntegration) {
      dispatch(setParticipationStatusLookup({ isLoading: true }))
      const result = await getParticipationStatus()
      dispatch(
        setParticipationStatusLookup({
          results: result,
          IVRIntegration: instanceConfig.results.IVRIntegration
        })
      )
    }
  } catch (e) {
    dispatch(showError(e.title))
  }
  dispatch(setParticipationStatusLookup({ isLoading: false }))
}

export const fetchBadgesPerModule = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    dispatch(setBadgesPerModule({ isLoading: true }))
    const { data } = await getBadgesPerModule()
    const finalBadges = {}
    data.forEach(badge => {
      if (['Study Message', 'Announcement'].includes(badge.module)) {
        finalBadges.mailBox = createModuleObject(finalBadges.mailBox, badge)
      } else {
        finalBadges[badge.module] = createModuleObject(finalBadges[badge.module], badge)
      }
    })
    dispatch(setBadgesPerModule({ isLoading: false, results: finalBadges }))
    dispatch(setLoading(false))
  } catch {
    dispatch(
      showError('There was some error while trying to fetch badges. Please refresh you page.')
    )
    dispatch(setLoading(false))
    dispatch(setBadgesPerModule({ isLoading: false }))
  }
}

const initialState = {
  policyPermissions: [],
  tcnUsersLookup: { isLoading: false, results: [] },
  statesLookup: [],
  siteClientId: '',
  participationProgressLookup: [],
  sourceLookup: [],
  enrollmentCodesLookup: { isLoading: false, results: [] },
  subjectSourcesLookup: { isLoading: false, results: [] },
  participationStatusLookup: { isLoading: false, results: [], IVRIntegration: '' },
  badgesPerModule: { isLoading: false, results: '' },
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setPolicyPermissions, (state, action) => {
      state.policyPermissions = action.payload
    })
    .addCase(setTcnUsersLookup, (state, action) => {
      state.tcnUsersLookup = { ...state.tcnUsersLookup, ...action.payload }
    })
    .addCase(setStatesLookup, (state, action) => {
      state.statesLookup = action.payload
    })
    .addCase(setSiteClientId, (state, action) => {
      state.siteClientId = action.payload
    })
    .addCase(setParticipationProgressLookup, (state, action) => {
      state.participationProgressLookup = action.payload
    })
    .addCase(setSourceLookup, (state, action) => {
      state.sourceLookup = action.payload
    })
    .addCase(setEnrollmentCodesLookup, (state, action) => {
      state.enrollmentCodesLookup = { ...state.enrollmentCodesLookup, ...action.payload }
    })
    .addCase(setParticipationStatusLookup, (state, action) => {
      state.participationStatusLookup = { ...state.participationStatusLookup, ...action.payload }
    })
    .addCase(setSubjectSourcesLookup, (state, action) => {
      state.subjectSourcesLookup = { ...state.subjectSourcesLookup, ...action.payload }
    })
    .addCase(setBadgesPerModule, (state, action) => {
      state.badgesPerModule = { ...state.badgesPerModule, ...action.payload }
    })
    .addCase(resetApplication, state => {
      copyObject(state, initialState)
    })
})
